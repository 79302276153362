.c-top-nav {
	background-color: palette(grey, super-light);
	display: none;
	
	@include breakpoint($bp-large) {
		display: block;
		font-size: $font-small;
	}
	
	@include breakpoint($bp-xlarge) {
		font-size: $base-font-size;
	}
	
	&__inner {
		display: flex;
		justify-content: flex-end;
	}
	
	ul {
		align-items: flex-start;
		display: flex;
		flex-wrap: nowrap;
		
		li {
			&.is-active {
				a {
					background-color: $c-white;
				}
			}
		}
		
		a {
			color: $c-slate-grey;
			display: block;
			padding: $spacing-xsmall;
			
			@include breakpoint($bp-xlarge) {
				padding: $spacing-small;
			}  
			
			&:hover {
				color: $c-brand;
				text-decoration: none;
			}
		}
	}
}