.c-text-cta {
	border-top: 1px solid $c-border;
	padding-top: $spacing-large;
	margin-top: $spacing-large;
	text-align: center;
	font-size: $font-size-10;
	font-weight: $font-weight-semibold;
	
	@include breakpoint($bp-medium) {
		font-size: $font-size-8;
		padding-top: $spacing-xlarge;
		margin-top: $spacing-xlarge;
	}  
	
	p:last-child {
		margin-bottom: 0;
	}
}