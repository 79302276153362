.c-page-title {
	position: relative;
	padding: $spacing-large 0;

	.o-grid {
		@include breakpoint($bp-large) {
			display: flex;
			align-items: center;
		}
	}

	h1 {
		@include heading-6;
		margin: 0;
		// margin-bottom: .6em;

		strong, b {
			display: block;
		}

		@include breakpoint($bp-large) {
			width: 80%;
		}
	}

	p {
		margin: 1em 0 0 0;
		line-height: $font-leading-xsmall;

		@include breakpoint($bp-medium) {
			margin: 0;
			font-size: $font-size-9;
		}
	}
}