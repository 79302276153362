.c-article-meta {
	border-bottom: 1px solid $c-border;
	margin-bottom: $spacing-medium;
	padding-bottom: 26px !important;

	li {
		color: $c-brand;
		padding-left: 0 !important; // Override CMS scope defaults
		margin-bottom: 0 !important;
		
		&:before {
			display: none !important; // Override CMS scope defaults
		}

		@include breakpoint($bp-medium) {
			display: inline-block;
			margin-right: $spacing-medium;
		}

		&.c-article-meta__time {
			color: $c-font;
			font-weight: 300;
		}
	}

}