.o-quarters-list {
	@include span-columns(12);
	margin-bottom: -3.09278%; // Compensate for the bottom margins on the list items
	
	&--centered {
		@include breakpoint($bp-large) {
			display: flex;
			justify-content: center;
		}  
	}
	
	> li {
		@include span-columns(12);
		margin-bottom: 3.09278%;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		@include breakpoint($bp-small  $bp-medium) {
			@include span-columns(4 of 8);		
			
			&:nth-child(2n) {
				@include omega();
			}
		}
		
		@include breakpoint($bp-medium  $bp-large) {
			@include span-columns(4 of 12);		
			
			&:nth-child(2n) {
				@include remove-omega();
			}
			
			&:nth-child(3n) {
				@include omega();
			}
		} 
		
		@include breakpoint($bp-large) {
			@include span-columns(3);		
			
			&:nth-child(4n) {
				@include omega();
			}
		}  	
	}	
	
	
}