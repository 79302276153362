.o-inline-tags {
	display: flex;
	
	li {
		margin-right: $spacing-medium;
	}
	
	.c-tag {
		margin-right: 10px;
	}
}