.c-chunky-checklist {
	@include span-columns(12);
	
	> ul {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		padding: $spacing-medium;
		
		@include breakpoint($bp-medium) {
			padding: $spacing-large;
		}  
		
		> li {
			font-size: $font-size-10;
			margin-bottom: $spacing-base;
			position: relative;
			padding: 0 $spacing-base $spacing-base 40px;
			
			@include breakpoint($bp-small) {
				width: 50%;
			}  
			
			@include breakpoint($bp-large) {
				width: 33%;
				margin-bottom: $spacing-xxsmall;
				padding: 0 $spacing-medium $spacing-base 40px;
			}  
			
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
			
			ul {
				padding-left: 1em;
				
				li {
					&:before {
						content: "- "
					}
				}
			}
		}
	}
	
	svg {
		fill: currentColor;
		height: 30px;
		left: 0;
		margin-right: 5px;
		position: absolute;
		top: 0;
		width: 30px;
	}
}