.c-location {
	font-size: $font-size-10;

	p {
		padding-bottom: $spacing-small;
	}

	a {
		font-weight: 700;
	}
}