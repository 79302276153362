.c-sidebar-header {
	margin: 0;
	padding: $spacing-base $spacing-medium;
	background-color: $c-white;
	font-size: $font-small;
	color: $c-font;
	font-size: $base-font-size;
	font-weight: $font-weight-semibold;
	text-align: center;
	text-transform: uppercase;
	display: block;
	
	&--border-bottom {
		border-bottom: 1px solid $c-border;
	}

	&--bold {
		background-color: #58595b;
		color: #fff;
		font-size: $font-size-10;
		font-weight: $font-weight-bold;
		text-align: left;
		text-transform: none;
	}
	
	&--bold-light {
		background-color: palette(grey, xxx-light);
		font-size: $font-size-10;
		font-weight: $font-weight-bold;
		text-align: left;
		text-transform: none;
	}
	
	&:link,
	&:visited {
		color: palette(grey, mid-light);
	}
}