.c-loader {
	animation: spinner 1s infinite linear;
	border-color: rgba(palette(brand), 0.25) rgba(palette(brand), 0.25) rgba(palette(brand), 0.25) palette(brand);
	border-radius: 40px;
	border-style: solid;
	border-width: 4px;
	content: "";
	display: inline-block;
	height: toRems(40px);
	transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	width: toRems(40px);
	
	&--white {
		border-color: rgba($c-white, 0.4) rgba($c-white, 0.4) rgba($c-white, 0.4) $c-white;
	}

	&--centered {
		margin: 0 auto;
		display: block;
	}

	&--loading {
		display: none;

		.is-loading & {
			display: block;
		}
	}
}