.u-heading-1 {
    @include heading-1;
    // color: $c-brand;
}

.u-heading-2 {
    @include heading-2;
    // color: $c-brand;
}

.u-heading-3 {
    @include heading-3;
    // color: $c-brand;
}

.u-heading-4 {
    @include heading-4;
    // color: $c-brand;
}

.u-heading-5 {
    @include heading-5;
    // color: $c-brand;
}

.u-heading-5-light-uppercase {
    @include heading-5;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    // color: $c-brand;
}

.u-heading-6 {
    @include heading-6;
    // color: $c-brand;
}

.u-heading-6-bold-uppercase {
    @include heading-6;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    // color: $c-brand;
}

.u-heading-7 {
    @include heading-7;
    // color: $c-brand;
}

.u-heading-7-uppercase {
    @include heading-7;
    text-transform: uppercase;
    // color: $c-brand;
}

.u-heading-7-light {
    @include heading-7;
    font-weight: $font-weight-light;
    // color: $c-brand;
}

.u-heading-7-light-uppercase {
    @include heading-7;
    text-transform: uppercase;
    font-weight: $font-weight-light;
    // color: $c-brand;
}

.u-heading-7-bold {
    @include heading-7;
    font-weight: $font-weight-bold;
    // color: $c-brand;
}

.u-heading-7-bold-uppercase {
    @include heading-7;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    // color: $c-brand;
}

.u-heading-8 {
    @include heading-8;
    // color: $c-brand;
}

.u-heading-8-light {
    @include heading-8;
    font-weight: $font-weight-light;
    // color: $c-brand;
}

.u-heading-8-semibold {
    @include heading-8;
    font-weight: $font-weight-semibold;
    text-transform: none;
    // color: $c-brand;
}

.u-heading-8-bold {
    @include heading-8;
    font-weight: $font-weight-bold;
    // color: $c-brand;
}

.u-heading-8-bold-uppercase {
    @include heading-8;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    // color: $c-brand;
}

.u-heading-9-light {
    @include heading-9;
    font-weight: $font-weight-light;
    // color: $c-brand;
}

.u-heading-9 {
    @include heading-9;
    // color: $c-brand;
}

.u-heading-9-semibold {
    @include heading-9;
    font-weight: $font-weight-semibold;
    // color: $c-brand;
}

.u-heading-9-bold {
    @include heading-9;
    font-weight: $font-weight-bold;
    // color: $c-brand;
}

.u-heading-9-bold-uppercase {
    @include heading-9;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    // color: $c-brand;
}


.u-heading-10 {
    @include heading-10;
    // color: $c-brand;
}

.u-heading-10-border-bottom {
    @include heading-10;
    border-bottom: 1px solid $c-border;
    padding-bottom: $spacing-small;
    // color: $c-brand;
}

.u-body-large {
    @include body-large;
}

.u-body-large-light {
    @include body-large-light;
}

.u-font-normal {
    font-weight: $font-weight-normal;
}

.u-font-light {
    font-weight: $font-weight-light;
}

.u-heading-block-bar {
    background-color: palette(grey, dark);
    color: $c-white;
    padding: $spacing-small $spacing-base;
    margin: 0;
    clear: both;
    
    @include breakpoint($bp-large) {
        padding: 24px $spacing-base;
    }  
}