.c-section-title {
	text-align: center;
	position: relative;
	margin-bottom: $spacing-large;
	
	@include breakpoint($bp-large) {
		margin: 0 auto $spacing-xlarge;
		width: 80%;
		max-width: 950px;
	}
	
	&__heading,
	h2 {
		margin-bottom: 0;
		// color: palette(brand);
		
		&:not(.u-heading-5, .u-heading-8-semibold) {
			@include heading-7;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
		}
		
		+ div {
			margin-top: toRems(40px);
		}
		
		+ p {
			margin-top: toRems(40px);
			font-size: $font-size-10;
		}
	}
	
	&__action {
		position: absolute;
		right: toRems(20px);
		top: 50%;
		transform: translateY(-50%);
		
		@include breakpoint($bp-xlarge) {
			right: toRems(30px);
		}
		
		a {
			font-weight: 700;
			
			@include breakpoint($bp-xlarge) {
				font-size: $font-size-10;
			}
		}
	}
	
	&--band-border {
		&:before {
			background-color: $c-brand;
			content: "";
			display: block;
			margin: 0 auto 60px;
			width: 180px;
			height: 5px;
			
			@include breakpoint($bp-medium) {
				height: 10px;
			}  
		}
	}
	
	&--full-width {
		max-width: 100%;
		width: 100%;
	}
	
	&--small {
		margin-bottom: 40px;
	}
	
	&--large {
		margin-bottom: 0;
		
		@include breakpoint($bp-large) {
			font-size: toRems(20px);
		}
		
		.c-section-title__heading {
			// margin: 0 0 5px 0;
			
			@include breakpoint($bp-large) {
				font-size: $font-size-6 !important;
			}
		}
		
		p:last-child:last-of-type {
			margin-bottom: 0;
		}
	}
	
	&--relaxed {
		h2 {
			text-transform: none !important;
			font-size: $font-size-8;
			font-weight: $font-weight-normal !important;
		}
	}
}