.c-accordion-nav {
	
	> li {
		background-color: $c-white;
		font-size: $font-size-11;
		font-weight: $font-weight-bold;
		margin: 0;
		
		@include breakpoint($bp-medium) {
			font-size: $font-size-10;
		}  
		
		> a {
			border-bottom: 1px solid $c-border;
			color: $c-font;
			display: block;
			padding: $spacing-small;
			text-decoration: none;
			transition: all .3 $global-easing;
			
			@include breakpoint($bp-medium) {
				padding: $spacing-base;
			}  
			
			&:hover {
				background-color: $c-brand;
				color: $c-white;
				
				svg {
					fill: $c-white;
				}
			}
		}
		
		&:last-child {
			a {
				border-bottom: none;
			}
		}
		
		ul {
			font-size: $base-font-size;
			font-weight: normal;
			
			@include breakpoint($bp-medium) {
				font-size: $font-size-10;
			}  
			
			li {
				margin: 0;
				
				a {
					background-color: rgba(palette(grey, xx-light), .3);
					border-bottom: 1px solid $c-white;
					display: block;
					padding: $spacing-small;
					color: $c-font;
					text-decoration: none;
					
					@include breakpoint($bp-medium) {
						padding: $spacing-base;
					}  
					
					&:hover {
						background-color: palette(grey, xx-light);
					}
				}
			}
		}
	}
	
	.has-subnav {
		a {
			padding-right: $spacing-medium;
			position: relative;
			display: block;
			
			svg {
				fill: palette(grey, xx-light);
				display: block;
				width: 16px;
				height: 16px;
				position: absolute;
				right: $spacing-base;
				top: 50%;
				margin-top: -8px;
				transform-origin: 50% 50%;
				transition: all .26s $global-easing;
				transform: rotate(0);
			}
			
			
			&.is-active {
				
				background-color: $c-brand;
				color: $c-white;
				
				svg {
					transform: rotate(45deg);
					fill: $c-white;
				}
			}
		}
		
	}
	
	&__subnav {
		display: none;
	}
}
