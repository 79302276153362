.o-content-with-sidebar {
	height: 100%;

	@include breakpoint($bp-large) {
		@include span-columns(12);
		display: flex;
		align-items: stretch;
	}

	&__content {
		@include breakpoint($bp-large) {
			@include span-columns(6);
			background-color: #f1f1f1;
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}

	&__sidebar {
		background-color: $c-white;
		position: relative;
		padding-bottom: 62px; // Bottom button

		@include breakpoint($bp-large) {
			@include span-columns(3);
		}
	}
	
	&__last-link {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}