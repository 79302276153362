.c-testimonials {
	background-image: url(../images/elements/hexagon-bg-brand-mobile.png);
	background-position: 50% 50%;
	background-size: cover;
	color: $c-white;
	text-align: center;
	
	@include breakpoint($bp-small) {
		background-image: url(../images/elements/hexagon-bg.jpg);
	}  
	
	h2 {
		@include heading-7;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		margin-bottom: $spacing-medium;
	}
	
	blockquote {
		
		@include breakpoint($bp-medium) {
			max-width: 800px;
			margin: 0 auto;
		}  
		
		p {
			font-size: $font-size-10;
			line-height: 1.2;
			
			@include breakpoint($bp-medium) {
				font-size: 22px;
			}
			
			@include breakpoint($bp-large) {
				line-height: 1.2;
				font-size: 30px;
			}
		}
		
		footer {
			margin-top: 2rem;
			
			@include breakpoint($bp-large) {
				font-size: 20px;
				margin-bottom: 2rem;
			}
		}
	}
	
}