.c-slider {
	
	// Don't show stacked slides while loading
	> div:not(:first-child) {
		opacity: 0;
		position: absolute;
	}
	
	&.slick-initialized {
		> div {
			position: relative;
			opacity: 1;
		}
	}
	
	img {
		min-width: 100%;
		padding: 0 $spacing-xsmall;
		
		@include breakpoint($bp-large) {
			padding: 0 $spacing-small;
		}  
	}
	
	.slick-dots {
		li {
			margin: 0;
			
			button {
				&:before {
					opacity: 1;
					color: $c-white;
					font-size: 12px;
				}
			}
			
			&.slick-active {
				button {
					&:before {
						color: palette(grey, xx-light);
					}
				}
			}
		}
	}
	
	.slick-slide {
		opacity: 0;
		transition: opacity .4s ease-in-out;
	}
	
	.slick-active,
	.slick-current {
		opacity: 1;
	}
	
	
	&--dots-light {
		.slick-dots {
			
		}
	}
	
	&--no-hide-inactive-slides {
		.slick-slide {
			opacity: 1;
		}
	}
	
	&--vertical {
		margin-bottom: 0 !important;
		padding-right: 30px;
		
		@include breakpoint($bp-medium) {
			padding: 0 40px;
		}
		
		.slick-slide {
			opacity: 1 !important;
		}
		
		// Hexagon timeline
		.c-slider__item {
			&:first-child {
				.c-timeline-item {
					&:before {
						top: 60px;
						
						@include breakpoint($bp-medium) {
							top: 160px;
						}
					}
					
					&:after {
						top: 50px;
						transform: translate(-50%, 0);
						
						@include breakpoint($bp-medium) {
							top: 150px;
						}
					}
				}
			}
			
			&:last-child {
				.c-timeline-item {
					&:before {
						bottom: 60px;
						top: 0;
						
						@include breakpoint($bp-medium) {
							bottom: 160px;
						}
					}
					
					&:after {
						bottom: 50px;
						top: auto;
						transform: translate(-50%, 0);
						
						@include breakpoint($bp-medium) {
							bottom: 150px;
						}
					}
				}
			}
		}
		
		.slick-arrow {
			background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2060%2067.2%22%3E%3Cpath%20d%3D%22M60%2C20.09v27a6.27%2C6.27%2C0%2C0%2C1-2.94%2C5.69L33.87%2C66.32a6.44%2C6.44%2C0%2C0%2C1-6.5%2C0L3.65%2C52.8A6.91%2C6.91%2C0%2C0%2C1%2C0%2C47.11v-27a7%2C7%2C0%2C0%2C1%2C3.66-5.69L27.3%2C0.88A6.72%2C6.72%2C0%2C0%2C1%2C34%2C.88l23.1%2C13.51A6.3%2C6.3%2C0%2C0%2C1%2C60%2C20.09Z%22%20fill%3D%22%2358595b%22%2F%3E%3C%2Fsvg%3E');
			background-repeat: no-repeat;
			background-size: cover;
			height: toRems(45px);
			width: toRems(40px);
			z-index: 1;
			
			@include breakpoint($bp-medium) {
				width: toRems(89px);
				height: toRems(100px);
			}
			
			&.slick-disabled {
				background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2060%2067.2%22%3E%3Cpath%20d%3D%22M60%2C20.09v27a6.27%2C6.27%2C0%2C0%2C1-2.94%2C5.69L33.87%2C66.32a6.44%2C6.44%2C0%2C0%2C1-6.5%2C0L3.65%2C52.8A6.91%2C6.91%2C0%2C0%2C1%2C0%2C47.11v-27a7%2C7%2C0%2C0%2C1%2C3.66-5.69L27.3%2C0.88A6.72%2C6.72%2C0%2C0%2C1%2C34%2C.88l23.1%2C13.51A6.3%2C6.3%2C0%2C0%2C1%2C60%2C20.09Z%22%20fill%3D%22%23CDCDCD%22%2F%3E%3C%2Fsvg%3E');
			}
			
			&.slick-prev,
			&.slick-next {
				&:before {
					font-size: 16px;
					left: 50%;
					line-height: 1;
					opacity: 1;
					position: absolute;
					top: 50%;
					transform: translate3d(-48%, -50%, 0);
					
					@include breakpoint($bp-medium) {
						font-size: 35px;
					}
				}
			}
			
			&.slick-prev {
				bottom: 100%;
				left: 0;
				right: auto;
				top: auto;
				transform: translate3d(0, 50%, 0);
				
				@include breakpoint($bp-medium) {
					left: 50%;
					transform: translate3d(-50%, 50%, 0);
				}
				
				&:before {
					@extend %i-chevron-up;
					top: 48%;
				}
			}
			
			&.slick-next {
				bottom: auto;
				left: 0;
				right: auto;
				top: 100%;
				transform: translate3d(0, -50%, 0);
				
				@include breakpoint($bp-medium) {
					left: 50%;
					transform: translate3d(-50%, -50%, 0);
				}
				
				&:before {
					@extend %i-chevron-down;
					top: 52%;
				}
			}
		}
		
		.slick-dots {
			bottom: auto;
			height: auto;
			left: auto;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			width: auto;
			
			li {
				display: block;
				
				button {
					&:before {
						opacity: 1;
						color: palette(grey, light);
						font-size: 12px;
					}
				}
				
				&.slick-active {
					button {
						&:before {
							color: palette(grey, mid);
						}
					}
				}
			}
		}
	}
	
	&__logo {
		img {
			max-width: 280px;
		}
	}
	
	&--hide-arrows\@xsmall {
		@include breakpoint($bp-xsmall) {
			.slick-arrow {
				display: none !important;
			}
			
			blockquote {
				margin: 0;
			}
		}
	}
	
	&--dark-controls {
		.slick-dots {
			bottom: -50px;
			
			li {
				
				button {
					&:before {
						color: palette(grey, xx-light);
					}
				}
				
				&.slick-active {
					button {
						&:before {
							color: palette(grey, xx-light);
						}
					}
				}
			}
		}
		
		.slick-arrow {
			&:before {
				color: palette(grey, xx-light);
				font-size: 50px;
			}
			
			&.slick-prev {
				@include breakpoint($bp-xlarge) {
					left: -50px;
				}  
			}
			
			&.slick-next {
				@include breakpoint($bp-xlarge) {
					right: -50px;
				}  
			}
		}
	}
	
	&--small-arrows {
		.slick-arrow {
			&:before {
				font-size: 30px;
			}
			
			&.slick-prev {
				@include breakpoint($bp-xlarge) {
					left: -50px;
				}  
			}
			
			&.slick-next {
				@include breakpoint($bp-xlarge) {
					right: -50px;
				}  
			}
		}
	}
	
	&--flex {
		
		.slick-track {
			@include breakpoint($bp-small) {
				display: flex;
				align-items: center;
				justify-content: center;
			}  
		}
	}
}

// Global changes
.slick-arrow {
	height: 50px;
	width: 50px;
	
	&:before {
		opacity: 1;
		color: $c-white;
		font-size: 50px;
	}
	
	&.slick-prev {
		&:before {
			@extend %i-chevron-left-thin;
		}
	}
	
	&.slick-next {
		&:before {
			@extend %i-chevron-right-thin;
		}
	}
	
	&.slick-disabled {
		&:before {
			opacity: .5;
		}
	}
}