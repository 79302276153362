.c-tab-button {
	border: 3px solid $c-brand;
	display: block;
	line-height: $font-leading-xxsmall;
	text-align: center;
	position: relative;
	font-size: $font-size-10;
	
	@include breakpoint($bp-medium) {
		font-size: $font-size-8;
	}  
	
	span {
		color: $c-brand;
		background-color: $c-white;
		padding: $spacing-base $spacing-large;
		display: block;
		position: relative;
		z-index: 1;
		transition: all .3s $global-easing;
	}
	
	&:after {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -32px;
		border-style: solid;
		border-width: 20px 32px 0 32px;
		border-color: $c-brand transparent transparent transparent;
		margin-top: -20px;
		z-index: 0;
		transition: all .3s $global-easing;
	}
	
	&[aria-selected="true"] {
		
		span {
			background-color: $c-brand;
			color: $c-white;
		}
		
		&:after {
			margin-top: 0;
		}
	}
}