.c-btn-hexagon {
    display: block;
    height: toRems(41px);
    width: toRems(46px);
    position: relative;
    
    &:hover {
        .c-btn-hexagon__background {
            fill: darken($c-brand, 10%);
        }
    }
    
    @include breakpoint($bp-medium) {
        height: toRems(62px);
        width: toRems(55px);
    }
    
    &__background {
        height: 100%;
        width: 100%;
        fill: $c-brand;
        transition: all .3s $global-easing;
    }
    
    &__icon {
        fill: $c-white;
        height: toRems(10px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -30%);
        width: toRems(16px);
        
        @include breakpoint($bp-medium) {
            height: toRems(13px);
            width: toRems(22px);
        }
    }
}