.o-list {
	margin: toEms(20px) 0;

	li {
		margin: toEms(12px) 0;
		padding: 0 toEms(20px);
		position: relative;

		&:before {
			content: "•";
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.o-icon-list {
	@include clearfix;

	li {
		margin: $spacing-large 0;


		@include breakpoint($bp-small) {
			@include span-columns(4);
			padding-right: $spacing-base;
		}
	}
}

.o-icon-list-ordered {
	@include clearfix;

	li {
		margin: $spacing-large 0;

		@include breakpoint($bp-small) {
			@include span-columns(4);
		}
	}
}