.c-social {
	
	li {
		display: inline-block;
	}
	
	svg {
		width: 46px;
		height: 46px;
		fill: $c-brand;
		margin-right: 10px;
		position: relative;
	}
}