.c-featured-image-card {
	background-color: $c-white;
	color: inherit;
	position: relative;
	text-decoration: none !important;
	display: block;

	@include breakpoint($bp-large-max) {
    	background-image: none !important;
	}

	@include breakpoint($bp-large) {
		background-position: 100% 50%;
		background-size: cover;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 40%;
		bottom: 0;
		background: linear-gradient(to right, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.84) 51%, rgba(255, 255, 255, 0.46) 71%, rgba(255, 255, 255, 0.1) 91%, rgba(255, 255, 255, 0.01) 100%);
	}

	&:hover {
		.c-btn {
			@extend .c-btn--outlined:hover;
		}
	}

	> img {
		@include breakpoint($bp-large) {
	    	display: none;
		}
	}

	&__content {
		padding: $spacing-medium;
		position: relative;

		@include breakpoint($bp-large) {
    		width: 50%;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		font-size: $base-font-size;
		font-weight: $font-weight-bold;

		@include breakpoint($bp-large) {
			font-size: $font-size-9;
			line-height: $font-leading-xsmall;
		}
	}
}