.c-footer {
	background-color: palette(grey, mid-dark);
	color: palette(grey, x-light);
	padding: $spacing-medium 0;
	
	@include breakpoint($bp-large) {
		padding: 80px 0;
	}  
	
	h2 {
		font-size: 18px;
		text-transform: uppercase;
		border-bottom: 1px solid #6a6a6a;
		padding-bottom: $spacing-xsmall;
	}
	
	&__logo {
		width: 130px;
		height: 92px;
		opacity: .3;
	}
}