.o-input-with-button {
	

	@include breakpoint($bp-small) {
		display: flex;
		justify-content: center;
	}
	
	&__input {
		@extend %c-form-text;
		display: flex;
		flex: 1 0 auto;
		
		@include breakpoint($bp-small) {
			flex: 1;
			border-radius: $global-radius 0 0 $global-radius;
		}
		
		.c-form-select {
			@extend .c-btn--no-right-corners;
			flex: 1;
		}
		
		input[type="text"],
		input[type="email"],
		select {
			@extend %c-form-text;
			background-color: $c-white;
			flex: 1;

			@include breakpoint($bp-small) {
				border-radius: $global-radius 0 0 $global-radius;
			}
			
		}
		
		input {
			padding-top: 10px;
		}
	}
	
	&__button {

		button {
			width: 100%;

			@include breakpoint($bp-small) {
				height: 52px;
				border-radius: 0 $global-radius $global-radius 0;
				width: auto;
			}
			

			@include breakpoint($bp-large) {
				height: 57px;
				font-size: 20px;
			}
		
		}
		
	}
	
	button {
		line-height: 1;
	}
	
	&--halves {
		display: block;
		
		@include breakpoint($bp-medium) {
			display: flex;
		}  
		
		li {
			
			@include breakpoint($bp-medium) {
				width: 50%;
			}
			
			&:first-child {
				@include breakpoint($bp-medium-max) {
					margin-bottom: 5px;
				}  
			}
			
			input,
			button {
				width: 100%;
				
			}
			
			input[type="text"],
			input[type="email"],
			button,
			select {
				@include breakpoint($bp-medium-max) {
					border-radius: $global-radius !important;
				}  
			}
			
			button {
				@include breakpoint($bp-xsmall) {
					// padding-top: 11px;
				}  
			}
		}
	}
	
	&--erp-email {
		display: block;
		
		@include breakpoint($bp-small) {
			display: flex;
		}
		
		.o-input-with-button__label {
			@include breakpoint($bp-small) {
				margin: 0;
			}
		}
		
		input {
			&[type="text"] {
				@include breakpoint($bp-small) {
					border-bottom-right-radius: 0;
					border-right: none;
					border-top-right-radius: 0;
				}
			}
			
			&[type="email"] {
				@include breakpoint($bp-small) {
					border-radius: 0;
					border-right: none;
				}
			}
		}
		
		.c-btn {
			border-radius: 36px !important;
			
			@include breakpoint($bp-small) {
				min-width: 150px;
				border-bottom-left-radius: 0 !important;
				border-top-left-radius: 0 !important;
			}
		}
	}
	
	@media all and (-ms-high-contrast:none) {
		*::-ms-backdrop, input, button  {
				max-height: 58px;
		} /* IE11 */
	}
}
















