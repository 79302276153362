.o-sidebar-list {
	&__list {
		@extend %reset-list;
	}

	&__item {
		@include clearfix;
		border-bottom: 1px solid $c-border;
		padding: 0;
		margin: 0;
		padding-bottom: $spacing-small;
		margin-bottom: $spacing-small;


		&:last-child {
			padding: 0;
			margin: 0;
			border: 0;
		}
	}

	&__link {
		display: block;
		display: flex;
		align-items: center;
	}

	&__image {
		@include span-columns(4);
	}

	&__details {
		@include span-columns(8);
		padding-left: 10px;
	}

	&__title {
		@extend %tr-color;
		text-transform: none;
		color: $c-font;
		font-size: 16px;
		line-height: 22px;
		margin: 0;
		font-weight: normal;
	}

	&__price {
		color: $c-brand;
		font-weight: 700;
		margin-top: 5px;
	}

	a:hover {
		.o-sidebar-list__title {
			color: $c-brand;
		}
	}
	
}