.c-company {
	&__details {
		display: inline-block;
		float: right;
		width: calc(100% - 35px);
		
		figure {
			margin-bottom: $spacing-medium;
			
			@include breakpoint($bp-medium) {
				@include span-columns(2);
				margin-bottom: 0;
			}  
		}
		
		h4 {
			margin-bottom: 0;
		}
	}

	&__info {
		margin-bottom: $spacing-medium;
		
		@include breakpoint($bp-medium) {
			@include span-columns(5);
			margin-bottom: 0;
		}  
	}

	&__map {
		@include breakpoint($bp-medium) {
			@include span-columns(5);
		}  
	}
}
