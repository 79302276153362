// Site
$max-site-width: 1240px;

// Font families
$font-proxima: 'Proxima Nova', Arial, sans-serif;
$font-arial: Arial, sans-serif;
$font-headline: $font-arial;
$font-body: $font-arial;

// Font sizes
$base-font-size: 16px;
$font-size-1: toRems(70px);
$font-size-2: toRems(64px);
$font-size-3: toRems(60px);
$font-size-4: toRems(50px);
$font-size-5: toRems(45px);
$font-size-6: toRems(40px);
$font-size-7: toRems(34px);
$font-size-8: toRems(30px);
$font-size-9: toRems(24px);
$font-size-10: toRems(20px);
$font-size-11: toRems(18px);

// Small font sizes
$font-small: toRems(14px);
$font-xsmall: toRems(13px);
$font-xxsmall: toRems(12px);

// Font weights
$font-weight-thin: 100;
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
// $font-weight-heavy:    800;
// $font-weight-black:    900;

// Font letter spacing
$letter-spacing-small: 0.025em;
$letter-spacing-medium: 0.05em;
$letter-spacing-large: 0.1em;
$letter-spacing-xlarge: 0.2em;

// Font leading
$font-leading-xxsmall: 1;
$font-leading-xsmall: 1.2;
$font-leading-small: 1.4;
$font-leading-base: 1.4;
$font-leading-medium: 1.8;
$font-leading-large: 2;

// Base Variables
$base-font-family: $font-body;
$base-font-size: $base-font-size;
$base-font-weight: $font-weight-normal;
$base-line-height: $font-leading-base;

// Neat grid settings
$span-columns: 12;
$max-width: $max-site-width;
$visual-grid: false !default;
$border-box-sizing: true !default;
$disable-warnings: false !default;
$column: 76px;
$gutter: 24px;

// Have to import Neat after grid settings
@import 'neat',
	// Neat - http://neat.bourbon.io/
		'node_modules/breakpoint-sass/stylesheets/breakpoint.scss'; // Breakpoints - http://breakpoint-sass.com/

// Breakpoints
// Extra small devices (portrait phones)
$bp-xsmall: max-width 540px;

$bp-small: 540px;

// Up to medium devices (tablets)
$bp-medium-max: max-width 767px;

// Medium devices (tablets)
$bp-medium: 768px;
// Large devices (desktops)
$bp-large: 992px;
// Up to Large devices (desktops)
$bp-large-max: max-width 991px;
// Extra large devices (large desktops)
$bp-xlarge: $max-site-width;

// Z-indexes
$z-header: 1000;
$z-modal: 2000;
$z-page-nav: 50;
$z-nav-content: 80;
$z-header: 100;

// Global variables
$global-radius: 30px;
$global-easing: ease-in-out;
$global-duration: 200ms;

// Global spacing
$spacing-xxsmall: toRems(5px);
$spacing-xsmall: toRems(10px);
$spacing-small: toRems(15px);
$spacing-base: toRems(20px);
$spacing-medium: toRems(30px);
$spacing-large: toRems(50px);
$spacing-xlarge: toRems(100px);

// Z-indexes

$header-height: 66px;
$header-height-large: 126px;
$header-height-xlarge: 162px;
