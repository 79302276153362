.c-product-card-wide {
	background-color: $c-white;
	padding: $spacing-medium;
	width: 100%;

	@include breakpoint($bp-large) {
    	display: flex;
	}

	figure {
		margin-bottom: $spacing-base;

		img {
			margin: 0 auto;
		}

		@include breakpoint($bp-large) {
    		margin-bottom: 0;
			margin-right: $spacing-medium;
		}
	}

	&__content {
		// text-align: center;
		
		h4 {
			font-size: $font-size-9;
		}

		ul {
			li {
				margin-bottom: .65em;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}