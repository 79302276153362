.u-border-top-grey-xx-light {
	border-top: 1px solid $c-border;
}

.u-triangle-border-top-white {
	position: relative;

	&:before {
		background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20270%2067%22%3E%3Cpath%20d%3D%22M270%2C67c-0.84-.58-1.72-1.14-2.67-1.68L164.91%2C7c-16.45-9.36-43.36-9.36-59.81%2C0L2.67%2C65.32C1.72%2C65.86.84%2C66.42%2C0%2C67H270Z%22%20fill%3D%22%23fff%22%20%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-size: cover;
		content: "";
		height: 58px;
		left: 50%;
		position: absolute;
		top: -2px;
		transform: translateX(-50%) rotate(180deg);
		width: 232px;
		z-index: 1;

		@include breakpoint($bp-medium) {
			height: 67px;
			width: 270px;
		}
	}
}