/**
 * Generated SVG Sprite Sizes
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more icons to the svg spritesheets:
 *    1. Add svgs to "images/svg/sprites/"
 *    2. Run `gulp svg-sprites` in your terminal
 *
 */

%svg-icon {
	display: inline-block;
}

.svg-arrow-left {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-arrow-right {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-arrow-size {
	@extend %svg-icon;
	height: 22.47px;
	width: 60px;
}

.svg-badge-tick {
	@extend %svg-icon;
	height: 60px;
	width: 60px;
}

.svg-bell {
	@extend %svg-icon;
	height: 50.29px;
	width: 60px;
}

.svg-boiler {
	@extend %svg-icon;
	height: 60px;
	width: 41.97px;
}

.svg-book {
	@extend %svg-icon;
	height: 46.68px;
	width: 60px;
}

.svg-bookmark {
	@extend %svg-icon;
	height: 60px;
	width: 41.11px;
}

.svg-chat {
	@extend %svg-icon;
	height: 43.6px;
	width: 60px;
}

.svg-checkbox {
	@extend %svg-icon;
	height: 97px;
	width: 106.6px;
}

.svg-chevron-down {
	@extend %svg-icon;
	height: 36.06px;
	width: 60px;
}

.svg-chevron-down-thin {
	@extend %svg-icon;
	height: 33.39px;
	width: 60px;
}

.svg-chevron-left {
	@extend %svg-icon;
	height: 60px;
	width: 36.06px;
}

.svg-chevron-left-thin {
	@extend %svg-icon;
	height: 60px;
	width: 33.39px;
}

.svg-chevron-right {
	@extend %svg-icon;
	height: 60px;
	width: 36.06px;
}

.svg-chevron-right-thin {
	@extend %svg-icon;
	height: 60px;
	width: 33.39px;
}

.svg-chevron-up {
	@extend %svg-icon;
	height: 36.06px;
	width: 60px;
}

.svg-chevron-up-down-thin {
	@extend %svg-icon;
	height: 60px;
	width: 40.8px;
}

.svg-chevron-up-thin {
	@extend %svg-icon;
	height: 33.39px;
	width: 60px;
}

.svg-clipboard {
	@extend %svg-icon;
	height: 60px;
	width: 42.72px;
}

.svg-close {
	@extend %svg-icon;
	height: 14.4px;
	width: 14.4px;
}

.svg-cog {
	@extend %svg-icon;
	height: 60px;
	width: 60px;
}

.svg-computer {
	@extend %svg-icon;
	height: 56px;
	width: 60px;
}

.svg-download {
	@extend %svg-icon;
	height: 54.99px;
	width: 60px;
}

.svg-drawing-compass {
	@extend %svg-icon;
	height: 60px;
	width: 49.82px;
}

.svg-enlarge {
	@extend %svg-icon;
	height: 59.98px;
	width: 60px;
}

.svg-error {
	@extend %svg-icon;
	height: 69.8px;
	width: 69.8px;
}

.svg-external-link {
	@extend %svg-icon;
	height: 60.8px;
	width: 60px;
}

.svg-eye {
	@extend %svg-icon;
	height: 37.5px;
	width: 60px;
}

.svg-facebook {
	@extend %svg-icon;
	height: 34.84px;
	width: 35px;
}

.svg-file-pdf {
	@extend %svg-icon;
	height: 60.15px;
	width: 53px;
}

.svg-gas {
	@extend %svg-icon;
	height: 62.61px;
	width: 60px;
}

.svg-google-plus {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-headset {
	@extend %svg-icon;
	height: 54.46px;
	width: 60px;
}

.svg-heart {
	@extend %svg-icon;
	height: 51.64px;
	width: 60px;
}

.svg-hexagon {
	@extend %svg-icon;
	height: 67.2px;
	width: 60px;
}

.svg-hexagon-google-plus {
	@extend %svg-icon;
	height: 67.25px;
	width: 60px;
}

.svg-hexagon-mail {
	@extend %svg-icon;
	height: 67.2px;
	width: 60px;
}

.svg-hexagon-outline {
	@extend %svg-icon;
	height: 72.2px;
	width: 65px;
}

.svg-hexagon-phone {
	@extend %svg-icon;
	height: 67.2px;
	width: 60px;
}

.svg-hexagon-play {
	@extend %svg-icon;
	height: 269.904px;
	width: 240.818px;
}

.svg-hexagon-play-outlined {
	@extend %svg-icon;
	height: 71.2px;
	width: 64px;
}

.svg-hexagon-tick {
	@extend %svg-icon;
	height: 67.2px;
	width: 60px;
}

.svg-hexagon-twitter {
	@extend %svg-icon;
	height: 67.2px;
	width: 60px;
}

.svg-hexagon-youtube {
	@extend %svg-icon;
	height: 67.25px;
	width: 60px;
}

.svg-ideal-logo {
	@extend %svg-icon;
	height: 60px;
	width: 143.3px;
}

.svg-ideal-logo-bw {
	@extend %svg-icon;
	height: 80.3px;
	width: 191.7px;
}

.svg-ideal-logo-simple {
	@extend %svg-icon;
	height: 79.7px;
	width: 190.4px;
}

.svg-instagram {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-keston-flat {
	@extend %svg-icon;
	height: 92px;
	width: 130px;
}

.svg-linkedin {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-location-pin {
	@extend %svg-icon;
	height: 69.61px;
	width: 60px;
}

.svg-magnifier {
	@extend %svg-icon;
	height: 60px;
	width: 60px;
}

.svg-mail {
	@extend %svg-icon;
	height: 60px;
	width: 60px;
}

.svg-menu {
	@extend %svg-icon;
	height: 24px;
	width: 24px;
}

.svg-minus {
	@extend %svg-icon;
	height: 59.3px;
	width: 60px;
}

.svg-mobile {
	@extend %svg-icon;
	height: 60px;
	width: 31px;
}

.svg-pencil-edit {
	@extend %svg-icon;
	height: 45.58px;
	width: 60px;
}

.svg-phone {
	@extend %svg-icon;
	height: 69.8px;
	width: 69.8px;
}

.svg-pinterest {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-play-circle {
	@extend %svg-icon;
	height: 60px;
	width: 60px;
}

.svg-plus {
	@extend %svg-icon;
	height: 59.26px;
	width: 60px;
}

.svg-printer {
	@extend %svg-icon;
	height: 52.02px;
	width: 60px;
}

.svg-product {
	@extend %svg-icon;
	height: 59.94px;
	width: 60px;
}

.svg-ps-f-gas {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-f-lpg {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-r-broken {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-r-new-build {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-r-replacement {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-t-combi {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-t-dont-know {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-t-heat {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-t-new {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-t-system {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-u-bathrooms {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-ps-u-people {
	@extend %svg-icon;
	height: 400px;
	width: 400px;
}

.svg-radiator {
	@extend %svg-icon;
	height: 82.1px;
	width: 85.6px;
}

.svg-rocket {
	@extend %svg-icon;
	height: 62.77px;
	width: 60px;
}

.svg-satelitte {
	@extend %svg-icon;
	height: 60.3px;
	width: 60px;
}

.svg-search {
	@extend %svg-icon;
	height: 69.5px;
	width: 69.5px;
}

.svg-share {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-tag {
	@extend %svg-icon;
	height: 60px;
	width: 126.9px;
}

.svg-tech-support {
	@extend %svg-icon;
	height: 60px;
	width: 59.79px;
}

.svg-tick {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-tick-thick {
	@extend %svg-icon;
	height: 48px;
	width: 60px;
}

.svg-twitter {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-vimeo {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-water {
	@extend %svg-icon;
	height: 60px;
	width: 46.26px;
}

.svg-wrench {
	@extend %svg-icon;
	height: 58.39px;
	width: 60px;
}

.svg-youtube {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

