.s-cms-content {
	img {
		max-width: 800px;
		margin: auto;
		margin-bottom: toRems(30px);
		margin-top: toRems(30px);
		width: 100%;
	}
	
	// Headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $font-weight-semibold;
	}
	
	h1 {
		@include heading-5;
	}
	
	h2, h3, h4 {
		margin-top: toRems(30px); // Is this needed?
	}
	
	ul {
		@extend %c-list;
		@extend %c-list--unordered;
	}
	
	ol {
		@extend %c-list;
		@extend %c-list--ordered;
	}
	
	blockquote {
		clear: both;
		font-size: $font-size-11;
		font-weight: $font-weight-light;
		line-height: $font-leading-xsmall;
		padding: $spacing-small 0 $spacing-small 25px;
		border-left: 5px solid $c-brand;
		position: relative;
		padding-top: 60px;
		margin-top: $spacing-large;
		margin-bottom: $spacing-large;
		margin-left: 20px;
		
		@include breakpoint($bp-medium) {
			font-size: $font-size-9;
			margin-top: 60px;
			margin-bottom: 60px;
			margin-left: $spacing-large;
			margin-right: $spacing-large;
		}  
		
		&:before {
			@extend %i-hexagon;
			color: $c-brand;
			font-size: 58px;
			position: absolute;
			top: -20px;
			left: -31px;
		}
		
		&:after {
			content: '“';
			color: $c-white;
			font-weight: $font-weight-bold;
			font-size: 54px;
			position: absolute;
			top: -11px;
			left: -15px;
		}
		
		
		footer {
			font-size: $base-font-size;
			color: palette(grey, mid-light);
			display: block;
			font-style: normal;
		}
		
	}
	
	figure {
		@extend %font-headline;
		color: $c-font-light;
		font-size: $font-small;
		margin-bottom: toRems(30px);
		text-align: center;
		
		img {
			margin-bottom: toRems(5px);
			max-width: 800px;
			width: 100%;
		}
	}
	
	.c-video-item {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
	}
	
	&.u-align-center {
		ul, ol, {
			li:before {
				position: relative;
				top: -.3em;
				margin-right: .25em;
			}
		}
	}

}