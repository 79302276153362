.c-browser-warning {
	width: 100%;
	background-color: #e76618;
	color: $c-white;
	text-align: center;
	padding: 16px;
	display: none;
	font-size: 18px;
	
	a {
		color: $c-white;
		text-decoration: underline;
	}
	
	@media screen and (min-width:0\0) {
		& {
			display: block\9;
		} /* backslash-9 removes ie11+ & old Safari 4 */
	}
}