.c-filters {
	position: relative;
	z-index: 1;
	
	ul {
		padding: $spacing-medium 0;
		display: none;
		
		li {
			display: inline-block;
			margin-right: 12px;
			margin-bottom: 10px;
			transition: all .3s $global-easing;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
	
	&__toggle {
		position: absolute;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		outline: none;
		
		.c-filters__toggle-plus {
			display: block;
			
			.is-active & {
				display: none;
			}
		}
		
		.c-filters__toggle-minus {
			display: none;
			
			.is-active & {
				display: block;
			}
		}
		
		// .is-active & {
		// 	.c-filters__toggle-plus {
		// 		display: none;
		// 	}
		// 	
		// 	.c-filters__toggle-minus {
		// 		display: block;
		// 	}
		// }
		
	}
	
	&.is-active {
		// display: block;
		
		ul li {
			transform: scale(1);
		}
	}
}