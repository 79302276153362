.o-field {
	@include clearfix;
	padding: 25px 0;

	.u-flex-grid-x2\@small & {
		margin: 25px 0;

		&:first-child {
			@include breakpoint($bp-small) {
				padding-right: $spacing-small;
			}
		}

		&:last-child {
			@include breakpoint($bp-small) {
				padding-left: $spacing-small;
			}
		}
	}
}