.c-tag {
	color: #fff;
	display: inline-block;
	margin: 0;
	height: 34px;
	font-size: 24px;
	padding: 0 5px 3px 16px;
	position: relative;
	min-width: 70px;
	text-align: center;

	&:before {
		content: '';
		display: block;
		height: 100%;
		left: 14px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: $c-green;
	}

	&:after {
		border-color: transparent $c-green transparent transparent;
		border-style: solid;
		border-width: 17px 14px 17px 0;
		content: '';
		display: block;
		height: 0;
		left: 0;
		position: absolute;
		top: 0;
		width: 0;
	}

	&--heating {
		color: #000;

		&:before {
			background-color: $c-heating;
		}
		&:after {
			border-color: transparent $c-heating transparent transparent;
		}

		.c-tag__content {
			svg {
				fill: #000;
			}
		}
	}

	&--water {
		&:before {
			background-color: $c-water;
		}
		&:after {
			border-color: transparent $c-water transparent transparent;
		}
	}

	&__content {
		position: relative;
		text-transform: uppercase;

		svg {
			fill: #fff;
			width: 16px;
			height: 16px;
			position: relative;
			top: 1px;
		}
	}

	&--small {
		height: 24px;
		font-size: 18px;
		min-width: 54px;

		&:after {
			border-width: 12px 14px 12px 0;
		}

		.c-tag__content svg {
			top: 0;
			width: 12px;
			height: 12px;
		}
	}

	&--large {
		align-items: center;
		padding: 0;
		display: inline-flex;
		font-weight: 500;
		font-size: 28px;
		height: 48px;
		justify-content: center;
		line-height: 1;
		min-width: 100px;
		padding-right: 5px;
		padding-left: 30px;

		&:before {
			left: 28px;
		}

		&:after {
			border-width: 24px 28px 24px 0;
		}
	}

	&--grey {
		&:before {
			background-color: #CCCCCC;
		}

		&:after {
			border-color: transparent #CCCCCC transparent transparent;
		}
	}

	&--yellow {
		&:before {
			background-color: #FFD600;
		}

		&:after {
			border-color: transparent #FFD600 transparent transparent;
		}
	}
}