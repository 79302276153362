.u-font-weight-light {
  &#{&} {
    font-weight: 300;
  }
}

.u-font-weight-semibold {
  &#{&} {
    font-weight: 600;
  }
}

.u-font-weight-bold {
  &#{&} {
    font-weight: 700;
  }
}