.c-visual-card {
	background-size: cover;
	background-position: 50% 0;
	display: block;
	color: $c-white;
	padding: 40px;
	display: flex;
	align-items: flex-end;
	text-decoration: none;
	background-color: palette(grey, x-light);
	
	@include breakpoint($bp-small $bp-large) {
		min-height: 300px;
	}  
	
	@include breakpoint($bp-large) {
		background-position: 50% 50%;
	}  
	
	&:hover {}
	
	&__content {
		display: block;
	}
	
	&__heading {
		@include heading-8;
		width: 100%;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		margin-bottom: $spacing-small;
		
		.c-visual-card--smaller & {
			@include breakpoint($bp-large) {
				@include heading-9;
				text-transform: uppercase;
				font-weight: $font-weight-bold;
			}  
		}
	}
	
	p:last-child {
		margin-bottom: 0;
	}
	
	// &--smaller {
	// 	
	// 	.c-visual-card__heading {
	// 		@include breakpoint($bp-large) {
	// 			@include heading-9;
	// 			text-transform: uppercase;
	// 			font-weight: $font-weight-bold;
	// 		}  
	// 	}
	// }
}