.u-color-brand {
	color: $c-brand;
}

.u-color-white {
	color: $c-white;
}

.u-color-grey {
	color: palette(grey);
}