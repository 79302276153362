.c-parallax-background-container {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 0;
	
	.o-grid {
		z-index: 5;
	}
	
	&--no-repeat {
		.c-parallax-background {
			background-repeat: no-repeat;
		}
	}
	
	// Hide the backgrounds going out
	&.has-gradient-top {
		
		// Hide the backgrounds if they move out of the container
		&:before {
			background-image: linear-gradient(to bottom, rgba($c-white, 1) 0%, rgba($c-white, 0) 100%);
			content: "";
			display: block;
			pointer-events: none;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 100px;
			width: 100%;
			z-index: 4;
			
		}
	}
	
	&.has-gradient-bottom {
		
		// Hide the backgrounds if they move out of the container

		&:after {
			background-image: linear-gradient(to top, rgba($c-white, 1) 0%, rgba($c-white, 0) 100%);
			bottom: 0;
			content: "";
			display: block;
			pointer-events: none;
			position: absolute;
			left: 0;
			right: 0;
			height: 100px;
			width: 100%;
			z-index: 4;
			display: none;
			
		}
		
	}
	
	// Grey background, not currently used but might be handy one day
	// &.u-bg-grey-super-light:before {
	// 	background-image: linear-gradient(to bottom, rgba(palette(grey, super-light), 1) 0%, rgba(palette(grey, super-light), 0) 100%);
	// 	display: none;
	// }
	// 
	// &.u-bg-grey-super-light:after {
	// 	display: none;
	// 	bottom: 0;
	// 	background-image: linear-gradient(to top, rgba(palette(grey, super-light), 1) 0%, rgba(palette(grey, super-light), 0) 100%);
	// }
}