/**
 * Show/hide @bp-xlarge
 */

.u-show\@xlarge.u-show\@xlarge {
	display: none;

	@include breakpoint($bp-xlarge) {
		display: block;
	}
}

.u-hide\@xlarge.u-hide\@xlarge {
	display: block;

	@include breakpoint($bp-xlarge) {
		display: none;
	}
}

/**
 * Show/hide @bp-large
 */

.u-show\@large.u-show\@large {
	display: none;

	@include breakpoint($bp-large) {
		display: block;
	}
}

.u-hide\@large.u-hide\@large {
	display: block;

	@include breakpoint($bp-large) {
		display: none;
	}
}

/**
 * Show/hide @bp-medium
 */

.u-show\@medium.u-show\@medium {
	display: none;

	@include breakpoint($bp-medium) {
		display: block;
	}
}

.u-hide\@medium.u-hide\@medium {
	display: block;

	@include breakpoint($bp-medium) {
		display: none;
	}
}

/**
 * Show/hide @bp-small
 */

.u-show\@small.u-show\@small {
	display: none;

	@include breakpoint($bp-small) {
		display: block;
	}
}

.u-hide\@small.u-hide\@small {
	display: block;

	@include breakpoint($bp-small) {
		display: none;
	}
}
