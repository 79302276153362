.c-form-select {
    background-color: $c-white;
    border: 1px solid palette(grey, xx-light);
    border-radius: $global-radius;
    color: $c-font;
    cursor: pointer;
    display: block;
    font-size: toEms(14px);
    margin: 0;
    padding: 0;
    position: relative;
    
    @include breakpoint($bp-large) {
        font-size: 20px;
    }
    
    &.parsley-error {
        border-color: palette(errors);
    }
    
    &:before {
        @extend %i-chevron-up-down-thin;
        color: $c-white;
        background-color: $c-brand;
        border-radius: 0 30px 30px 0;
        padding: 14px 24px;
        display: block;
        font-size: toRems(24px);
        line-height: 1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        pointer-events: none;
        
        @include breakpoint($bp-large) {
            padding: 17px 24px;
            
        }
        
        .lt-ie10 & {
            display: none;
        }
    }
    
    // Hide native arrow in IE
    select::-ms-expand {
        display: none;
    }
    
    &__input,
    .select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        color: $c-font;
        cursor: pointer;
        font-size: $font-small;
        font-weight: 300;
        line-height: normal;
        line-height: 1;
        padding: toRems(18px) 90px toRems(18px) 24px;
        position: relative;
        width: 100%;
        
        @include breakpoint($bp-large) {
            font-size: 20px;
        }
        
        .lt-ie10 & {
            padding: toRems(11px);
        }
    }
    
    &--plain {
        
        &:before {
            background-color: transparent;
            color: palette(grey, x-light);
            padding: 14px;
        }
        
        .c-form-select__input {
            padding-right: 50px;
        }
    }
    
    &--inverted {
        color: $c-white;
        background-color: transparent;
        border-width: 2px;
        border-color: $c-white;
        
        .c-form-select__input {
            color: $c-white;
            background-color: transparent;
            font-weight: bold;
        }
        
        &:before {
            background-color: transparent;
            color: palette(grey, x-light);
            padding: 14px;
            right: 5px;
        }
        
        .c-form-select__input {
            padding-right: 50px;
        }
    }
    
    &--inline {
        display: inline-block;
    }
}

@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, .c-form-select:before {
        padding: 20px 24px;
    } /* IE11 */
}

