.o-centered-inline-list {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	max-width: $max-site-width;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: $spacing-medium;
	
	&--offset-top {
		
		@include breakpoint($bp-large) {
			margin: -140px auto 100px;
		}  
	}
	
	li {
		flex: 0 0 84px;
		margin: 0 $spacing-small $spacing-small;
		
		@include breakpoint($bp-medium) {
			margin: 0 $spacing-base $spacing-small $spacing-base;
		}  
	}
}