.c-product-slider {
	img {
		margin: 0 auto;
	}
	
	&__item:not(:first-child) {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	&.slick-initialized .c-product-slider__item {
		opacity: 1 !important;
		position: relative !important;
	}
	
	.slick-dots {
		bottom: -50px;
		
		li {
			margin: 2px;
			
			button {
				&:before {
					transition: all .3s $global-easing;
					opacity: 1; 
					color: palette(grey, xx-light);
					font-size: 18px;
				}
			}
			
			&.slick-active {
				button {
					&:before {
						color: $c-white;
					}
				}
			}
		}
	}
	
}