.c-triangle-title {
	align-items: flex-end;
	bottom: 100%;
	color: palette(grey);
	display: flex;
	font-size: 20px;
	font-weight: 700;
	height: 65px;
	justify-content: center;
	left: 50%;
	line-height: 1;
	margin: 0 auto -4px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	transform: translateX(-50%);
	width: 270px;
	z-index: 0;

	&:before {
		background-image: url('../images/elements/rounded-triangle.svg');
		background-repeat: no-repeat;
		background-size: cover;
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	&--flipped {
		transform: translateY(100%) translateX(-50%);
		margin-bottom: 0;
		align-items: flex-start;

		&:before {
			transform: rotate(180deg);
		}
	}
}
