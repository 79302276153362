// -------------------------------------------------------------------
// [Clearfix]
// @Description:
// Contain floats
// @Usage:
// @include clearfix;
// -------------------------------------------------------------------

@mixin clearfix {
	zoom: 1;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

// CLEAR FIX
.clearfix {
	@include clearfix;
}

// -------------------------------------------------------------------
// [Remove omega]
// @Description:
// Removes omega floats from item
// @Usage:
// @include remove_omega;
// -------------------------------------------------------------------

@mixin remove-omega {
	margin-right: flex-gutter();
}

// -------------------------------------------------------------------
// [Remove Omega from nth-child]
// @Description:
// Removes omega floats from passed nth-child condition
// @Usage:
// @include omega_reset(2n);
// -------------------------------------------------------------------

@mixin omega-reset($nth) {
	&:nth-child(#{$nth}) {
		margin-right: flex-gutter();
	}

	&:nth-child(#{$nth}+1) {
		clear: none;
	}
}

// -------------------------------------------------------------------
// [Visually Hidden]
// @Description:
// Visually hides an item but leaves it available for accessability purposes.
// @Usage:
// @include visually-hidden;
// -------------------------------------------------------------------

@mixin visually-hidden {
	border: 0 !important;
	clip: rect(1px 1px 1px 1px);
	/* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px !important;
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

// -------------------------------------------------------------------
// [Font Icon]
// @Description:
// Use font for icons in all all broswer EVEN IE7
// Note: Be sure you have imported the font
// Charater Code: Example \f001,
// Psudeo: Defaults to : before if left blank, set to true if want :after
// Font-Family: Defaults to defined value for $FONT-ICONS in constants.scss
// @Usage:
// @include font-icon(CharaterCode, Psudeo, Font-Family);
// Uses Before Psuedo:
// .icon_num_1 { @include font-icon("\f000") }
// Uses After Psuedo:
// .icon_num_2 { @include font-icon("\f001", true) }
// Uses After Psuedo and sets a font type:
// .icon_num_3 { @include font-icon("\f001", true, "MY-font") }
// -------------------------------------------------------------------

@mixin font-icon($char, $after: false, $font-family: $FONT_ICONS ) {
	.ie7 & {
		zoom: "expression( this.runtimeStyle['zoom'] = '1', this.innerHTML = #{$char} )";
	};

	@if $after {
		&:after,
		& .after {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			content: "#{$char}";
			font-family: $font-family;
			font-style: normal;
			font-variant: normal;
			font-weight: normal;
			speak: none;
			text-transform: none;
		}
	}

	@else {
		&:before,
		& .before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			content: "#{$char}";
			font-family: $font-family;
			font-style: normal;
			font-variant: normal;
			font-weight: normal;
			speak: none;
			text-transform: none;
		}
	}
}

// -------------------------------------------------------------------
// [Mark up retina]
// @Description:
// Swap static images in markup for retina versions when displayed on retina device.
// @Usage:
// @include markUpRetina("/img/markupImage", "/img/markupImageRetina.jpg");
// -------------------------------------------------------------------

@mixin markUpRetina($normalImage, $imgType: ".jpg", $suffix: "@2x" ) {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3/2),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5),
	only screen and (-webkit-min-device-pixel-ratio: 2) {
		$retinaImage: "#{$normalImage}#{$suffix}#{$imgType}";

		img[src*="#{$normalImage}"] {
			content: url("../images#{$retinaImage}");
			width: image-width("../images#{$retinaImage}") / 2;
			height: image-height("../images#{$retinaImage}") / 2;
		}
	}
}

// -------------------------------------------------------------------
// [IE Gradients]
// @Description:
// Sets gradient for use in IE8
// @Usage:
// @include IEGradient(#fff, #000);
// -------------------------------------------------------------------

@mixin IEGradient( $start, $end ) {
	filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$start}', endColorstr='#{$end}');
}

// -------------------------------------------------------------------
// [CSS3 Pie]
// @Description:
// Incude css pie on the chosen element.
// @Usage:
// @include css3Pie;
// -------------------------------------------------------------------

@mixin css3Pie {
	behavior: url("/PIE.htc");
}

// -------------------------------------------------------------------
// [Material Shadow]
// A mixin which helps you to add depth to elements according to the Google Material Design spec:
// http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
//  *
// Please note that the values given in the specification cannot be used as is. To create the same visual experience
// the blur parameter has to be doubled.
//  *
// Adapted from a LESS version at https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
//  *
// Original Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
//  *
// Example usage:
//  *
// .card {
//     width: 95px;
//     height: 95px;
//     background: #f4f4f4;
//     -webkit-transition: all 250ms;
//     -moz-transition: all 250ms;
//     transition: all 250ms;
//     @include box_shadow(1);
//     &:hover {
//         @include material-shadow(3);
//         -webkit-transform: translateY(-5px);
//         -moz-transform: translateY(-5px);
//         transform: translateY(-5px);
//     }
// }
// -------------------------------------------------------------------

@mixin material-shadow($level) {
	@if $level == 1 {
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}

	@else if $level == 2 {
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}

	@else if $level == 3 {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	}

	@else if $level == 4 {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}

	@else if $level == 5 {
		box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	}
}

@mixin padding($spacing) {
	@if $spacing == 'base' {
		padding: $spacing-base;
	}
	@else if $spacing == 'medium' {
		padding: $spacing-medium;
	}
 	@else if $spacing == 'large' {
 		padding: $spacing-large;
 	}
}

@mixin horizontal-padding($spacing) {
	@if $spacing == 'base' {
		padding-left: $spacing-base;
		padding-right: $spacing-base;
	}
	@else if $spacing == 'medium' {
		padding-left: $spacing-medium;
		padding-right: $spacing-medium;
	}
 	@else if $spacing == 'large' {
 		padding-left: $spacing-large;
		padding-right: $spacing-large;
 	}
}

@mixin vertical-padding($spacing) {
	@if $spacing == 'base' {
		padding-top: $spacing-base;
		padding-bottom: $spacing-base;
	}
	@else if $spacing == 'medium' {
		padding-top: $spacing-medium;
		padding-bottom: $spacing-medium;
	}
 	@else if $spacing == 'large' {
 		padding-top: $spacing-large;
		padding-bottom: $spacing-large;
 	}
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {
		@content;
	}
	
	@include optional-at-root(':-moz-placeholder') {
		@content;
	}
	
	@include optional-at-root('::-moz-placeholder') {
		@content;
	}
	
	@include optional-at-root(':-ms-input-placeholder') {
		@content;
	}
}

// https://github.com/aaronjamesyoung/simplest-flexbox-grid/blob/master/_fg_grid.scss

// Global variables:
// Unitless, interpreted as pixels
$_fg_gutter: 24 !default; // gutter between columns. Set as desired.
$_fg_padding: 12 !default; // padding for column boxes. Set as desired, can override for individual columns.


// *** Main mixin to create a per-row layout *** //
@mixin _fg($colList, $gutter: $_fg_gutter, $padding: $_fg_padding) {
  @if type-of($colList) == number { // call _fg_grid() directly
    @include _fg_grid($colList, $gutter, $padding);

  } @else if type-of($colList) == list and length($colList) > 1 {

    // Count how many columns there are altogether
    $columnCount: 0;
    @each $i in $colList {
      $columnCount: $columnCount + $i;
    }

    @include _fg_grid($columnCount, $gutter, $padding);

    @for $i from 1 through length($colList) { // set child items widths using nth:child()
      $c: nth($colList, $i);
      & > :nth-child(#{length($colList)}n+#{$i}) {
        @include _fg_width($c/$columnCount, $gutter);
      }
    }
  }
}


// *** Set up grid with equal width columns *** //
@mixin _fg_grid($cols: 0, $gutter: $_fg_gutter, $padding: $_fg_padding) {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-left: (-1 * $gutter) * 1px;

  > * { padding: $padding * 1px;
      margin-left: $gutter * 1px;
      box-sizing: border-box; }

  $calc_percent: (1/$cols) * 100%;
  $calc_gutter_allowance: $gutter * 1px;

  > * { width: calc(#{$calc_percent} - #{$calc_gutter_allowance}); }
}


// *** width override for a column *** //
@mixin _fg_width($ratio, $gutter: $_fg_gutter) {
  $calc_percent: $ratio * 100%;
  $calc_gutter: $gutter * 1px;
  width: calc(#{$calc_percent} - #{$calc_gutter});
}
