.c-table-wrapper {
	position: relative;
	margin-bottom: 30px;

	&:after {
		@include linear-gradient(to right, rgba($c-black, 0) 0%, rgba($c-black, 0.2) 100%, $fallback: transparent);
		bottom: 0;
		content: "";
		display: none;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	&__inner {
		width: 100%;
	}

	&.is-overflowed {
		&:after {
			display: block;
		}

		.c-table-wrapper__inner {
			-ms-overflow-style: -ms-autohiding-scrollbar;
			-webkit-overflow-scrolling: touch;
			overflow-x: scroll;
		}
	}
}
