.c-erp-section {
	@include breakpoint($bp-medium) {
		display: flex;
		align-items: stretch;
	}
	
	&.u-show\@medium {
		@include breakpoint($bp-medium) {
			display: flex !important;
		}
	}
	
	&__item {
		margin-bottom: 50px;
		position: relative;
		width: 100%;
		
		@include breakpoint($bp-medium) {
			margin-bottom: 0;
			padding-left: 3%;
			padding-right: 3%;
		}
		
		&:first-child {
			padding-left: 0;
		}
		
		&:last-child {
			padding-right: 0;
		}
	}
	
	&__title {
		font-size: toRems(24px);
		font-weight: 700;
		text-align: center;
		
		@include breakpoint($bp-medium) {
			font-size: toRems(30px);
		}
	}
	
	&__name {
		font-weight: 300;
		margin-bottom: 30px;
		margin-top: 30px;
		
		@include breakpoint($bp-medium) {
			font-size: toRems(24px);
			margin-top: 100px;
			margin-bottom: 0;
		}
	}
	
	&__form {
		padding-top: toRems(30px);
		
		@include breakpoint($bp-medium) {
			padding-top: toRems(100px);
		}
		
		.o-inline-input-label {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__image {
		position: relative;
		z-index: 1;
		
		@include breakpoint($bp-medium) {
			display: flex;
			align-items: center;
			flex: 1;
		}
		
		&:after {
			@extend .c-loader;
			@extend .c-loader--white;
			content: "";
			left: 50%;
			margin-left: -20px;
			margin-right: -20px;
			position: absolute;
			top: 50%;
			z-index: -1;
		}
		
		img {
			max-width: 250px;
			width: 100%;
		}
	}
	
	&__rating {
		display: block;
		font-weight: 700;
		font-size: toRems(60px);
		line-height: 1;
		margin-top: 30px;
		
		@include breakpoint($bp-medium) {
			font-size: toRems(80px);
		}
	}
	
	&__content {
		display: flex;
		flex-direction: column;
		
		@include breakpoint($bp-medium) {
			height: 100%;
			min-height: 450px;
		}
	}
	
	&__rating-label {
		display: block;
		font-weight: 700;
		font-size: 40px;
		line-height: 1;
		margin-bottom: 30px;
		
		em {
			display: block;
			font-size: 16px;
			font-family: $font-proxima;
			font-style: normal;
		}
	}
	
	&__plus {
		line-height: 1;
		font-size: 60px;
		font-weight: bold;
		display: block;
		text-align: center;
		margin-top: 30px;
		
		@include breakpoint($bp-medium) {
			position: absolute;
			margin-top: 0;
			font-size: 80px;
			left: 100%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	&__total {
		@include breakpoint($bp-small) {
			display: flex;
			justify-content: center;
		}
		
		li {
			margin-bottom: 30px;
			
			@include breakpoint($bp-small) {
				padding: 0 25px;
				margin-bottom: 0;
			}
			
			@include breakpoint($bp-medium) {
				padding: 0 50px;
			}
			
			&:first-child {
				padding-left: 0;
			}
			
			&:last-child {
				padding-right: 0;
			}
		}
		
		.c-erp-section__rating {
			margin-top: 0;
		}
	}
	
	&--ratings {
		.c-erp-section__item {
			margin: toRems(40px) 0 0;
			padding: 0;
			
			&.has-plus {
				&:after {
					color: $c-white;
					content: "+";
					font-size: 80px;
					font-weight: bold;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(50%, -50%);
				}
			}
		}
	}
}