.ui-datepicker {
	background-color: #fff;
	border: 2px solid $c-font;
	box-shadow: 0 0 8px rgba(0,0,0,.2);
	display: none;
	margin-top: 4px;
	padding: 10px;
	width: 300px;
	position: relative;
	z-index: 20 !important;
}
.ui-datepicker a,
.ui-datepicker a:hover {
	font-weight: bold;
	text-decoration: none;
}
.ui-datepicker a:hover,
.ui-datepicker td:hover a {
	color: $c-font;
	        transition: color 0.1s ease-in-out;
}
.ui-datepicker .ui-datepicker-header {
	margin-bottom: 4px;
	text-align: center;
}
.ui-datepicker .ui-datepicker-title {
	// font-weight: 700;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	cursor: pointer;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	height: 30px;
	line-height: 1;
	margin-top: -2px;
	font-size: 32px;
	width: 30px;
}
.ui-datepicker .ui-datepicker-prev {
	float: left;
	text-align: left;
	padding-left: 5px;
	
}
.ui-datepicker .ui-datepicker-next {
	float: right;
	text-align: right;
	padding-right: 5px;
}
.ui-datepicker .ui-datepicker-prev:before {
	content: "\00ab";
}
.ui-datepicker .ui-datepicker-next:before {
	content: "\00bb";
}
.ui-datepicker .ui-icon {
	display: none;
}
.ui-datepicker .ui-datepicker-calendar {
  table-layout: fixed;
	width: 100%;
}
.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
	text-align: center;
	padding: 4px 0;
}
.ui-datepicker .ui-datepicker-calendar td {
	border-radius: 4px;
	        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.ui-datepicker .ui-datepicker-calendar td:hover {
	background-color: #eee;
	cursor: pointer;
}
.ui-datepicker .ui-datepicker-calendar td a {
	text-decoration: none;
}
.ui-datepicker .ui-datepicker-current-day {
	background-color: $c-font;
}
.ui-datepicker .ui-datepicker-current-day a {
	color: #fff
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
	background-color: #fff;
	cursor: default;
}

.ui-datepicker-month {
	margin-right: $spacing-xxsmall;
}