.c-product-slider-nav-item {
	outline: none;
	transition: all 300ms $global-easing;
	border: 1px solid palette(grey, x-light);
	display: inline-block;
	
	@include breakpoint($bp-medium) {
		margin-bottom: $spacing-small;
		display: block;
	}  
	
	&.is-active {
		border-color: palette(grey, mid-light);
	}
	
	img {
		width: 78px;
	}
	
}