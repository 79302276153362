.c-search-bar {
	@extend %c-form-text;
	background-color: palette(grey, xxx-light);
	color: $c-white;
	left: 0;
	opacity: 0;
	padding: $spacing-base 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: $header-height;
	transform: translateY(-3px);
	transition: all .3s ease-in-out;
	visibility: hidden;
	z-index: 10;

	@include breakpoint($bp-large) {
    	top: $header-height-large;
	}
	
	@include breakpoint($bp-xlarge) {
    	top: $header-height-xlarge;
	}

	&.is-active {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}

	form	{
		display: flex;
		justify-content: center;
	}

	input {
		background-color: $c-white;
		border: none;
		padding-left: 20px;
		border-radius: $global-radius 0 0 $global-radius;

		@include breakpoint($bp-large) {
			max-width: 60%;
			padding: 14px 20px;
			font-size: 20px;
		}
	}

	button {
		padding: 15px $spacing-base;
		width: 100px;

		@include breakpoint($bp-large) {
			padding: 15px $spacing-base;
			width: 140px;
		}
	}
}