.c-installer-connect {
	&__item {

		margin-bottom: $spacing-large;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include breakpoint($bp-large) {
			margin-bottom: 0;
		}
		
		.c-btn {
			margin-top: $spacing-base;
		}
		
		&--primary {
			@include breakpoint($bp-large) {
				@include span-columns(7);
				padding-right: $spacing-large * 2;
				position: relative;

				&:after {
					border-right: 2px dotted palette(grey, mid-light);
					content: '';
					display: none;
					height: 100%;
					left: calc(100% + 10px);
					pointer-events: none;
					position: absolute;
					top: 0;
					
					@include breakpoint($bp-large) {
						display: block;
					}  
				}
			}
		}

		&--secondary {
			@include breakpoint($bp-large) {
				@include span-columns(4);
				@include omega;
				float: right;
			}
		}
		
	}
}