.o-toggle-trigger {
	@extend %font-headline;
	@extend %toggle-icon;
	position: relative;
	outline: 0;
	display: block;
	font-weight: $font-weight-bold;

	.js & {
		cursor: pointer;
	}

	&:after {
		right: 0;
	}

}

.o-toggle-content {
	.js & {	
		display: none;
	}
}