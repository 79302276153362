// Taken from hard-coded CSS in original code
$notification-height: 160px;
$notification-height-large: 70px;

body {
	transition: padding-top $global-duration $global-easing;

	&.js-notif-open {
		transition: none;
		padding-top: $notification-height;
		
		@include breakpoint($bp-medium) {
			padding-top: $notification-height-large;
		}

		.c-notification-bar {
			height: $notification-height;

			@include breakpoint($bp-medium) {
				height: $notification-height-large;
			}
		}
		
		.c-header {
			top: $notification-height;

			@include breakpoint($bp-medium) {
				top: $notification-height-large;
			}

			@include breakpoint($bp-large) {
				top: 0;
			}
		}
		#mm-js-nav {
			top: $header-height + $notification-height;

			@include breakpoint($bp-medium) {
				top: $header-height + $notification-height-large;
			}
			
			@include breakpoint($bp-large) {
				top: 0;
			}

		}
		.c-sites-nav {
			top: 20px + $notification-height;

			@include breakpoint($bp-medium) {
				top: 20px + $notification-height-large;
			}

			@include breakpoint($bp-large) {
				top: 0;
			}
		}	

		.c-header,
		.c-sites-nav {
			transition: none;
		}
	}

	.c-header,
	.c-sites-nav {
		transition: top $global-duration $global-easing; // To make the .c-notification-bar removal appear smoother
	}
}

.c-notification-bar {
	background-color: #f1f1f1;
	color: #333;
	display: flex;
	height: 0;
	justify-content: flex-start;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	transition: height 0.2s ease-in-out;
	width: 100%;
	z-index: 99999;

	* {
		font-size: 13px;
		line-height: 13px;

		@include breakpoint($bp-small) {
			font-size: 14px;
			line-height: 14px;
		}
	}

	> * {
		align-items: center;
		display: flex;
		height: 100%;
	}

	> div {
		&:first-child {
			color: #fff;
			padding-left: 30px;
			padding-right: 30px;
			position: relative;

			&:before {
				background-color: $c-notice;
				border-radius: 50%;
				content: '';
				display: block;
				height: 200px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 200px;
				z-index: -1;
			}

			@include breakpoint($bp-xsmall) {
				box-sizing: content-box;
				flex-grow: 0;
				flex-shrink: 0;
				padding-left: 15px !important;
				padding-right: 15px !important;
				width: 50px;
			}
		}

		&:nth-child(2) {
			padding-left: 30px;
			padding-right: 30px;

			@include breakpoint($bp-xsmall) {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}

			a {
				color: $c-notice;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	// Close button
	> a {
		display: flex;
		margin-left: auto;
		margin-right: 18px;

		@include breakpoint($bp-medium) {
			margin-right: 30px;
		}
	}
}
