.c-product-card {
	background-color: $c-white;
	box-shadow: 0px 0px 0px 1px $c-border;
	padding: $spacing-medium;
	position: relative;
	transition: box-shadow .2s $global-easing, margin-bottom .2s $global-easing;
	width: 100%;
	
	&__indicator {
		border-color: transparent transparent palette(grey, xxx-light)
		transparent;
		border-style: solid;
		border-width: 0 22px 14px 22px;
		bottom: 100%;
		content: "";
		height: 0;
		left: 50%;
		bottom: -62px;
		position: absolute;
		transform: translate(-50%, 0);
		transition: bottom .2s $global-easing;
		width: 0;
		visibility: hidden;
		display: block;
	}
	
	&.is-expanded {
		box-shadow: 0px 0px 0px 3px palette(grey, xx-light);
		
		.c-product-card__indicator {
			bottom: -49px;
			visibility: visible;
		}
	}
	
	&--no-padding {
		padding: 0;
		box-shadow: none;
	}

	// Used on product literature for double sised headers as match height
	// doesn't kick in on iOS when you change orientation
	&--less-bottom-padding {
		padding-bottom: $spacing-base;
	}
	
	img {
		margin: 0 auto $spacing-medium;
		
		.touchevents & {
			pointer-events: none;
		}
	}
	
	&__col-heading {
		border-bottom: 1px solid palette(grey, xx-light);
		font-weight: 600;
		margin-bottom: 15px;
		padding-bottom: 15px;
		
		@include breakpoint($bp-medium) {
			font-size: toRems(20px);
		}
	}
	
	&__title {
		@include heading-9;
		font-weight: $font-weight-bold;
		margin-bottom: 0;
		height: 2em;
		min-height: 38px;
		
		@include breakpoint($bp-large) {
			min-height: 48px;
		}  
		
	}
	
	&__content {
		text-align: center;
		
		h3 a {
			color: inherit;
			text-decoration: none;
		}
	}
	
	label {
		display: block;
		padding-bottom: $spacing-large;
		
		// iOS bug, elements inside the label can't be clickable
		.touchevents & {
			.u-heading-10, p, .c-features-list, .c-product-card__content, .c-input-btn {
				pointer-events: none
			}
		}
		
		&:hover {
			cursor: pointer;
			
			.c-input-btn {
				&:after {
					background-color: darken($c-brand, 10%);
				}
			}
		}
	}
	
	.c-input-btn {
		position: absolute;
		bottom: $spacing-medium;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
	}
}
