.o-callouts-grid {
	> div {
		
		> .o-callouts-grid__item {
			margin-bottom: $spacing-base;
			position: relative;
			
			&:before {
				background: linear-gradient(to top, rgba(0,0,0,.9) 0%, transparent 100%);
				content: "";
				display: block;
				left: 0;
				top: 0;
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 0;
			}
			
			@include breakpoint($bp-large) {
				height: 280px;
			}  
			
			.c-visual-card__content {
				position: relative;
				z-index: 1;
			}
		}
		
		@include breakpoint($bp-large) {
			display: inline-block;
			margin-left: -4px;
			vertical-align: top;
			width: 50%;
		}  
		
		&:first-child {
			@include breakpoint($bp-large) {
				border-right: $spacing-base solid $c-white;
				
				> .o-callouts-grid__item {
					border-bottom: none;
					height: 580px;
				}
			}  
		}
		
	}
}