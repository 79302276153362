.c-pagination {
	@include span-columns(12);
	text-align: center;
	padding: $spacing-large 0 0;

	li {
		display: inline-block;
		vertical-align: middle;

		a:not(.c-btn) {
			color: $c-font;
			display: block;
			padding: 10px 14px;
		}

		&.is-active {
			position: relative;

			&:after {
				@extend %i-hexagon;
				color: $c-brand;
				font-size: 30px;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
				
				@media all and (-ms-high-contrast:none) {
					*::-ms-backdrop, & {
						transform: translate3d(-50%, -45%, 0);
					} /* IE11 */
				}
				
			}
			a {
				color: $c-white;
				display: block;
				position: relative;
				text-decoration: none;
				z-index: 2;
			}
		}
	}
}

@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop, .c-pagination li.is-active:after {
		transform: translate3d(-50%, -44%, 0);
	} /* IE11 */
}