.c-hero {
	position: relative;
	background-position: center center; // Might need to change this as it focusses on the right hand side of the image
	background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	width: 100%;
	background-size: cover;
	transform: translate3d(0,0,0);

	
	@include breakpoint($bp-large) {
		// background-size: cover;
		align-items: center;
		justify-content: center;
		padding-bottom: toRems(50px);
		padding-top: toRems(50px);
		
	}
	
	// We only want the background image to stay on the homepage
	&--show-bg\@large {
		
		@include breakpoint($bp-large-max) {
			background-image: url(/images/elements/hexagon-bg-grey.jpg) !important;
			background-size: 140%;
			background-position: 50% 0;
		}  
	}
	
	&--bg-img-focus-right {
		background-size: contain;
		background-position: 100% 0;
		
		@include breakpoint($bp-large) {
			background-size: cover;
		}  
	}

	&--bg-img-focus-left {
		background-size: contain;
		background-position: 50% 0%;
		
		@include breakpoint($bp-large) {
			background-size: cover;
		}  
	}
	
	.o-grid {
		width: 100%;
		z-index: 1;
		
		@include breakpoint($bp-large) {
			margin: 0;
			display: flex;
			align-items: center; 
			
			@media all and (-ms-high-contrast:none) {
				*::-ms-backdrop, & {
					height: 540px;
				} /* IE11 needs height for flexbox items */
			}
		}  
	}
	
	&__image {
		@include span-columns(5);
		margin-top: $spacing-base;
		margin-left: $spacing-base;
		margin-bottom: -36px;
		position: relative;
		z-index: 10;
		
		@include breakpoint($bp-large) {
			@include span-columns(6);
			z-index: auto;
			margin: 0;
		}  

		&--weighted-left {
			@include breakpoint($bp-large) {
				@include span-columns(5);
			}
		}
		
		img {
			min-width: 100%;
			
			@include breakpoint($bp-large) {
				margin: 0 auto;
				min-width: auto;
			}  
		}
	}
	
	&__content {
		@include span-columns(12);
		background-color: palette(grey, super-light);
		font-weight: 300;
		text-align: center;
		padding: $spacing-medium;
		position: relative;
		z-index: 1;
		
		@include breakpoint($bp-large-max) {
			color: $c-font;
		}  
		
		// Marker to show centre for lining up slanted bg
		// &:before {
		// 	content: "";
		// 	width: 1px;
		// 	background-color: blue;
		// 	height: 2000px;
		// 	display: block;
		// 	position: absolute;
		// 	left: 50%;
		// 	margin-left: -1px;
		// 	top: 0;
		// }
		
		@include breakpoint($bp-large) {
			@include span-columns(6);
			padding: $spacing-medium 0 $spacing-medium $spacing-medium;
			background-color: transparent;
			float: right !important;
			// padding-top: 0;
			text-align: left;
			
			h1 b,
			h1 strong {
				display: block;
			}
			
			&:after {
				display: none !important; // Hide the concave border
			}
			
			.c-hero--no-image & {
				margin-left: auto;
			}
		}
		
		*:last-child {
			margin-bottom: 0;
		}
		
		p {
			
			@include breakpoint($bp-large) {
				font-size: $font-size-10;
				margin-bottom: toRems(40px);
			}
		}
		
		&--text-shadow {
			h2,
			p {
				@include breakpoint($bp-large) {
					text-shadow: 0 0 40px $c-white, 0 0 10px $c-white, 0 0 100px $c-white;
				}
			}
		}
		
		&--box {
			background-color: rgba(0,0,0,.6);
			padding: $spacing-large;
			color: $c-white;
			
			@include breakpoint($bp-large) {
				@include span-columns(5);
			}  
		}

		&--weighted-right {
			@include breakpoint($bp-large) {
				@include span-columns(7);
			}
		}
	}

	&__buttons {
		.c-btn {
			margin: 0 $spacing-small $spacing-small $spacing-small;
		}
		@include breakpoint($bp-large) {
			.c-btn {
				margin: 0 $spacing-base $spacing-small 0;
			}
		}
	}
	
	// "Mask" the background image
	&__image-cover {
		width: 2360px;
		position: absolute;
		bottom: -1px;
		left: 50%;
		transform: translateX(-50%);
		right: 0;
		top: 0;
		z-index: 0;
		display: none;
		background-position: 50% 100%;
		margin-left: 250px; // Nudge along so that it sits under the text content
		background-image: url(../images/elements/hexagon-hero-image-cover.svg);
		background-size: cover;
		background-repeat: no-repeat;
		
		@media all and (-ms-high-contrast:none) {
			*::-ms-backdrop, & {
				width: 2390px;
			} /* IE11 */
		}
		
		// The image goes only so far, this will help cover up and overlap of
		// the underlying background image
		&:before,
		&:after {
			content: '';
			position: absolute;
			height: 100%;
			width: 1000px;
			display: block;
			background-color: $c-white;
		}
		
		&:before {
			right: 99%;
			top: 0;
		}
		
		&:after {
			left: 99%;
			top: 0;
		}
		
		@include breakpoint($bp-large) {
			display: block;
		}  
	}
	
	&__action-wrapper {
		bottom: 0;
		left: 50%;
		position: absolute;
		transform: translate(-50%, 50%);
		z-index: 1;
	}
	
	&--swap-order-weighted-right {
		.c-hero__image {
			@include breakpoint($bp-large) {
				@include span-columns(4);
				order: 1;
			}
		}
		
		.c-hero__content {
			@include breakpoint($bp-large) {
				@include span-columns(8);
			}
		}
	}
	
	&--swap-order {
		.c-hero__image {
			@include breakpoint($bp-large) {
				order: 1;
			}
		}
	}
	
	&--weighted-left {
		.c-hero__image {
			@include breakpoint($bp-large) {
				@include span-columns(4);
			}
		}
		
		.c-hero__content {
			@include breakpoint($bp-large) {
				@include span-columns(8);
				padding-right: 30%;
			}
		}
	}
	
	&--no-bg-image\@large-max {
		@include breakpoint($bp-large-max) {
			background-image: none !important;
		}
	}
	
	&--gradient\@large {
		&:before {
			background: linear-gradient(90deg, #f2f2f2 0%, #fefefe 64%, transparent 100%);
			bottom: 0;
			content: "";
			display: none;
			left: 0;
			position: absolute;
			right: 30%;
			top: 0;
			
			@include breakpoint($bp-large) {
				display: block;
			}
		}
	}
	
	&--light-text {
		@include breakpoint($bp-large) {
			color: $c-white;
			
			.c-btn {
				color: $c-white;
				border-color: $c-white;
			}
		}
	}
	
	// Used in page, centered text
	&--visual {
		background-color: palette(grey, light);
		align-items: center;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		min-height: toRems(500px);
		font-size: toRems(24px);
		
		@media all and (-ms-high-contrast:none) {
			*::-ms-backdrop, & {
				height: toRems(500px);
			} /* IE11 */
		}
		
		@include breakpoint($bp-medium) {
			font-size: toRems(40px);
		}
		
		.c-hero__content {
			background-color: transparent;
			color: $c-white;
			text-align: center;
			width: 100%;
		}
		
		h2 {
			line-height: 1;
			margin-bottom: 0;
			font-weight: $font-weight-bold;
			
			@include breakpoint($bp-medium) {
				margin-bottom: -10px;
			}
		}
		
		p {
			font-size: toRems(24px);
			font-weight: 300;
			margin: 0;
			
			@include breakpoint($bp-medium) {
				font-size: toRems(40px);
			}
		}
		
	}
	
	&--visual-medium {
		@extend .c-hero--visual;
		min-height: toRems(180px);
		
		@include breakpoint($bp-small) {
			min-height: toRems(450px);
		}
	}
	
	// The image bleeds into the section below
	&--overlapping-image {
		margin-bottom: 30px; // Accommodate for the overlapping image
		
		.c-hero__image {
			@include breakpoint($bp-large) {
				margin-bottom: -80px;
				margin-top: 80px;
			}  
		}
	}
	
	&--smaller-text {
		p {
			font-size: toRems(16px);
			font-weight: 300;
			margin-top: 1.3em;
			
			@include breakpoint($bp-medium) {
				font-size: toRems(20px);
			}
		}
	}

	&--home {
		
		@include breakpoint($bp-large) {
			min-height: toRems(500px);
		}

		@include breakpoint(1600px) {
			min-height: toRems(650px);
		}

		@include breakpoint(1900px) {
			min-height: toRems(750px);
		}

		.c-hero__content {
			@include breakpoint($bp-large) {
				@include shift(6);
				color: white;
			}
		}

		.c-hero__image {
			width: 100%;
			margin: 0;

			@include breakpoint($bp-large) {
				width: 100%;
				z-index: auto;
				margin: 0;
			}  
		}

	}
}