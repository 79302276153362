.o-box {
	@include clearfix;
	@include padding(base);
	// clear: both;
	background: palette(grey, super-light);
	background: darken(palette(grey, super-light), 1%);
	margin-bottom: $spacing-base;
	color: $c-font;

	@include breakpoint($bp-small) {
		@include padding(medium);
	}

	&--outline {
		background: $c-white;
		border: 1px solid $c-border;
		border: 5px solid $c-border;
	}

	&--dark {
		background: palette(grey, x-dark);
		color: $c-white;

		h2 {
			color: $c-white;
		}
	}

	&--blue {
		background: $c-brand;
		color: $c-white;
	}

	&--compact {
		padding: $spacing-base $spacing-base;

		@include breakpoint($bp-small) {
			padding: $spacing-base $spacing-medium;
		}
	}

	&--no-margin {
		margin-bottom: 0;
	}

}