.c-sites-nav {
	position: fixed;
	text-align: left;
	font-size: 14px;
	right: 106px;
	top: 20px;
	z-index: 500;
	
	@include breakpoint(max-width 350px) {
		right: 83px;
	} 
	
	@include breakpoint($bp-large) {
		display: none;
	}  
	
	> li {
		background-color: $c-brand;
		
		&:not(:first-child) {
			display: none;
		}
		
		&:first-child {
			background-color: $c-brand;
			transition: all 300ms ease-in-out;
			border-radius: 5px;
			margin-bottom: -5px;
			position: relative;
			opacity: 0;
			
			&.is-ready {
				opacity: 1;
			}
			
			&.is-open,
			&:hover {
				a {
					background-color: $c-brand;
					color: $c-white;
				}
			}
			
			&.is-open {
				svg {
					transform: rotate(180deg);
				}
			}

			a {
				color: $c-white;
				border-radius: 5px;
				padding-right: 20px;
				width: 125px;
				
				svg {
					transition: all .3s $global-easing;
					position: absolute;
					right: 7px;
					top: 9px;
					height: 10px;
					width: 10px;
					fill: $c-white;
				}
				
			}
		}
		
		&:last-child {
			border-radius: 0 0 5px 5px;
		}
		
		> a {
			color: $c-white;
			display: block;
			padding: 6px 10px;
			line-height: 1;
			text-decoration: none !important;
			text-decoration: none;
		}
		
	}
	
	&__inner {
		border-radius: 0 0 5px 5px;
		background-color: $c-brand;
		display: none;
		padding: 5px;
		position: absolute;
		width: 125px;
		z-index: 10;
		top: 23px;
		
		li {
			border-bottom: 1px solid rgba(255,255,255,.2);
			margin-bottom: 0 !important;
			
			svg {
				display: none;
			}
			
			&:last-child {
				border-bottom: none;
				
				a {
					padding-bottom: 0;
				}
			}
			
			a {
				color: $c-white;
				display: block;
				padding: 4px 5px;
				text-decoration: none !important;
				
				&:hover {
					opacity: .7;
				}
			}
		}

	}
	
}
