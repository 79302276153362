.c-tooltip {
	display: inline-block;
	margin-left: $spacing-xxsmall;
	position: relative;
	z-index: 10;
	letter-spacing: normal;
	position: relative;
	top: -2px;
	
	&__trigger {
		background-color: $c-help;
		border-radius: 50%;
		color: $c-white;
		cursor: pointer;
		font-size: $font-size-10;
		height: 24px;
		padding-top: 3px;
		font-weight: bold;
		text-align: center;
		width: 24px;
		line-height: 1;

		&--text {
			width: auto;
			background: none;
			color: $c-help;
			font-size: $font-small;
		}
	}
	
	&__message {
		background-color: $c-help;
		border-radius: 4px;
		color: $c-white;
		font-size: 15px;
		font-weight: normal;
		left: -$spacing-large;
		opacity: 0;
		padding: $spacing-small;
		position: absolute;
		text-align: left;
		top: calc(100% + 16px);
		transform: translate3d(-50%, 10px, 0) scale(.99);
		transition: all .3s $global-easing;
		width: 300px;
		line-height: 1.2;
		box-shadow: 0 3px 7px rgba(0,0,0,.2);
		pointer-events: none;

		@include breakpoint($bp-medium) {
			left: 50%;
		}
		
		&:before {
			border-color: transparent transparent $c-help transparent;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			bottom: 100%;
			content: '';
			display: block;
			height: 0;
			left: 80%;
			position: absolute;
			transform: translateX(-50%);
			width: 0;

			@include breakpoint($bp-medium) {
				left: 50%;
			}
		}
	}
	
	&:hover {
		
		.c-tooltip__message {
			opacity: 1;
			transform: translate3d(-50%, 0, 0) scale(1);
		}
	}
	
	*:last-child {
		margin-bottom: 0;
	}
	
	&--right {
		@include breakpoint($bp-large) {
			.c-tooltip__message {
				transform: translate3d(-90%, 10px, 0) scale(.99);
				
				&:before {
					left: 90%;
				}
			}
			
			&:hover {
				.c-tooltip__message {
					transform: translate3d(-90%, 0, 0) scale(1);
				}
			}
		}  
	}
}