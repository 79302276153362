.c-split-cta {
	position: relative;
	
	.o-grid {
		position: relative;
		z-index: 1;
		padding-top: $spacing-medium;
		padding-bottom: $spacing-medium;
		text-align: center;
		
		@include breakpoint($bp-medium) {
			text-align: left;
			padding-top: 60px;
			padding-bottom: 60px;
		}  
		
		@include breakpoint($bp-large) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}  
	}
	
	&__image {
		margin-bottom: $spacing-medium;
		
		@include breakpoint($bp-medium) {
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
		}  
		
		@include breakpoint($bp-large) {
			margin-left: -80px;
		}  
		
		img {
			margin: 0 auto;
		}
		
	}
	
	&__content {
		@include breakpoint($bp-medium) {
			margin-left: 380px;
		}  
		
		@include breakpoint($bp-large) {
			margin-left: 260px;
			padding: 0 $spacing-large;
		}  
	}
	
	&__action {
		
		flex: 330px 0 0;
		
		@include breakpoint($bp-medium) {
			margin-left: 380px;
		}  
		
		@include breakpoint($bp-large) {
			margin-left: 0;
		}  
	}
	
	&__bg-image {
		background-repeat: no-repeat;
		// background-size: 100%;
		background-position: 0 50%;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 0;
	}
}