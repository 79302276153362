.c-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: $z-header;
	
	@include breakpoint($bp-large) {
		position: relative;
	}  
}