.c-sitemap-list {

	> li {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: $spacing-small;

		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-base;
			font-size: $base-font-size;
		}

		> a {
			color: palette(grey, x-light);
		}

		ul {
			> li {
				font-weight: normal;
				text-transform: none;

				a {
					color: palette(grey, mid-light);
				}
			}
		}
	}
}