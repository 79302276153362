// Moved to a seperate file to keep things easier to digest

.c-nav-content {
	
	@include breakpoint($bp-large) {
		background: linear-gradient(130deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 65%, palette(grey, xx-light) 100%);
		left: 0;
		position: absolute;
		right: 0;
		padding: 8px 0 $spacing-large 0;
		box-shadow: 0 5px 10px rgba(0,0,0,.1);
		z-index: $z-nav-content;
		transition: all .3s ease-in-out;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		// transform: translateY(3px);
	}
	
	
	> h2 {
		background-color: $c-brand;
		color: $c-white;
		display: none;
		font-size: $font-size-10;
		font-weight: $font-weight-bold;
		margin: 0;
		padding: $spacing-base 0;
		
		@include breakpoint($bp-large) {
			display: block;
		}  
	}
	
	.c-nav-content__title {
		@include span-columns(12);
		@include heading-9;
		// float: none;
		font-weight: bold;
		border-bottom: 1px solid #c1c1c1;
		// padding-top: .6rem;
		padding-bottom: .6rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
		
		@include breakpoint($bp-large) {
			margin-bottom: 2rem;
		}  

		&:first-child {
			@include breakpoint($bp-large-max) {
				margin-top: 0;
			}
		}
		
		&--no-bottom-border {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 1rem;
		}
		
		&--top-border {
			border-top: 1px solid #c1c1c1;
			padding-top: 2rem;
		}
	}
	
	&__side-box {
		float: left;
		width: 100%;
		
		img {
			float: right;
			margin: 0 0 0 $spacing-base;
		}
	}
	
	.o-grid {
		@include breakpoint($bp-large-max) {
			// padding: 0;
		}
		
		@include breakpoint($bp-large) {
			transform: scale(.99);
			transition: all .3s $global-easing;
		}  
	}
	
	h3 {
		color: $c-brand;
		font-weight: $font-weight-bold;
	}
	.u-grid-3 {
		padding-top: 2em;
		clear: both;
		
		@include breakpoint($bp-medium) {
			margin-top: 0;
			padding-top: 0;
			clear: none;
		}  
	}
}