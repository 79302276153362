.c-dropdown {
	min-width: 100%;

	@include breakpoint($bp-small) {
		min-width: 180px;
	}

	&.chosen-container-active.chosen-with-drop .chosen-single {
	    border: none;
	    background: #000;
	}

	.chosen-single {
		align-items: center;
		background: $c-white;
		border-radius: 30px !important;
		border: none !important;
		box-shadow: none;
		color: $c-font;
		display: flex;
		font-size: 18px;
		font-weight: 400;
		height: auto;
		letter-spacing: 0.025em;
		min-height: toRems(50px);
		padding: toRems(5px) toRems(30px);
		text-align: left;
		text-transform: none;
		transition: background 0.2s $global-easing, color 0.2s $global-easing;

		@include breakpoint($bp-medium) {
			min-height: toRems(60px);
			
			@media all and (-ms-high-contrast:none) {
				*::-ms-backdrop, & {
					height: toRems(60px);
				} /* IE11 */
			}
		}
		
		@media all and (-ms-high-contrast:none) {
			*::-ms-backdrop, & {
				height: toRems(50px);
			} /* IE11 */
		}

		// Arrow
		div {
			color: palette(grey, xx-light);
			font-size: toRems(24px);
			height: 14px;
			line-height: 1;
			margin-top: toRems(-12px);
			right: 20px;
			top: 50%;
			transition: transform 0.2s $global-easing;
			width: auto;
			z-index: 1;

			&:before {
				@extend %i-chevron-up-down-thin;
				line-height: 1;
			}

			b {
				display: none;
			}
		}
	}

	.chosen-drop {
		box-shadow: none;
		border: none;
		margin: 0;
		min-width: 100%;
		padding: 0;

		@include breakpoint($bp-small) {
			min-width: 180px;
		}
	}

	.chosen-drop {
		top: calc(100% + 10px);
	}

	.chosen-results {
		@include material-shadow(2);
		max-height: 390px;
		padding: 0;
		margin: 0;

		li {
			align-items: center;
			background: palette(grey, super-light);
			color: $c-font;
			display: flex !important;
			font-size: $font-size-10;
			line-height: 1.3;
			font-weight: 400;
			position: relative;
			justify-content: flex-start;
			letter-spacing: 0;
			min-height: 60px;
			padding: $spacing-small;
			text-transform: none;

			&:after {
				background-color: palette(grey, xxx-light);
				bottom: 0;
				content: "";
				height: 1px;
				left: $spacing-small;
				position: absolute;
				right: $spacing-small;
			}

			&:hover {
				background: palette(grey, mid-light);
				color: $c-white !important;

				&:after {
					background-color: palette(grey, mid-light);
				}
			}

			&.result-selected {
				background: palette(grey, mid-light);
				color: $c-white !important;

				&:after {
					background-color: palette(grey, mid-light);
				}
			}

			&.highlighted {
				background: palette(grey, mid-light);
				color: $c-white !important;

				&:after {
					background-color: palette(grey, mid-light);
				}
			}

			&.disabled-result,
			&.disabled-result:hover {
				background: $c-white;
				color: tint(palette(grey, mid-light), 50%);
			}
		}
	}

	&.chosen-container-active.chosen-with-drop .chosen-single {
		background: $c-white;
		color: $c-font;
	}

	&--small {
		max-width: 160px;
		min-width: 0;

		@include breakpoint($bp-medium) {
			max-width: 100%;
		}

		@include breakpoint($bp-large) {
			max-width: 160px;
		}
	}

	&--inverse-white {
		max-width: 300px;
		min-width: 0;

		&.chosen-container-active.chosen-with-drop .chosen-single {
			background: $c-white;
			color: palette(brand);
		}

		.chosen-single {
			background-color: transparent;
			border: 2px solid $c-white !important;
			color: $c-white;
			font-weight: 700;
			min-height: toRems(60px);

			@include breakpoint($bp-medium) {
				font-size: 20px;
			}

			&:hover {
				color: palette(brand);
				background: $c-white;

				div {
					color: $c-brand;
				}
			}

			div {
				color: $c-white;
				font-size: toRems(14px);
				margin-top: toRems(-7px);
				right: 30px;

				&:before {
					content: "\E002";
				}
			}

			span {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				@include breakpoint($bp-large) {
					width: 160px;
				}
			}
		}

		&.chosen-with-drop {
			.chosen-single div {
					transform: rotate(180deg);
			}
		}
	}
}
