.c-form-checkbox {

	&__input {
		left: -9999em;
		position: absolute;

		&:checked {
			& + .c-form-checkbox__label:before {
				background-color: $c-brand;
			}
		}
	}

	&__label {
		color: $c-font;
		cursor: pointer;
		font-weight: normal;
		letter-spacing: 0;
		letter-spacing: 0;
		line-height: 1.6em;
		padding-left: toRems(35px);
		position: relative;
		text-transform: none;
		font-size: $font-size-11;
		display: inline-block;

		&:before {
			background: $c-white;
			border-radius: 2px;
			border: 1px solid palette(grey, mid-dark);
			content: '.';
			cursor: pointer;
			height: toRems(20px);
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: toRems(20px);
		}

		&:after {
			@extend %i-tick;
			color: $c-white;
			display: block;
			font-size: 12px;
			left: 5px;
			margin-top: toRems(-6px);
			position: absolute;
			top: 50%;
		}
	}

	&--large-white {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: toRems(60px);

		.c-form-checkbox__input {
			&:checked + .c-form-checkbox__label {
				&:before {
					background-color: $c-white;
				}

				&:after {
					color: palette(brand);
					opacity: 1;
				}
			}
		}

		.c-form-checkbox__label {
			color: $c-white;
			font-weight: bold;
			padding-left: toRems(50px);
			font-size: 20px;

			&:before {
				background-color: transparent;
				border-radius: 0;
				border: 2px solid $c-white;
				height: toRems(30px);
				width: toRems(30px);
			}

			&:after {
				font-size: toRems(16px);
				left: 8px;
				margin-top: -8px;
				opacity: 0;
				transition: opacity 0.2s $global-easing;
			}
		}
	}

	&--align-top  {
		.c-form-checkbox__label {
			&:before,
			&:after {
				top: 16px;
			}
		}
	}
}
