.o-process-steps {
	@include span-columns(12);
	// background-image: url(/images/elements/blurred-hexagon-bg.jpg);
	// background-position: 50% 50%;
	// background-repeat: no-repeat;
	
	li {
		
		@include breakpoint(1100px) {
			width: 60%;
			min-width: 708px;
			margin: 0 auto -30px auto;
		}  
		
		&:nth-child(odd) {
			@include breakpoint(1100px) {
				transform: translateX(-24%);
			}  
		}
		
		&:nth-child(even) {
			@include breakpoint(1100px) {
				transform: translateX(24%);
			}  
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}