.o-flex-stories {
	display: flex;
	flex-wrap: wrap;
	
	> li {
		margin-bottom: $spacing-base;
		
		@include breakpoint($bp-small) {
			@include span-columns(4 of 8);
			display: flex;
		}
		
		@include breakpoint($bp-large) {
			@include span-columns(3 of 12);
			display: flex;
			background-color: $c-white;
			margin-bottom: 2.73973%;	
			margin-bottom: 25px;		
		}

		&:nth-child(2n) {
			@include breakpoint(max-width $bp-large) {
				@include omega;
			}
		}

		&:nth-child(3) {
			@include breakpoint($bp-large) {	
				@include omega();
			}
		}
		
		&:nth-child(7n) {
			@include breakpoint($bp-large) {
				@include omega();
			}
		}
		
		&:last-child {
			// margin-bottom: 0;
		}
		
		// Remove bottom margin from the last 3
		&:nth-last-child(-n+4) {
			@include breakpoint($bp-large) {
				//margin-bottom: 0;
			}  
		}
		
		a {
			@include breakpoint($bp-large) {
				display: flex;
				flex-direction: column;
				height: 100%;
				width: 100%;
			}
		}		
	}
	
	.o-flex-stories__item--featured {
		@include breakpoint($bp-large) {
			@include span-columns(6 of 12);
		}
	}
}