.o-spaced-inline-text-list {
	display: flex;
	
	li {
		padding-right: 1.5rem;
		margin-right: 1.5rem;
		position: relative;
		
		&:after {
			color: palette(grey, x-light);
			content: '|';
			left: calc(100% - 1px);
			top: 50%;
			margin-top: -.5em;
			position: absolute;
		}
		
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}