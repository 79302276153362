.c-hero-3-images {
	position: relative;
	background-position: 100% center; 
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	padding-top: 30px;

	@include breakpoint($bp-large) {
		padding-top: 120px;
		background-size: cover;
		align-items: center;
		justify-content: center;
		min-height: toRems(590px);
	}
	
	.o-grid {
		width: 100%;
		z-index: 1;
		
		@include breakpoint($bp-large) {
			
			@media all and (-ms-high-contrast:none) {
				*::-ms-backdrop, & {
					height: 590px;
				} /* IE11 needs height for flexbox items */
			}
		}  
	}
	
	&__image {
		@include span-columns(5);
		margin-top: $spacing-base;
		margin-left: $spacing-base;
		margin-bottom: -36px;
		position: relative;
		z-index: 10;
		
		@include breakpoint($bp-large) {
			@include span-columns(5);
			left: -60px;
			position: absolute;
			margin-bottom: 0;
			z-index: auto;
			margin: 0;
			top: -50px;
		}  
		
		img {
			min-width: 100%;
			
			@include breakpoint($bp-large) {
				margin: 0 auto;
				min-width: auto;
			}  
		}
	}
	
	// Image that sits on the right
	&__image-extra {
		display: none;

		@include breakpoint($bp-large) {
			@include span-columns(4);
			position: absolute;
			right: -100px;
			top: 50%;
			transform: translateY(-50%);
		}  
		
		
		img {
			@include breakpoint($bp-large) {
				margin: 0 auto;
				min-width: auto;
				display: block;
			}  
		}
	}
	
	&__content {
		@include span-columns(12);
		background-color: palette(grey, super-light);
		font-weight: 300;
		text-align: center;
		padding: $spacing-large $spacing-medium;
		position: relative;
		z-index: 1;
		
		@include breakpoint($bp-large) {
			@include span-columns(5);
			@include shift(4);
			padding: $spacing-medium $spacing-medium $spacing-large;
			background-color: transparent;
			padding-top: 0;
			text-align: left;
			
			h1 b,
			h1 strong {
				display: block;
			}
			
			&:after {
				display: none !important; // Hide the concave border
			}
		}
		
		*:last-child {
			margin-bottom: 0;
		}
		
		p {
			
			@include breakpoint($bp-large) {
				font-size: $font-size-10;
				margin-bottom: toRems(40px);
			}
		}
		
		h1,
		h2,
		p {
			@include breakpoint($bp-large) {
				text-shadow: 0 0 40px $c-white, 0 0 10px $c-white, 0 0 100px $c-white;
			}
		}
	}
}