.c-form-text,
.c-form-input,
%c-form-text {
	position: relative;

	&.parsley-error {
		input,
		textarea {
			border-color: palette(errors);
		}
	}

	input,
	textarea {
		-webkit-appearance: none;
		appearance: none;
		font-weight: 300;
		background: $c-white;
		border-radius: $global-radius;
		border: 1px solid palette(grey, xx-light);
		color: $c-font;
		font-size: toEms(14px);
		padding: toRems(17px) $spacing-base;
		width: 100%;

		@include breakpoint($bp-large) {
			padding: toRems(16px) 24px;
			font-size: 20px;
		}

		@include placeholder {
			color: rgba($c-font, 0.5);
		}

		&:focus {
			outline: none;
			border-color: palette(grey, x-light);
		}

		&:disabled {
			opacity: 1;
		}
	}

	textarea {
		border-radius: 0;
	}

	.parsley-errors-list li {
		@extend %c-form-error;
		// padding: $spacing-xsmall 0 0 $spacing-base;
	}
}
