.u-underline {
	position: relative;
	padding-bottom: $spacing-xxsmall;
	margin-bottom: $spacing-medium;

	&:after {
		content: "";
		background: url(../images/dots.svg) 0 0 repeat-x;
		background-size: 7px 7px;

		width: 100%;
		height: 6px;
		position: absolute;
		left: 0;
		bottom: -12px;
		opacity: .2;
	}
}