.u-bg-white {
	background-color: $c-white;
}

.u-bg-grey-super-light {
	background-color: palette(grey, super-light);
}

.u-bg-grey-xxx-light {
	background-color: palette(grey, xxx-light);
}

.u-bg-grey-xx-light {
	background-color: palette(grey, xx-light);
}

.u-bg-grey-x-light {
	background-color: palette(grey, x-light);
}

.u-bg-grey-light {
	background-color: palette(grey, light);
}

.u-bg-grey-mid-light {
	background-color: palette(grey, mid-light);
}

.u-bg-grey-base {
	background-color: palette(grey, base);
}

.u-bg-grey-mid {
	background-color: palette(grey, mid);
}

.u-bg-grey-mid-dark {
	background-color: palette(grey, mid-dark);
}

.u-bg-grey-dark {
	background-color: palette(grey, dark);
}

.u-bg-grey-x-dark {
	background-color: palette(grey, x-dark);
}

.u-bg-gradient-red {
	background-image: palette(gradient, red);
	color: $c-white;
}

.u-bg-gradient-green {
	background-image: palette(gradient, green);
	color: $c-white;
}

.u-bg-gradient-red-mid {
	background-image: palette(gradient, red-mid);
	color: $c-white;
}

.u-bg-brand {
	background-color: $c-brand;
	color: $c-white;
}

.u-bg-gradient-grey-dark {
	background-image: palette(gradient, grey-dark);
	color: $c-white;
}

.u-blurred-background-image {
	background-size: cover;
	background-repeat: no-repeat;
}

.u-bg-hexagons-blurred {
	background-image: url(/images/elements/blurred-hexagon-bg-black-and-white.jpg);
	background-repeat: no-repeat;
}

.u-bg-pos-left {
	background-position: 0 50%;
}

.u-bg-green-dark {
	background: #0D3D24;
	color: $c-white;
}