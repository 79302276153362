.c-sitemap {
	background-color: $c-slate-grey;
	border-top: 10px solid $c-brand;
	clear: both;
	color: palette(grey, x-light);
	padding: $spacing-medium 0;
	
	@include breakpoint($bp-large) {
		padding: 80px 0;
	}  
	
	h2 {
		font-size: 18px;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		margin-bottom: $spacing-medium;
		padding-top: $spacing-medium;
		border-top: 1px solid palette(grey, mid-light);
		
		&:first-child {
			border-top: none;
			padding-top: 0;
		}
		
		a {
			color: inherit;
		}
		
		@include breakpoint($bp-medium) {
			border-top: none;
		}  
	}
	
	&__col {
		border-bottom: 1px solid rgba(palette(grey), .4);
		padding-bottom: 15px;
		margin-bottom: $spacing-medium;
		
		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		
		@include breakpoint($bp-medium) {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}  
	}
}