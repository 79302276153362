%tr-color {
	transition:color .2s linear;
}

%tr-bg {
	transition:background .2s linear;
}


%tr-op {
	transition:opacity .2s linear;
}

%tr-border {
	transition:border .2s linear;
}

%tr-fill {
	transition:fill .2s linear;
}

%tr-all {
	transition:all .2s linear;
}

%x-btn {
	position: relative;
	text-indent: -9999px;
	display: block;
	width: 25px;
	height: 25px;

	&::after,
	&::before {
		transition:all .2s linear;
		@include position(absolute, 3px 3px 0 50%);
		@include size(0.15em 1.5em);
		background: palette(grey);
		content: "";
		display: block;
		margin: -3px 0 0 -1px;
		transform: rotate(45deg);
	}

	&:hover::after,
	&:hover::before {
		background: darken($c-brand, 10%);
	}

	&::before {
		transform: rotate(-45deg);
	}
 }

 %font-headline {
 	font-family: $font-headline;
 	font-weight: $font-weight-medium;
 	text-transform: uppercase;
 	letter-spacing: .10em;
 }

 %noscroll {
 	max-height: 100%;
  	overflow: hidden;

  	body {
  		max-height: 100%;
  		overflow: hidden;
  	}
 }

 %reset-list {
 	list-style: none;
 	padding: 0;
 	margin: 0;
 }

 %bullet-list {
 	list-style: disc;
	padding-left: 18px;
	margin-bottom: toRems(20px);

	li {
		margin-bottom: .25em;
	}
}

%toggle-icon {
	position: relative;

	&:after {
		@extend %font-icon;
		content: "\E001";
		font-size: $font-size-6;
		position: absolute;
		right: $spacing-small;
		top: 50%;
		line-height: 0;
	}

	&.is-active {
		&:after {
			transform: rotate(180deg);
		}
	}
}