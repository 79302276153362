.c-form-error,
%c-form-error {
	@include span-columns(12);
	color: palette(errors);
	display: block;
	font-weight: $font-weight-normal;
	margin: 0;
	text-align: left;

	li {
		padding: 0 0 $spacing-xsmall $spacing-base;
		
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.parsley-errors-list.filled {
	@extend %c-form-error;
	margin-bottom: $spacing-base;
	margin-top: $spacing-base;
	border-radius: 5px;
	background-repeat: no-repeat;
	background-size: 100%;
	border: 1px solid palette(errors);
	background-color: #fbe5ea;
	padding: 20px 25px 20px 60px;
	background-size: 32px 32px;
	background-position: 25px 50%;
	position: relative;
	background-image: url('data:image/svg+xml;utf-8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 69.8 69.8" style="enable-background:new 0 0 69.8 69.8;"  xml:space="preserve">
<path d="M34.9,0C15.6,0,0,15.6,0,34.9s15.6,34.9,34.9,34.9c19.3,0,34.9-15.6,34.9-34.9S54.2,0,34.9,0z M39.1,15.4L38,40.4h-6.2
	l-1.2-25.1H39.1z M34.9,53.1c-2.4,0-4.5-2.1-4.5-4.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2.1,4.5,4.5C39.4,51,37.3,53.1,34.9,53.1z" fill="#{palette(errors)}"/>
</svg>
');

// Behind arrow
	&:before {
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 21px 11px 21px;
		border-color: transparent transparent palette(errors) transparent;
		position: absolute;
		left: 49px;
		bottom: calc(100%);
		content: '';
		margin-top: 0;
	}

// In front arrow
	&:after {
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20px 10px 20px;
		border-color: transparent transparent #fbe5ea transparent;
		position: absolute;
		left: 50px;
		bottom: 100%;
		content: '';
		z-index: 1;
	}
}

.c-form-success {
	background: palette(success);
	color: palette(success, dark);
	border: 1px solid palette(success, dark);
	padding: 20px 25px 20px 25px;
	border-radius: 5px;
	margin-bottom: $spacing-base;
	position: relative;
}
