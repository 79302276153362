.c-sticky-message {
	background-color: palette(grey, dark);
	bottom: 0;
	color: $c-white;
	position: fixed;
	transition: all .5s $global-easing;
	width: 100%;
	z-index: 10;
	opacity: 0;
	
	&:not(.is-unfixed) {
		transform: translateY(100%);
	}
	
	&.is-showing {
		transform: translateY(0);
		opacity: 1;
	}
	
	&.is-unfixed {
		position: absolute;
		
		&.is-showing {
		}
	}

	&__toggle {
		padding: $spacing-small;
		color: $c-white;
		display: block;
		font-size: 18px;
		outline: none; 
		text-align: right;
		
		@include breakpoint($bp-large) {
    		padding: $spacing-base;
		}  
		
		&:hover {
			span {
				text-decoration: underline;
			}
		}
		
		span {
			display: inline-block;
			font-weight: bold;
		}
		
		svg {
			fill: $c-white;
			height: 20px;
			margin-left: $spacing-base;
			position: relative;
			top: 5px;
			transform: rotate(0);
			transition: all .3s $global-easing;
			width: 20px;
			
			.is-open & {
				transform: rotate(180deg);
			}
		}
		
	}
	
	&__content {
		padding: $spacing-medium;
		padding-top: 0;
		
		@include breakpoint($bp-large) {
    		padding: $spacing-large;
			padding-top: 0;
		}  
		
		.js & {
			display: none;
		}
		
		&-inner {
			transition: all .3s $global-easing;
			opacity: 0;

			.is-open & {
				opacity: 1;
			}
			
			*:last-child {
				margin-bottom: 0;
			} 
			
		}
	}
}