.c-filter-button {
	display: block;
	position: relative;
	padding: $spacing-base $spacing-medium $spacing-base;

	&__extra {
		position: absolute;
		background-color: palette(grey, super-light);
		display: block;
		top: 50%;
	}
}