.o-btn-group {
	@include breakpoint($bp-medium) {
		display: flex;
	}

	&__item {
		padding-bottom: 15px;
		text-align: center;

		@include breakpoint($bp-medium) {
			> * {
				margin: 0 5px;
			}
		}

		@include breakpoint($bp-large) {
			> * {
				margin: 0 15px;
			}
		}

		// &:first-child {
		//     padding-left: 0;
		// }
		//
		// &:last-child {
		//     padding-right: 0;
		// }
	}

	&--centered {
		justify-content: center;
	}
}
