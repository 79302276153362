$c-nav-margin-top: 24px;

.c-nav {
	display: none;
	float: right;

	@include breakpoint($bp-large) {
		margin-top: 20px;
		display: inline-block;
	}

	@include breakpoint($bp-xlarge) {
		margin-top: 35px;
	}

	// Top level
	> ul {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding: 0;

		// Top level list item
		> li {

			&:hover,
			&.is-active {
				
				> a {
					color: $c-brand;
				}
			}

			// Top level link
			> a {
				color: $c-slate-grey;
				display: block;
				font-weight: 600;
				padding: 15px $spacing-xxsmall;
				text-decoration: none;
				font-size: 14px;
				
				@include breakpoint($bp-xlarge) {
					padding: 20px $spacing-xsmall;
					font-size: 16px;
				}  

				&.is-highlight {
	
					@include breakpoint($bp-large) {
						span {
							background: palette(brand, mid);
							border-radius: 25px;
							color: white;
							padding: 8px 10px;
							transition: all .3s $global-easing;
						}

						&:hover {
							span {
								background-color: darken(palette(brand, mid), 10%);
							}
						}
					}

					@include breakpoint($bp-xlarge) {
						span {
							padding: 8px 20px;

						}
					}

				}
			}

			&.has-subnav {

				&:hover {

					.no-touchevents & {
						// Second level nav
						.c-nav-content {
							visibility: visible;
							opacity: 1;
							transform: translateY(0);
							pointer-events: all;
							
							.o-grid {
								transform: scale(1);
							}
						}
						
						a {
							&:before {
								transform: translateY(0);
								opacity: 1;
							}
							
							&:after {
								color: $c-brand;
							}
						}
					}
				}

				// Top level link (with subnav)
				> a {
					.no-touchevents & {
						display: block;
						text-decoration: none;
						position: relative;
						padding-right: 20px;

						@include breakpoint($bp-xlarge) {
							padding-right: 34px;
						}
						
						// Arrow pointing up from content
						&:before {
							position: absolute;
							left: 50%;
							bottom: 0;
							display: block;
							content: '';
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 20px 10px 20px;
							border-color: transparent transparent $c-brand transparent;
							z-index: 100;
							margin-left: -25px;
							margin-bottom: -8px;
							opacity: 0;
							// transform: translateY(3px);
							transition: all .3s $global-easing;


						}

						// Down chevon arrow
						&:after {
							@extend %i-chevron-down;
							font-size: 12px;
							position: absolute;
							float: right;
							margin-left: 3px;
							top: 50%;
							transform: translateY(-50%);
							color: palette(grey, mid-light);
							transition: color .3s $global-easing;
							
							@include breakpoint($bp-xlarge) {
	    						transform: translateY(-43%);
	    						margin-left: 10px;
							}  
						}
					}
					
				}
			}

		}
	}


	// Second level - @NOTE: NOT CURRENTLY USED
	&__subnav:not(.mm-listview) {
		display: block;
		visibility: visible;
		// flex-wrap: wrap;
		// justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		top: $header-height-large;
		background-color: $c-brand;
		opacity: 1;
		transition: all .3s ease-in-out;
		transform: translateY(-3px);

		@include breakpoint($bp-large) {
		    display: flex;
		}

		> li {

			> a {
				display: block;
				color: $c-white;
				font-weight: 700;
				font-size: 20px;
				padding: $spacing-small;
				text-decoration: none;
			}

			&.has-subnav {

				&:hover {

					.c-nav-content {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
				> a {

					&:after {
						position: relative;
						float: right;
						content: '';
						margin-left: 10px;
						margin-top: 12px;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 5px solid $c-white;
						border-bottom: 5px solid transparent;
					}
				}
			}
		}
	}
	
}