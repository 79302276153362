// Colour palletes
$palettes: (
    brand: (
        base: rgb(36, 87, 43),
        mid:  rgb(87, 172, 37),
    ),
    grey: (
        super-light: rgb(247, 246, 246),
        xxx-light: #E3E3E3,
        xx-light: #D1D3D4,
        x-light: #CCCCCC,
        light: #CDCDCD,
        mid-light: #AEAEAE,
        base: #8A8A8A,
        mid: rgb(135, 135, 135),
        mid-dark: #525254,
        dark: #454546,
        x-dark: #26282E,
    ),
    errors: (
        base: #d50032
    ),
    success: (
        base: #dff0d8,
        dark: #3c763d
    ),
    border: (
        base: #d6d8da
    ),
    gradient: (
        red: linear-gradient(180deg, #d50032 20%, #470011 100%),
        red-mid: linear-gradient(180deg, #d50032 20%, #b5002b 100%),
        grey-light: linear-gradient(to right, #ffffff, #d1d3d4),
        grey-dark: linear-gradient(to top, #383939, #58595b),
    )
);

// Global colors
$c-white: #ffffff;
$c-black: #000000;
$c-link: palette(brand, mid);
$c-link-hover: palette(grey, light);
$c-link-visited: #444;
$c-font: palette(grey, x-dark);
$c-font-light: #6D727B;
$c-font-x-light: #A1A6AA;
$c-placeholder: palette(grey, light);
$c-border: palette(grey, xxx-light);

// Maybe move these
$c-brand: palette(brand);
$c-green: #95c11f;
$c-slate-grey: palette(grey, mid);
$c-grey-super-light: palette(grey, super-light);
$c-help: #34b6ea;
$c-notice: #d0341e;

// Text highlight colors
$text-highlight-color: $c-white;
$text-highlight-bg-color: palette(grey);
$tap-highlight-color: rgba(255,255,255, 0);

// Social colors
$c-facebook: #3b5998;
$c-google-plus: #dd4b39;
$c-instagram: #125688;
$c-linked-in: #007bb5;
$c-pinterest: #cb2027;
$c-twitter: #00aced;
$c-youtube: #bb0000;

// Erp ratings
$c-heating: #ffed00;
$c-water: #15984d;
