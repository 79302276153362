@for $i from 0 through 12 {
  .u-grid-#{$i} {
    @include breakpoint($bp-medium) {
      @include span-columns($i);
    }
  }
  .u-prefix-#{$i} {
    @include shift($i);
  }
  .u-clear {
    clear: both;
  }
}

.u-grid-full {
    @include span-columns(12);
}

/**
* @bp-xsmall
*/
@include breakpoint($bp-xsmall) {
  @for $i from 0 through 12 {
    .u-grid-#{$i}\@xsmall {
      @include span-columns($i);
    }
    .u-prefix-#{$i}\@xsmall {
      @include shift($i);
    }
    .u-hidden\@xsmall {
      display: none;
    }
  }
}


/**
* @bp-small
*/
@include breakpoint($bp-small) {
  @for $i from 0 through 12 {
    .u-grid-#{$i}\@small {
      @include span-columns($i);
    }
    .u-prefix-#{$i}\@small {
      @include shift($i);
    }
    .u-clear\@small {
      clear: both;
    }
    .u-right\@small {
      float: right;
    }
    .u-left\@small {
      float: left;
    }
    .u-hidden\@small {
      display: none;
    }
  }
}


/**
* @bp-medium
*/

@include breakpoint($bp-medium) {
  @for $i from 0 through 12 {
    .u-grid-#{$i}\@medium {
      @include span-columns($i);
    }
    .u-prefix-#{$i}\@medium {
      @include shift($i);
    }
    .u-clear\@medium {
      clear: both;
    }
    .u-right\@medium {
      float: right;
    }
    .u-left\@medium {
      float: left;
    }
    .u-hidden\@medium {
      display: none;
    }
  }
}


/**
* @bp-large
*/
@include breakpoint($bp-large) {
  @for $i from 0 through 12 {
    .u-grid-#{$i}\@large {
      @include span-columns($i);
    }
    .u-prefix-#{$i}\@large {
      @include shift($i);
    }
    .u-clear\@large {
      clear: both;
    }
    .u-right\@large {
      float: right;
    }
    .u-left\@large {
      float: left;
    }
    .u-hidden\@large {
      display: none;
    }
  }
}

.u-grid-omega {
  @include omega;
}


/**
* Flex Grid
*/

.u-flex-grid-x2 {
  @include _fg_grid(2, 0, 0);
}

.u-flex-grid-x3 {
  @include _fg_grid(3, 0, 0);
}

.u-flex-grid-x3-padded {
  @include _fg_grid(3, 0, 50);
}

.u-flex-grid-x4 {
  @include _fg_grid(4, 0, 0);
}

.u-flex-grid-x5 {
  @include _fg_grid(5, 0, 0);
}

@include breakpoint($bp-small) {
  .u-flex-grid-x2\@small {
    @include _fg_grid(2, 0, 0);
  }
}

@include breakpoint($bp-medium) {
  .u-flex-grid-x3\@medium {
    @include _fg_grid(3, 0, 0);
  }
}

@include breakpoint($bp-large) {
  .u-flex-grid-x4\@large {
    @include _fg_grid(4, 0, 0);
  }
}