.o-product-slider {
	
	@include breakpoint($bp-medium) {
		display: flex;
		padding-right: $spacing-medium;
	}  
	
	&__nav {
		
		ul {
			display: none;
			
			@include breakpoint($bp-medium) {
				display: block
			}  
			
			li {
				display: inline-block;
			}
		}
		
		@include breakpoint($bp-medium) {
			flex: 80px 0 0;
			order: -1;
			margin-right: $spacing-medium;
		}  
	}
	
	&__slider {
		@include breakpoint($bp-medium) {
			width: calc(100% - 110px);
		}  
	}
}