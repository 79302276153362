.c-small-icons-list {
	li {
		position: relative;
		padding-left: 26px;
		margin-bottom: .5em;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	svg {
		width: 16px;
		height: 16px;
		position: absolute;
		left: 0;
		top: 3px;
		fill: palette(grey, x-light)
	}
}