.c-breadcrumbs {
	border-bottom: 1px solid $c-border;
	padding-top: 10px;
	padding-bottom: 10px;

	ul {
		@extend %reset-list;
		color: palette(grey, mid-light);
		font-size: 14px;

		li {
			display: inline-block;

			&:not(:last-child) {
				&:after {
					content: ' /'

				}
			}
		}

		a {
			color: $c-font;
		}
	}

	mark {
		background-color: transparent;
		color: palette(grey, mid-light);

		&:before {
			content: '"'
		}

		&:after {
			content: '"'
		}
	}
}