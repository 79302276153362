.u-ui-body {
	padding-top: 60px;
	transition: transform 0.5s $global-easing;

	@include breakpoint($bp-xlarge) {
		padding-left: 300px;
	}

	.ui-guide-menu-active & {
		transform: translateX(300px);

		@include breakpoint($bp-xlarge) {
			transform: none !important;
		}
	}
}

.u-ui-header {
	background: $c-white;
	color: $c-white;
	align-items: center;
	min-height: 60px;
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;

	&__banner {
		align-items: center;
		background-color: rgba($c-black, 0.8);
		display: flex;
		min-height: 60px;
		padding-left: 20px;
		transition: all 0.5s $global-easing;
		width: 100%;

		@include breakpoint($bp-xlarge) {
			// padding-left: 50px;
			width: 100%;
		}

		.ui-guide-menu-active & {
			transform: translateX(300px);

			@include breakpoint($bp-xlarge) {
				transform: none !important;
			}
		}

		h1 {
			border: none;
			color: $c-white;
			font-size: toRems(16px);
			font-weight: 400;
			letter-spacing: 0.02em;
			line-height: 1.3;
			margin-bottom: 0;
			margin: 0;

			@include breakpoint($bp-xlarge) {
				font-size: toRems(18px);
			}
		}
	}

	#{&}-logo {
		align-items: center;
		background-color: $c-white;
		border-bottom: 1px solid rgba($c-black, 0.15);
		display: flex;
		left: 0;
		left: 0;
		min-height: 60px;
		padding-left: 20px;
		position: fixed;
		top: 0;
		top: 0;
		transform: translateX(-100%);
		width: 300px;
		z-index: 6000;

		@include breakpoint($bp-xlarge) {
			position: relative;
			background: $c-white;
			transform: none;
			width: 300px;
		}

		.ui-guide-menu-active & {
			transform: none;
		}

		svg {
			fill: #202020;
			width: 70px;
		}
	}
}

.u-ui-menu-btn {
	display: block;
	height: 16px;
	position: relative;
	width: 25px;
	margin-right: 40px;

	@include breakpoint($bp-xlarge) {
		display: none;
	}

	&:focus {
		outline: none;
	}

	&__icon {
		background-color: $c-white;
		display: block;
		height: 2px;
		width: 100%;

		&:before,
		&:after {
			background-color: $c-white;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}
	}
}

.u-ui-sidebar {
	border-right: 1px solid rgba($c-black, 0.15);
	font-size: $font-xsmall;
	font-weight: 600;
	height: 100%;
	left: 0;
	padding-bottom: 100px;
	padding-top: 60px;
	position: fixed;
	top: 0;
	transform: translateX(-100%);
	transition: transform 0.5s $global-easing;
	width: 300px;
	z-index: 5000;

	@include breakpoint($bp-xlarge) {
		transform: none;
	}

	.ui-guide-menu-active & {
		transform: none;
	}

	li {
		padding: 0 !important;
		margin: 0 !important;

		&:before {
			display: none !important;
		}

		&.is-indented a {
			color: rgba($c-black, 0.7);
			padding-left: 40px;
			font-weight: 400;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}

	a {
		border: none;
		color: rgba($c-black, 0.8);
		display: block;
		padding: 5px 20px;
		position: relative;

		&:focus {
			outline: none;
		}

		&:after {
			background-color: rgba($c-black, 0.25);
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: width 0.3s $global-easing;
			width: 0;
		}

		&:hover {
			&:after {
				width: 5px;
			}
		}
	}

	&__inner {
		height: calc(100vh - 60px);
		padding: 10px 0 0;
		overflow: auto;

		ul {
			min-height: calc(100vh - 170px);
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	&__footer {
		background: $c-white;
		border-top: 1px solid rgba($c-black, 0.15);
		color: rgba($c-black, 0.5);
		cursor: pointer;
		font-size: toRems(10px);
		font-weight: 400;
		letter-spacing: 0.025em;
		line-height: 1.3;
		margin-top: 30px;
		padding: 20px;
		width: 100%;
		z-index: 9999;

		p {
			margin: 0;
		}
	}
}

%ui-heading {
	border-bottom: 1px solid tint($c-black, 75%);
	color: tint($c-black, 50%);
	font-weight: 400;
	line-height: 1;
	margin-bottom: 50px;
	margin-top: 0;

	code {
		@extend .u-ui-code-wrap;
	}
}

.u-ui-heading-large {
	@extend %ui-heading;
	border: none;
	color: $c-white;
	font-size: toRems(42px);
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 0;
}

.u-ui-heading-medium {
	@extend %ui-heading;
	font-size: toRems(30px);
	padding-bottom: 10px;
}

.u-ui-heading-small {
	@extend %ui-heading;
	font-size: toRems(21px);
	line-height: 1;
	padding-bottom: 10px;
	margin: 30px 0;
}

// Color swatches
.u-ui-color {
	// @include omega(5n);
	@include span-columns(4.8 of 24);
	border-radius: 3px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	font-size: toRems(14px);
	line-height: 1.3;
	margin-bottom: 20px;
	overflow: hidden;
	padding-top: 150px;

	> div {
		background: $c-white;
		padding: 10px;
	}

	h4 {
		font-size: toRems(14px);
		font-weight: 400;
		margin: 0;
	}
}

// Sections
.u-ui-section {
	padding: 50px 0;
}

.u-ui-section-medium {
	background: #eee;

	[class^="ui-heading"] {
		color: tint($c-black, 35%);
		border-color: tint($c-black, 35%);
	}
}

.u-ui-section-dark {
	background: #666;

	[class^="ui-heading"] {
		color: $c-white;
		border-color: $c-white;
	}
}

.u-ui-bg-wrap {
	display: inline-block;
	padding: 15px;
}

.u-ui-code-wrap {
	background-color: #666;
	color: $c-white;
	display: inline-block;
	font-family: monospace;
	font-size: toRems(14px);
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	margin-left: 20px;
	padding: 10px 15px;
	text-transform: none;
	vertical-align: middle;
}

.u-ui-note {
	background-color: #754ACC;
	color: $c-white;
	display: block;
	margin: 30px 0;
	padding: 25px;

	&:before {
		content: "DEVELOPER NOTE*";
		display: block;
		font-size: toRems(14px);
		font-weight: $font-weight-bold;
		margin-bottom: 10px;
		text-decoration: underline;
	}

	a {
		color: $c-white;
		text-decoration: underline;
	}

	.u-ui-code-wrap {
		background-color: $c-white;
		color: #754ACC;
		margin-left: 10px;
		margin-right: 10px;
	}
}
