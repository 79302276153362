// Mobile menu toggle
.c-nav-toggle {
	height: $header-height;
	width: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: block;
	outline: none;
	position: relative;
	cursor: pointer;
	border: none;
	
	@include breakpoint($bp-large) {
		display: none;
	}  

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $c-font;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .26s ease-in-out;
		width: 26px;
		
		&:nth-child(1) {
			top: 24px;
		}
		
		&:nth-child(2), &:nth-child(3) {
			top: 32px;
		}
		
		&:nth-child(4) {
			top: 40px;
		}
	}
	
	&.is-active {
		span {
			&:nth-child(1) {
				top: 32px; // To the middle
				width: 0%;
				left: 12px;
			}
			
			&:nth-child(2) {
				transform: rotate(45deg);
			}
			
			&:nth-child(3) {
				transform: rotate(-45deg);
			}
			
			&:nth-child(4) {
				top: 32px; // To the middle
				width: 0%;
				left: 12px;
			}
		}
	}
	
}