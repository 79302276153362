.c-process-step {
	$process-step-icon-width: 130px;
	$process-step-icon-height: 147px;
	$process-step-icon-width-large: 230px;
	$process-step-icon-height-large: 260px;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	
	@include breakpoint($bp-medium) {
		flex-direction: row;
		flex: 0 330px;
		width: 100%;
	}
	
	header {
		flex-shrink: 0;
		width: $process-step-icon-width;
		
		@include breakpoint($bp-medium) {
			margin-left: 120px;
			width: $process-step-icon-width-large;
			order: 1;
		}
		
	}
	
	footer {
		padding-top: 120px;
		
		@include breakpoint($bp-medium) {
			padding-top: 0;
			text-align: right;
		}
		
		h2 {
			font-size: $font-size-10;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
		}
		
		a {
			font-weight: %font-weight-bold;
		}
	}
	
	&__icon {
		display: flex;
		align-items: center;
		position: relative;
		width: $process-step-icon-width;
		height: $process-step-icon-height;
		
		@include breakpoint($bp-medium) {
			width: $process-step-icon-width-large;
			height: $process-step-icon-height-large;
		}
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			background-color: $c-brand;
			
			@include breakpoint($bp-medium-max) {
				top: 100%;
				width: 3px;
				height: 100px;
				margin-left: -1px;
				left: 50%;
				top: 100%;
				margin-bottom: -80px;
			}
			
			@include breakpoint($bp-medium) {
				top: auto;
				right: 100%;
				height: 3px;
				margin-top: -1px;
				width: 100px;
			}
		}
		
		&:after {
			border-radius: 50%;
			content: "";
			position: absolute;
			display: block;
			height: 20px;
			border: 3px solid $c-brand;
			background-color: $c-white;
			width: 20px;
			
			@include breakpoint($bp-medium-max) {
				left: 50%;
				top: 100%;
				margin-top: 80px;
				margin-left: -10px;
			}
			
			@include breakpoint($bp-medium) {
				right: 100%;
				top: 50%;
				margin-top: -10px;
				margin-right: 80px;
			}
		}
		
		svg {
			position: absolute;
			fill: $c-brand !important;
			left: 50%;
			width: $process-step-icon-width;
			height: $process-step-icon-height;
			top: 0;
			
			@include breakpoint($bp-medium-max) {
				margin-left: -($process-step-icon-width/2);
			}
			
			@include breakpoint($bp-medium) {
				margin-left: -($process-step-icon-width-large/2);
				width: $process-step-icon-width-large;
				height: $process-step-icon-height-large;
			}
			
		}
	}
	
	&__icon-title {
		color: $c-white;
		font-size: 30px;
		position: relative;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		text-align: center;
		z-index: 2;
		display: block;
		width: 100%;
	}
	
	&--flipped {
		
		header {
			order: 0 !important;
			
			@include breakpoint($bp-medium) {
				margin-left: 0;
				margin-right: 120px;
			}
		}
		
		
		footer {
			@include breakpoint($bp-medium) {
				text-align: left;
			}  
		}
		
		.c-process-step__icon {
			&:before {
				
				@include breakpoint($bp-medium) {
					right: auto;
					left: 100%;
				}
			}
			
			&:after {
				@include breakpoint($bp-medium) {
					right: 0;
					left: 100%;
					margin-right: auto;
					margin-left: 80px;
				}
			}
		}
	}
}