// Slideout plugin for mobile
#mm-js-nav,
#mm-js-sites-nav {
	background-color: #f1f1f1;
	box-shadow: 0 10px 10px rgba(0,0,0,.3);
	max-width: none;
	top: $header-height;
	width: calc(100%);
	
	// @include breakpoint($bp-medium) {
	// 	top: $header-height-large; 
	// }  
	
	@include breakpoint($bp-large) {
		display: none;
	}  	
	
	.navigation-list {
		
	}
	
	.mm-navbars-top {
		height: $header-height;
	}
	
	.mm-panels {
		// top: $header-height;
	}
	
	.mm-panel {
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		transition: transform .4s ease-in-out;
		padding-left: 0;
		padding-right: 0;
		
		&.mm-hasnavbar {
			padding-top: 52px;
		}
		
		&:not(:first-child) {
			.mm-navbar {
				padding-left: 40px; // Clear the back button
				
				.mm-title {
					
				}
			}
		}
		
		// Title bar
		
		.mm-navbar {
			// height: $header-height;
			padding: 0 0 0 $spacing-base;
			background-color: $c-brand;
			height: auto;
			
			@include breakpoint($bp-medium) {
				padding: 0 0 0 25px;
			}  
			
			.mm-title {
				color: $c-white;
				font-weight: bold;
				padding: 15px 0 15px;
				// height: $header-height;
				text-align: left;
				position: relative;
				top: 1px;
				// padding-top: 15px;
				
				@include breakpoint($bp-medium) {
    				padding-left: $spacing-xsmall;
				}  
			}
			
			a {
				&:before {
					border-color: $c-white;
					border-width: 1px;
					height: 10px;
					width: 10px;
				}
				
				&.mm-btn {
					height: 50px;
				}
			}
		}
		
		// List items
		.mm-listview {
			padding: 0 !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
			
			li {
				border-color: $c-border;
				margin: 0;
				padding: 0;
				
				&:after {
					left: 0;
				}

				a, span {
					&:not(.mm-next) {
						padding: 15px 20px;
						color: palette(grey, mid);
						font-size: $base-font-size;
						// font-size: $font-small;
						// color: $c-dark-blue;
						font-weight: 700;
						
						@include breakpoint($bp-medium) {
							padding: 15px 34px;
						}  
					}
					
					&.mm-next {
						border-color: transparent;
						right: 0;
						
						&:after {
							border-width: 1px;
							height: 10px;
							width: 10px;
							border-color: palette(grey, mid);
						}
					}
				}

				a.is-highlight {
					span {
						padding: 0;
					}
				}
				
				&.c-nav__mobile-sublink {
					background-color: #ececec;

					a {
						font-weight: normal;
						font-size: 15px;
						padding: 10px 20px;
						
						@include breakpoint($bp-medium) {
    						padding-left: 34px;
						}  
					}
				}

				&.is-active {
					a {
						color: $c-brand;
					}
				}
			}
		}
	}
}

.mm-slideout {
	@include breakpoint($bp-large) {
		z-index: auto !important;
	}  
}

#mm-blocker {
	// display: none;
	top: $header-height !important;
	// background: $overlay-bg;
	
	@include breakpoint($bp-medium) {
		top: $header-height-large !important; 
	}  
	
	@include breakpoint($bp-large) {
		display: none !important;
	}  
}

html.mm-opened {
	
	@include breakpoint($bp-large-max) {
		overflow-x: hidden;
		position: relative;
		
	} 
	
	@include breakpoint($bp-large) {
		overflow-x: visible !important;
	}   
}

html.mm-blocking {
	
	@include breakpoint($bp-large-max) {
		overflow: hidden;
	}  
	
	@include breakpoint($bp-large) {
		overflow: visible !important;
	} 
	
	body {
		
		@include breakpoint($bp-large-max) {
			overflow-x: hidden;
		}  
		
		@include breakpoint($bp-large) {
			overflow: visible !important;
			overflow-x: visible !important;
		} 
	}
}