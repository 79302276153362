.o-fader-with-sidebar {
	@include breakpoint($bp-large) {
		background-color: $c-white;
		text-align: left;
		height: 100%;
		position: relative;
		display: table;
		width: 100%;
		table-layout: fixed;
	}
	
	&__content {
		
		@include breakpoint($bp-large) {
			background-color: palette(grey, xx-light);
			display: table-cell;
			vertical-align: middle;
			position: relative;
			
			> div {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
		
		.slick-arrow {
			z-index: 1;
			
			&:before {
				font-size: 30px;
				
				@include breakpoint($bp-large) {
					display: none;
				} 
			}
			
			@include breakpoint($bp-large) {
				display: none;
			}  
		}
		
		.slick-prev {
			left: 10px;
		}
		
		.slick-next {
			right: 20px;
		}
	}
	
	&__sidebar {
		display: none;
		
		@include breakpoint($bp-large) {
			display: table-cell;
			width: 280px;
			position: relative;
			z-index: 2;
		}  
	}
}