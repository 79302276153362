.u-svg-icon {
	margin: toEms(20px) 0;
	display: block;
	
	&--centered {
		display: table;
		width: 100%;
	}
	&--yellow {
		fill: $c-brand;
	}
	&--red {
		fill: $c-brand;
	}
	&--white {
		fill: white;
	}
	&--circle {
		border: 1px solid $c-white;
		border-radius: 48px;
	}

	&--people {
		width: 100%;
		padding: 0;
	}

	.o-box &,
	.c-slideshow & {
		margin-top: 0;
	}

	.c-stats & {
		margin-bottom: 5px;
	}
	
	.c-highlights & {
		margin-bottom: 0px;
	}

}