.c-sidebar-nav-item {
	border-bottom: 1px solid $c-border;
	position: relative;
	display: block;
	text-align: left;
	outline: none;
	width: 100%;
	z-index: 10;

	&:before {
		position: absolute;
		left: calc(100% - 10px);
		top: 50%;
		margin-top: -15px;
		display: block;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 0 15px 10px;
		border-color: transparent transparent transparent $c-white;
		transition: all .3s $global-easing;
		z-index: -1;
	}

	&--left-arrow {
		&:before {
			right: calc(100% - 10px);
			left: auto;
			border-width: 15px 10px 15px 0;
			border-color: transparent $c-brand transparent transparent;
		}
	}
	
	&--featured {
		p {
			font-size: $font-size-10;
		}
	}
	
	img {
		margin: 0 auto $spacing-medium;
	}

	&__content {
		position: relative;
		z-index: 0;
		display: block;
		padding: $spacing-base 60px $spacing-base $spacing-base;
		background-color: $c-white;
		color: $c-font;
		transition: all .3s $global-easing;
		
		.c-sidebar-nav-item__text {
			font-size: $font-size-10;
		}
	}

	&__extra {
		position: absolute;
		background-color: palette(grey, super-light);
		display: block;
		top: 50%;
		right: $spacing-base;
		transform: translateY(-50%);
		color: $c-font;
		min-width: 32px;
		padding: 5px;
		text-align: center;
		border-radius: 5px;
		font-size: 20px;
		line-height: 1;
	}

	&.is-active {

		.c-sidebar-nav-item__content {
			background-color: $c-brand;
			color: $c-white;
		}

		.c-meta__category {
			color: $c-white;
		}

		&:before {
			left: 100%;
			border-color: transparent transparent transparent $c-brand;
		}

		&.c-sidebar-nav-item--left-arrow:before {
			right: 100%;
			left: auto;
			border-color: transparent $c-brand transparent transparent;
		}

		&:hover {
			.c-sidebar-nav-item__content {
				background-color: $c-brand;
			}
		}
	}

	&:hover {
		text-decoration: none;
		
		.c-sidebar-nav-item__content {
			background-color: #f3f3f3;
		}
	}

	&.c-sidebar-nav__item--featured {
		p {
			font-size: 20px;
		}
	}

	p {
		font-weight: 700;
		margin-bottom: 0;
	}
}
