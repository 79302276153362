.o-section {
	background-position: 50% 50%;
	background-size: cover;
	display: block;
	padding: $spacing-large 0;
	position: relative;
	clear: both; // Added this to clear the .c-divider, might cause issues elswhere. Remove if it is.

	@include breakpoint($bp-large) {
		padding: $spacing-xlarge 0;
	}

	&__content {
		z-index: 10;
	}

	&__curved-border {
		display: block;
		height: 200px;
		left: 0;
		overflow: hidden;
		position: absolute;
		bottom: -30px;
		width: 100%;
		z-index: 5;

		&:after {
			background-color: $c-white;
			border-radius: 100%;
			content: "";
			display: block;
			height: 100%;
			left: 50%;
			position: absolute;
			top: 0;
			transform: translate(-50%, 0);
			width: 200%;

			@include breakpoint($bp-medium) {
				width: 140%;
			}
		}
	}

	&--no-pad {
		padding: 0;
	}

	&--no-pad-top {
		padding-top: 0;
	}

	&--no-pad-bottom {
		padding-bottom: 0;
	}

	&--small {
		padding: $spacing-medium 0;
	}

	&--medium {
		padding: $spacing-large 0;
	}

	&--grey {
		background: palette(grey);
	}

	&--grey-xx-light {
		background: palette(grey, xx-light);
	}

	&--blue {
		background: $c-brand;
	}

	&--border {
		border-bottom: 1px solid $c-border;
	}

	&--border-dark {
		border-bottom: 1px solid #e3e3e3;
	}

	&--divider-top {
		&:before {
			background: palette(grey, x-light);
			content: "";
			display: block;
			height: 1px;
			left: 50%;
			max-width: 500px;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 70%;
		}
	}

	&--erp {
		padding: toRems(80px) 0;

		@include breakpoint($bp-medium) {
			padding: toRems(120px) 0 toRems(160px);
		}

		> div {
			position: relative;
			z-index: 1;
		}

		&:after {
			background-image: linear-gradient(
				180deg,
				#d50032 20%,
				#470011 300%
			);
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

			@include breakpoint($bp-medium) {
				background-image: linear-gradient(
					180deg,
					#d50032 20%,
					#470011 100%
				);
				height: 100%;
			}
		}
	}

	&--has-tabs {
		@include breakpoint($bp-medium) {
			padding-top: $spacing-xlarge;
		}
	}

	&--extra-top-padding\@large {
		@include breakpoint($bp-large) {
			padding-top: 170px;
		}
	}

	&--extra-bottom-padding\@large {
		@include breakpoint($bp-large) {
			padding-bottom: 150px;
		}
	}
}
