.c-product-selector-results {
	background-color: $c-white;

	.o-section {
		transition: all .5s $global-easing .5s;
		opacity: 0;
	}

	&.is-open {
		.o-section {
			opacity: 1;
		}
	}
}
