.o-media {
	text-align: center;
	
	img {
		margin: 0 auto;
	}
	
	@include breakpoint($bp-large) {
		text-align: left;
		display: flex;
		align-items: stretch;
		height: 100%;
	}  	
	
	&__figure {
		margin-bottom: $spacing-medium;
		
		@include breakpoint($bp-large) {
			margin-right: toRems(40px);
			margin-bottom: 0;
		}  	
		
		img {
			max-width: 100%;
		}
	}
	
	&__content {
		@include breakpoint($bp-large) {
			flex: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			
			p,
			a {
				align-self: flex-start
			}

			@media all and (-ms-high-contrast:none) {
				*::-ms-backdrop, > * {
					max-width: 100%;
				} /* IE11 */
			}
			
			ul, ol, {
				li:before {
					@include breakpoint($bp-large-max) {
						position: relative;
						top: -.3em;
						margin-right: .25em;
					}  
				}
			}
			
		}  	
	}
	
	&--reversed {
		
		.o-media__figure {
			order: 1;
			@include breakpoint($bp-large) {
				margin-left: toRems(40px);
				margin-right: 0;
			}  
		}
	}
	
	&--content-1\/3 {
		.o-media__content {
			
			@include breakpoint($bp-large) {
				flex: none;
				width: 360px;
				margin-right: 2rem;
			}  	
		}
	}
	
	&--inset {
		@include breakpoint($bp-xlarge) {
			@include shift(1);
		}  
	}
	
	&--chunky {
		> *:nth-child(odd) {
			@include breakpoint($bp-large) {
				padding-right: $spacing-medium;
			}  
		}
		
		> *:nth-child(even) {
			@include breakpoint($bp-large) {
				padding-left: $spacing-medium;
			}  
		}
		
		&.o-media--reversed {
			> *:nth-child(odd) {
				@include breakpoint($bp-large) {
					padding-right: 0;
					padding-left: $spacing-medium;
				}  
			}
			
			> *:nth-child(even) {
				@include breakpoint($bp-large) {
					padding-left: 0;
					padding-right: $spacing-medium;
				}  
			}
		}
	}
	
	&--vertically-align {
		@include breakpoint($bp-large) {
			align-items: center;
		}  
	}
	
	&--break-later {
		@include breakpoint($bp-large) {
			text-align: center;
			display: block;
		}  	
		
		@include breakpoint($bp-xlarge) {
			text-align: left;
			display: flex;
			align-items: stretch;
			height: 100%;
		}  	
		
		.o-media__content {
			@include breakpoint($bp-large) {
				text-align: center;
				
				p, a {
					margin-left: auto;
					margin-right: auto;
				}
			} 
			
			@include breakpoint($bp-xlarge) {
				text-align: left;
				
				p, a {
					margin-left: 0;
				}
			} 
		}
	}

	&--layered {
		margin-bottom: $spacing-xlarge;

		&:last-of-type {
			margin-bottom: 0;
		}

		.o-media__content {
			
			@include breakpoint($bp-large) {
				flex: 1;
				width: auto;
			}  	
		}

		.o-media__figure {
			
			@include breakpoint($bp-large) {
				flex: 1;
				width: auto;
			}  	
		}
	}


}