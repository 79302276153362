@mixin heading-1 {
    font-size: $font-size-6; // 40px
    line-height: 1;
    text-transform: uppercase;
    // color: $c-brand;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-1;
        letter-spacing: -1.4px;
    }
}

@mixin heading-2 {
    font-size: $font-size-6; // 40px
    line-height: 1;
    text-transform: uppercase;
    // color: $c-brand;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-2; // 64px
    }
}

@mixin heading-3 {
    font-size: $font-size-6; // 40px
    font-weight: 400;
    letter-spacing: -1.2px;
    line-height: 1;
    // color: $c-brand;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-3;
    }
}

@mixin heading-4 {
    font-size: $font-size-7; // 40px
    font-weight: 300;
    letter-spacing: -1px;
    line-height: .9;
    text-transform: uppercase;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-4;
    }
}

@mixin heading-5 {
    font-size: $font-size-7; // 34px
    font-weight: 500;
    letter-spacing: -0.9px;
    line-height: .9;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-5; // 45px
    }
}

@mixin heading-6 {
    font-size: $font-size-8; // 30px
    font-weight: 300;
    letter-spacing: -0.8px;
    line-height: .9;
    text-transform: uppercase;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-6; // 40px
    }
}

@mixin heading-7 {
    font-size: $font-size-9; // 24px
    // font-weight: 300;
    font-weight: normal;
    letter-spacing: -0.7px;
    line-height: 1.1;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-7; // 34px
        line-height: .9;
    }
}

@mixin heading-8 {
    font-size: $font-size-9; // 24px
    font-weight: $font-weight-normal;
    letter-spacing: -0.6px;
    line-height: 1.1;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-8; // 30px
    }
}

@mixin heading-9 {
    font-size: $font-size-10;
    letter-spacing: -0.5px;
    line-height: 1.1;
    font-weight: $font-weight-normal;
    
    @include breakpoint($bp-medium) {
        line-height: 1;
        font-size: $font-size-9; // 24px
    } 
}

@mixin heading-10 {
    font-weight: $font-weight-semibold;
    line-height: 1.17;
    letter-spacing: -.4px;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-10;
    }  
}

@mixin body-large {
    @include breakpoint($bp-medium) {
        font-size: $font-size-10; // 20px
        font-weight: normal;
    }
}

@mixin body-large-light {
    font-weight: $font-weight-light;
    
    @include breakpoint($bp-medium) {
        font-size: $font-size-10; // 20px
    }
}