.c-results {
	width: 100%;
	table-layout: fixed;
	font-size: $font-size-11;
	
	th {
		background-color: palette(grey, dark);
		color: $c-white;
		padding: 24px $spacing-base;
		border: none;
		display: none;
		
		@include breakpoint($bp-medium) {
			display: table-cell;
		}  
		
		&:first-child {
			
			@include breakpoint($bp-medium) {
				width: 50%;
			}  
		}
	}
	
	tbody {
		
		svg {
			width: 18px;
			height: 10px;
			transition: all .3s $global-easing;
			transform: rotate(0);
			fill: $c-brand;
		}
		
		tr {
			border-bottom: 1px solid palette(grey, xxx-light);
			display: flex;
			flex-direction: column;
			padding: $spacing-small 0;
			
			@include breakpoint($bp-medium) {
				display: table-row;
			}  
		}
		
		td {
			border-top: none;
			font-weight: $font-weight-bold;
			padding: 0 0 $spacing-small;

			@include breakpoint($bp-medium) {
				font-weight: normal;
				vertical-align: middle;
				padding: 26px $spacing-base;	
			}  
			
			// Accredited Logo
			img {
				float: right;
				margin-top: -1em;
	
				@include breakpoint($bp-medium) {
					margin-top: -12px;
					margin-bottom: -12px;
				}  
			}
			
			&:before {
				content: attr(data-label);
				font-weight: $font-weight-normal;
				display: block;
				
				@include breakpoint($bp-medium) {
					display: none;
				} 
			}
			
			&:first-child {
				
				@include breakpoint($bp-medium) {
					font-weight: $font-weight-bold;
					display: flex;	
					align-items: center;
				} 
				
				img {
					margin-right: $spacing-small;
				}
			}
			
			&:last-child {
				
				font-weight: $font-weight-bold;
				padding-bottom: 0;
				
			
				
				@include breakpoint($bp-medium) {
					font-weight: $font-weight-normal;
					text-align: right;
				}  
			}
		}
	}
}