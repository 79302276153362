.c-dl-simple {
	margin: 0;
	
	dt {
		font-weight: 700;
	}
	dd {
		margin: 0 0 .8em 0;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}