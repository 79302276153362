.u-forgot-password {
	@extend %font-headline;
	display: block;
	text-align: center;
	margin-top: $spacing-xsmall;


	@include breakpoint($bp-small) {
		float: right;
		padding-left: $spacing-base;
	}

}