.c-brochure-cta {
	text-decoration: none !important;

	@include breakpoint($bp-small) {
		display: flex;
		align-items: flex-end;
	}

	&:hover {
		figcaption {
			box-shadow: 0px 0px 0px 8px palette(grey, xx-light);
		}
	}

	figure {
		background-repeat: no-repeat;
		background-position: 50% 100%;
		padding: 0 10px;
		position: relative;
		z-index: 10;
		text-align: center;

		@include breakpoint($bp-small) {
			background-image: url(/images/elements/brochure-shadow.png);
			background-size: 100% auto;
			// flex: 188px 0 0;
		}

		img {
			margin: 0 auto;
			// width: 168px;
		}
	}

	figcaption {
		background-image: linear-gradient(to right, $c-brand, palette(brand, mid));
		color: $c-white;
		border-radius: 6px;
		font-size: 20px;
		margin-bottom: $spacing-medium;
		padding: $spacing-base $spacing-medium;
		line-height: 1.1;
		box-shadow: 0px 0px 0px 0px palette(grey, xx-light);
		transition: all .3s $global-easing;

		@include breakpoint($bp-small) {
			max-width: 400px;
			padding: $spacing-base 40px $spacing-base $spacing-base;
			border-radius: 0 50px 50px 0;
			margin-left: -10px;

		}
	}
}