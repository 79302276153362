.c-commissioning-form-holder {	
	@include breakpoint($bp-medium) {
		width: 80%;
		// margin-left: 20%;
	}
}

.c-commissioning-form {
	display: none;
	font-size: $font-size-10; 

	fieldset {
		margin: $spacing-medium 0 $spacing-large 0;
		border-bottom: 2px solid palette(grey, xx-light);
		position: relative;
	}

	legend {
		font-size: $font-size-6;
		color: $c-brand;
		margin-bottom: $spacing-small; 
	}

	label {
		font-weight: 600;
		margin-bottom: $spacing-xsmall;
		display: block;
		font-size: $font-size-10; 
	}

	.c-form-checkbox__label,
	.c-form-radio__label {
		font-weight: 400;
	}

	.c-tooltip {
		float: right;
		position: relative;
		top: -30px;
		right: 5px;
		margin-bottom: -30px;

		&__message {
		
			@include breakpoint($bp-medium) {
				width: 500px;
			}

		}
	}

	&__intro {
		margin-bottom: $spacing-large;
		font-size: $font-size-10;

		h1 {
			@include heading-4;
			margin: 0;
			margin-bottom: .6em;

			strong, b {
				display: block;
			}

			@include breakpoint($bp-large) {
				width: 80%;
			}
		}
	}

	&__btn-group {
		.c-btn {
			margin-bottom: $spacing-small;
			width: 100%;

			@include breakpoint($bp-small) {
				width: auto;
				margin-right: $spacing-small;
			}
		}
	}

	&__table {
		background: white;

		tr {
			td:last-child {
				text-align: right;
			}
		}

		tbody {
			tr:last-child {
				td {
					font-weight: 700;
				}
			}
		}

		thead {
			background: palette(grey, xxx-light);
			font-weight: 700;

			td {
				font-weight: 700;
			}
		}

		tfoot {
			background: palette(grey, mid);

			td {
				font-weight: 700;
				color: white;
			}
		}

		#commissioning-holder {
			tr {
				td {
					font-weight: normal;
				}
			}
		}
	}

	&__highlight {
		font-weight: 700;
	}

	&__max-boilers {
		background: white;
		border: 5px $c-brand;
		padding: $spacing-base;
		margin-bottom: $spacing-large;

		p {
			margin-bottom: 0;
			font-weight: 700;
		}
	}

}

.c-commissioning-form-message {
	display: none;
	font-size: $font-size-10;
	background: white;
	border: 5px solid $c-brand;
	padding: $spacing-base;
	margin-bottom: $spacing-large;

	p {
		font-weight: 700;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

}

.c-commissioning-form-boiler {

	&__remove {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
	}

	&:last-of-type {
		.c-commissioning-form-boiler__remove {
			display: block;
		}
	}

	&:nth-of-type(1) {
		.c-commissioning-form-boiler__remove  {
			display: none;
		}
	}
}