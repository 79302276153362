.c-link {
    align-items: center;
    display: inline-flex;
    line-height: 1;
    align-items: center;
    
    &__icon {
        fill: $c-brand;
        height: toRems(12px);
        width: toRems(12px);
        margin-left: toRems(10px);
        
        @include breakpoint($bp-medium) {
            height: toRems(14px);
            width: toRems(14px);
        }
    }
    
    &--icon-left {
        .c-link__icon {
            margin-right: toRems(10px);
            margin-left: 0;
        }
    }
    
    &--large-icon {
        .c-link__icon {
            height: toRems(22px);
            width: toRems(22px);
        }
    }
    
    &--large\@medium {
        @include breakpoint($bp-medium) {
            font-size: $font-size-10;
        }  
    }
}