form {

	fieldset {
		border: none;
		padding: 0 0 $spacing-medium 0;
	}

	legend {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 30px;
		white-space: normal;
		width: 100%;
	}

	ol {
		@include clearfix;

		> li {
			@include clearfix;
			margin-bottom: $spacing-base;
		}
	}
}

button {
	background: none;
	border: none;
	padding: 0;
}
