.c-title-with-icon {
	display: flex;
	align-items: center;
	font-weight: $font-weight-semibold;
	
	svg {
		width: 30px;
		height: 30px;
		fill: #bcbdbd;
		margin-right: 10px;
		// margin-top: .2rem;
		position: relative;
		flex: 0 0 30px;
	}
	
	&--alt {
		svg {
			width: 45px;
			height: 45px;
			fill: $c-brand;
			flex: 0 0 45px;
		}
	}
	
	&--small {
		svg {
			width: 16px;
			height: 16px;
			flex: 0 0 16px;
		}
	}
}