.c-options {
	padding-bottom: $spacing-large;
	margin-bottom: $spacing-large;
	text-align: center;

	.is-loading & {
		display: none;
	}

	&--no-spacing-bottom {
		margin-bottom: 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	&__item {
		margin: $spacing-xsmall;
	}

	&__radio {
		position: absolute;
		left: -9999px;
	}

	&__radio:checked + &__item-inner {
		box-shadow: 0px 0px 0px 8px $c-brand;

		.c-options__text {
			color: palette(brand);
		}
	}

	&__item-label {
		display: block;
		cursor: pointer;
	}

	&__item-inner {
		background-color: $c-white;
		// border-radius: 6px;
		box-shadow: 0px 0px 0px 1px $c-border;
		cursor: pointer;
		padding: $spacing-medium;
		pointer-events: none;
		position: relative;
		transition: box-shadow .2s $global-easing,
			margin-bottom .2s $global-easing, transform .2s $global-easing;
		transform: scale(1) translate3d(0, 0, 0);

		&:hover {
			box-shadow: 0px 0px 0px 1px $c-border, 0 0 20px rgba(0, 0, 0, .1);
			transform: translate3d(0, -4px, 0);
		}
	}

	&__icon {
		display: block;
		width: 170px;
		height: 170px;
		margin: 0 auto;
	}

	&__text {
		@include heading-9;
		margin-bottom: 0;
		display: block;
	}

	&__item-usage {
		background-color: $c-white;
		box-shadow: 0px 0px 0px 1px $c-border;
		padding: $spacing-medium;
	}
}

.c-product-options {
	@include breakpoint($bp-large) {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	&__rec {
		@include breakpoint($bp-large) {
			padding: $spacing-medium $spacing-xsmall 24px;
			background-color: palette(grey, xxx-light);
			width: 66%;
		}
	}

	&__alt {
		@include breakpoint($bp-large) {
			margin-left: 24px;
			width: 33%;
		}

		.c-product-option {
			@include breakpoint($bp-large) {
				background-color: palette(grey, xxx-light);
				padding: $spacing-medium 24px 24px;
			}
		}
	}

	.c-features-list {
		text-align: left;
	}
}

.c-product-option {
	margin-bottom: $spacing-medium;

	@include breakpoint($bp-large) {
		margin-bottom: 0;
		float: left;
		padding: 0 $spacing-small;
	}

	.c-product-options__rec & {
		@include breakpoint($bp-large) {
			width: 50%;
		}
	}
}

.c-product-card {
	.is-active & {
		background: palette(grey, super-light);
	}
}

.c-control {
	&.is-active {
		background: palette(grey, super-light);
	}
}

.c-products-form {
	.is-loading & {
		display: none;
	}
}

.c-product-selector-results {
	.is-loading & {
		display: none;
	}
}