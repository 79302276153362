.c-map-modal {
	background-color: $c-white;
	visibility: hidden;
	position: absolute;
	opacity: 0;
	pointer-events: none;
	max-width: 780px;
	padding: 0 !important; // Override Fancybox defaults

	@include breakpoint($bp-large) {
		min-width: 780px;		
	}  
	
	// Did this instead of display: none as it was throwing the map pin off centre
	.fancybox-slide & {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
		position: relative;
	}
	
	&__canvas {
		// width: 780px;
		height: 400px;
		
		> div {
			width: 100%;
			height: 100%;
		}
	}
	
	&__content {
		padding: $spacing-medium;
		
		@include breakpoint($bp-large) {
			padding: $spacing-large;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}  
		
		h3 {
			font-size: $font-size-10;
		}
		
		p:last-child {
			margin-bottom: 0;
		}
		
		&-full {
			flex: 0 1 100%;
			
			// First column
			+ .c-map-modal__content-half {
				border-bottom: 1px solid $c-border;
				margin-bottom: $spacing-medium;
				padding-bottom: $spacing-medium;
				
				@include breakpoint($bp-large) {
					padding-right: $spacing-xlarge;
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: none;
					flex: 1 0 60%;
				}  
			}
		}
		
		&-half {
			@include breakpoint($bp-large) {
				flex: 1 0 40%;
			}  
		}
	}
}