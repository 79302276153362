.c-page-nav {
	background-color: $c-white;
	background-color: palette(grey, mid);
	color: $c-white;
	
	// Override the Scrollmagic inline styles
	@include breakpoint($bp-medium-max) {
		position: relative !important;
		top: auto !important;
		width: auto !important;
	}
	
	@include breakpoint($bp-medium) {
		&.is-stuck {
			z-index: $z-page-nav;
			width: 100% !important;
		}
	}  
	
	.o-grid {
		@include breakpoint($bp-large) {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			align-items: center;
		}
	}
	
	&__title {
		font-size: 20px;
		text-transform: uppercase;
		margin: 0;
		display: none;
		font-weight: $font-weight-bold;
		
		@include breakpoint($bp-large) {
			flex: 400px 0 0;
			display: block;
		}
	}
	
	&__list {
		
		@include breakpoint($bp-medium) {
			display: flex;
			width: 100%;
		}
		
		@include breakpoint($bp-large) {
			justify-content: flex-end;
			width: calc(100% - 400px);
		}
	}
	
	ul {
		font-size: $font-small;
		
		@include breakpoint($bp-medium) {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		
		@include breakpoint($bp-xlarge) {
			font-size: $base-font-size;
			justify-content: flex-end;
		}  
		
		> li {
			text-align: center;
			
			&:first-child {
				@include breakpoint($bp-large) {
					margin-left: auto;
				}  
			}
			
			&.is-special {
				@include breakpoint($bp-large) {
					margin-bottom: -40px;
				}
				
				a {
					background-color: $c-brand;
					padding-left: 20px;
					padding-right: 20px;
					position: relative;
					z-index: 15;
					text-decoration: none;
					display: block;
					
					@media all and (-ms-high-contrast:none) {
						*::-ms-backdrop, & {
							background-image: url(/images/elements/hexagon.png);
							background-repeat: no-repeat;
							background-position: 0 100%;
							background-size: 100% auto;
						} /* IE11 */
					}
					
					span {
						position: relative;
						display: block;
						z-index: 16;
					}
					
					@include breakpoint($bp-large) {
						overflow: hidden;
						background-color: transparent;
						padding-bottom: 58px;
					}
					
					&:hover,
					&.is-active {
						svg {
							fill: darken($c-brand, 5%);
						}
					}
					
					svg {
						bottom: 0;
						fill: $c-brand; 
						left: 0;
						position: absolute;
						width: 100%;
						height: auto;
						display: none;
						transition: all .3s $global-easing;
						
						@include breakpoint($bp-large) {
							display: block;
						}  
						
						@media all and (-ms-high-contrast:none) {
							*::-ms-backdrop, & {
								display: none;
							} /* IE11 */
						}
					}
				}
				
			}
		}
	}
	
	a {
		color: $c-white;
		text-align: center;
		display: block;
		line-height: $font-leading-xsmall;
		padding: 8px $spacing-xsmall;
		text-decoration: none;
		
		&:hover,
		&.is-active {
			.no-touchevents & {
				background-color: darken(palette(grey, mid), 10%);
			}
		}
		
		@include breakpoint($bp-medium) {
			padding: 12px .8em;
		}  
		
		@include breakpoint($bp-large) {
			padding: 16px .8em;
		}  
	}
}

.scrollmagic-pin-spacer {
	@include breakpoint($bp-large-max) {
		height: auto !important;
	}  
}