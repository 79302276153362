.o-grid,
%o-grid {
    @include outer-container;
    position: relative;
    padding: 0 $spacing-base;

    @include breakpoint($bp-medium) {
        padding: 0 $spacing-medium;
    }

    &--no-pad {
        padding: 0;
    }

    &--no-pad\@large-max {
        @include breakpoint($bp-large-max) {
            padding: 0;
        }
    }

    &--not-relative {
        position: static;
    }

    &--skinny {
        @include breakpoint($bp-medium) {
            max-width: 780px;
        }
    }

    &--narrow {
    	@include breakpoint($bp-large) {
			width: 80%;
			max-width: 950px;
		}
    }

    // Used to vertically align columns
    &--flex {
        @include breakpoint($bp-medium) {
            display: flex;
            align-items: center;
        }
    }
    
    &--flex\@large {
        @include breakpoint($bp-large) {
            display: flex;
            align-items: center;
        }
    }
    
    &--no-max-width {
        max-width: none;
        padding: 0;
    }
}
