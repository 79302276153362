.o-tags {
	position: absolute;
	right: 0;
	top: 60px;

	li {
		margin-bottom: $spacing-xxsmall;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}