.c-image-card {
	background-color: $c-white;
	color: inherit;
	text-decoration: none !important;
	display: block;
	
	img {
		min-width: 100%;
	}

	&--highlight {
		background-color: $c-slate-grey;
		color: $c-white;

		.c-meta .c-meta__category {
			color: $c-white;
		}
		
		.c-image-card__content {
			color: $c-white;
		}
	}

	&__content {
		padding: $spacing-medium;
		color: $c-font;

		p:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		font-size: $base-font-size;
		font-weight: $font-weight-bold;
	}
}