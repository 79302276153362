.c-range {
	padding: 0 30px;
	margin-bottom: $spacing-medium;

	@include breakpoint($bp-large) {
		margin-bottom: 0;
	}

	&__min,
	&__max {
		@include heading-10;
		font-weight: $font-weight-bold;
		padding: $spacing-small;
		display: block;
	}

	&__min {
		float: left;
	}

	&__max {
		float: right;
	}
}
