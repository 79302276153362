.c-meta {

	li {
		&:first-child {
			color: $c-brand;
			font-size: 14px;
			text-transform: uppercase;
			display: block;

			time {
				color: $c-font;
			}
		}
	}

	time {
		font-weight: 300;
		font-size: 14px;
		margin-bottom: 1em;
		display: block;
	}
	
	&--inline {
		align-items: center;
		display: flex;
		margin-bottom: 1rem;
		
		li {
			
			position: relative;
			padding-right: 1em;
			margin-right: 1em;
			
			&:after {
				content: '';
				position: absolute;
				right: 0;
				height: .8em;
				width: 1px;
				background-color: #fff;
				display: block;
				top: 50%;
				margin-top: -.4em;
			}
			
			&:first-child {
				display: inline-block;
			}
			
			&:last-child {
				&:after {
					display: none;
				}
			}
			
			time {
				margin-bottom: 0;
			}
		}
	}
}