.c-twitter-card {
	background-color: $c-brand;
	color: $c-white;
	text-decoration: none !important;
	display: block;
	
	&__title {
		font-size: $font-small;
		text-transform: uppercase;
		display: block;
		margin-bottom: 1.4em;
	}
	
	&__content {
		padding: $spacing-medium;
		font-size: $font-size-10;
		font-weight: $font-weight-bold;
		line-height: $font-leading-xsmall;
	}
	
	&__date {
		font-size: $base-font-size;
		font-weight: normal;
		margin-bottom: 0;
	}
	
	footer {
		border-top: 1px solid $c-brand;
		padding: $spacing-medium;
		display: flex;
		align-items: center;
		
		a {
			color: $c-white;
		}
		
		img {
			max-width: none;
			margin-right: $spacing-small;
		}
	}
}