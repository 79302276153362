.c-form-radio {

	&__input {
		left: -9999em;
		position: absolute;

		&:checked {
			& + .c-form-radio__label:after {
				background: $c-brand;
			}
		}
	}

	&__label {
		cursor: pointer;
		letter-spacing: 0;
		line-height: 2em;
		padding-left: 30px;
		padding-right: 20px;
		position: relative;
		text-transform: none;

		&:before {
			background: $c-white;
			border-radius: 20px;
			border: 1px solid palette(grey, mid-dark);
			content: '.';
			cursor: pointer;
			height: 20px;
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 20px;
		}

		&:after {
			border-radius: 20px;
			content: '.';
			cursor: pointer;
			height: 10px;
			left: 5px;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 10px;
		}
	}

	&--inline {
		@include breakpoint($bp-small) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.c-form-radio__group-label {
			@include breakpoint($bp-small) {
				flex-grow: 1;
				padding-right: $spacing-base;
				margin-bottom: 0;
			}
		}

		.c-form-radio__label {
			@include breakpoint($bp-small) {
				margin-bottom: 0;
			}
		}
	}

	&--border-bottom {
		border-bottom: 1px solid palette(grey, xxx-light);
	}

	&--border-top {
		border-top: 1px solid palette(grey, xxx-light);
	}
}



	

		