.u-spacing-top-zero {
	&#{&} {
		margin-top: 0;
	}
}

.u-spacing-bottom-zero {
	&#{&} {
		margin-bottom: 0;
	}
}

.u-spacing-top-small {
	&#{&} {
		margin-top: $spacing-small;
	}
}

.u-spacing-bottom-small {
	&#{&} {
		margin-bottom: $spacing-small;
	}
}

.u-spacing-bottom-xxsmall {
	&#{&} {
		margin-bottom: $spacing-xxsmall;
	}
}

.u-spacing-bottom-xsmall {
	&#{&} {
		margin-bottom: $spacing-xsmall;
	}
}

.u-spacing-top-base {
	&#{&} {
		margin-top: $spacing-base;
	}
}

.u-spacing-bottom-base {
	&#{&} {
		margin-bottom: $spacing-base;
	}
}

.u-spacing-top-medium {
	&#{&} {
		margin-top: $spacing-medium;
	}
}

.u-spacing-bottom-medium {
	&#{&} {
		margin-bottom: $spacing-medium;
	}
}

.u-spacing-top-large {
	&#{&} {
		margin-top: $spacing-large;
	}
}

.u-spacing-bottom-large {
	&#{&} {
		margin-bottom: $spacing-large;
	}
}

.u-spacing-top-xlarge {
	&#{&} {
		margin-top: $spacing-xlarge;
	}
}

.u-spacing-bottom-xlarge {
	&#{&} {
		margin-bottom: $spacing-xlarge;
	}
}

/**
* @bp-xsmall
*/

.u-spacing-bottom-medium\@xsmall {
	&#{&} {
		@include breakpoint($bp-xsmall) {
			margin-bottom: $spacing-medium;
		}  
	}
}

/**
* @bp-medium
*/

.u-spacing-top-zero\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: 0;
		}
	}
}

.u-spacing-bottom-zero\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: 0;
		}
	}
}

.u-spacing-top-small\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: $spacing-small;
		}
	}
}

.u-spacing-bottom-small\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-small;
		}
	}
}

.u-spacing-top-base\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: $spacing-base;
		}
	}
}

.u-spacing-bottom-base\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-base;
		}
	}
}

.u-spacing-top-medium\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: $spacing-medium;
		}
	}
}

.u-spacing-bottom-medium\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-medium;
		}
	}
}

.u-spacing-top-large\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: $spacing-large;
		}
	}
}

.u-spacing-bottom-large\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-large;
		}
	}
}

/**
* @bp-large
*/

.u-spacing-top-zero\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-top: 0;
		}
	}
}

.u-spacing-bottom-zero\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-bottom: 0;
		}
	}
}

.u-spacing-top-small\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-top: $spacing-small;
		}
	}
}

.u-spacing-bottom-small\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-bottom: $spacing-small;
		}
	}
}

.u-spacing-top-base\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-top: $spacing-base;
		}
	}
}

.u-spacing-bottom-base\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-bottom: $spacing-base;
		}
	}
}

.u-spacing-top-medium\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-top: $spacing-medium;
		}
	}
}

.u-spacing-bottom-medium\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-bottom: $spacing-medium;
		}
	}
}

.u-spacing-right-medium\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			padding-right: $spacing-medium;
		}
	}
}


.u-spacing-top-large\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-top: $spacing-large;
		}
	}
}

.u-spacing-bottom-large\@large {
	&#{&} {
		@include breakpoint($bp-large) {
			margin-bottom: $spacing-large;
		}
	}
}

.u-spacing-top-xlarge\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-top: $spacing-large;
		}
	}
}

.u-spacing-bottom-xlarge\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			margin-bottom: $spacing-xlarge;
		}
	}
}