.c-brands {
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	margin-top: $spacing-large;

	&__brand {
		flex-basis: 100%;
		margin: $spacing-base 0;
		padding: 0 $spacing-base;

		@include breakpoint($bp-medium) {
    		flex-basis: 33.33%;
		}

		@include breakpoint($bp-large) {
    		flex-basis: 25%;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $spacing-base;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		p {
			color: $c-font;
		}
	}
}