.c-top-bar {
	background-color: $c-white;
	// padding: 16px 0 10px;
	border-bottom: 1px solid $c-border;
	
	@include breakpoint($bp-large) {
		padding: 10px 0 calc(#{$spacing-small} - (#{$c-nav-margin-top} / 2)) 0;
	}
	
	@include breakpoint($bp-xlarge) {
		padding: 10px 0 calc(#{$spacing-base} - (#{$c-nav-margin-top} / 2)) 0;
	}
	
	> .o-grid {
		width: 100%;
		
		@include breakpoint($bp-large-max) {
			padding-right: 0;
		}  
	}
	
	&__logo {
		width: 150px;
		height: 56px;
		display: inline-block;
		margin-top: 6px;
		
		@include breakpoint($bp-large) {
			margin-top: 2px;
			width: 170px;
			height: 71px;
			float: left;
		}
		
		@include breakpoint($bp-xlarge) {
			margin-top: 0;
			width: 240px;
			height: 91px;
		}
		
		img {
			display: inline-block;
			line-height: 0;
			max-width: 100%;
			vertical-align: middle;
		}
	}

	&__logo-ideal {
		display: inline;
		width: 80px;

		@include breakpoint($bp-large) {
			width: 100px;
		}

		@include breakpoint($bp-xlarge) {
			width: 130px;
		}
	}

	&__logo-macmillan {
		display: inline;
		width: 60px;

		@include breakpoint($bp-large) {
			width: 60px;
			margin-left: 5px;
		}

		@include breakpoint($bp-xlarge) {
			width: 90px;
			margin-left: 10px;
		}
	}
	
	&__utils {
		float: right;
		
		> li {
			display: inline-block;
		}
	}
	
	&__search-toggle {
		display: block;
		position: absolute;
		top: 23px;
		margin-right: 0;
		width: 40px;
		outline: none;
		right: 56px;
		
		@include breakpoint(max-width 350px) {
			right: 44px;
		}  
		
		@include breakpoint($bp-large) {
			position: relative;
			right: auto;
			top: 35px;
		}  
		
		@include breakpoint($bp-xlarge) {
			top: 55px;
		}  
		
		svg {
			width: 20px;
			height: 20px;
			fill: $c-font;
			transition: all .3s $global-easing;
			
			@include breakpoint($bp-large) {
				width: 20px;
				height: 20px;
				fill: palette(grey, mid-light);
			}  
		}
		
		&.is-active {
			svg {
				fill: $c-brand;
			}
		}
		// 
		// @include breakpoint($bp-large) {
		// 	margin-top: 38px;
		// 	top: auto;
		// 	margin-right: 0;
		// 	
		// 	svg {
		// 		width: 20px;
		// 		height: 20px;
		// 	}
		// }
	}
}