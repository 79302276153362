// Minimal initial style of sidebar
$sidebar-width: 260px;
$sidebar-width-neg: -260px;

// Fonts
$sidr-font-family: %font-roboto;
$sidr-font-size: 15px;

// Colors
$sidr-background: palette(grey, super-light);
$sidr-background-shadow-color: palette(grey, super-light);
$sidr-text-color: $c-font;
$sidr-box-shadow-color: $c-black;
$sidr-input-background-color: $c-black;

.sidr {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    width: $sidebar-width;
    overflow-x: hidden;
    overflow-y: auto;

    &.right {
        left: auto;
        right: $sidebar-width-neg;
    }

    &.left {
        left: $sidebar-width-neg;
        right: auto;
    }
}

.sidr {
    // Default Settings
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    width: $sidebar-width;
    overflow-x: hidden;
    overflow-y: auto;

    @include breakpoint($bp-small) {
        display: none;
    }

    .sidr-inner {
        padding: 0 0 15px;

        > p {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &.right {
        left: auto;
        right: $sidebar-width-neg;
    }

    &.left {
        left: $sidebar-width-neg;
        right: auto;
    }

    // Theme Settings
    font-family: $sidr-font-family;
    font-size: $sidr-font-size;
    background: $sidr-background;
    color: $sidr-text-color;

    .sidr-class-c-nav__item-top {
        border-bottom: 1px solid $c-border;
    }

    .sidr-class-c-nav__item-link {
        @extend %font-headline;
        display: block;
        padding: $spacing-small;
        color: $c-brand;
    }

    .sidr-class-c-nav__parent {
        > a {
            position: relative;
            outline: 0;

            &:after {
                @extend %font-icon;
                content: "\E003";
                color: $c-brand;
                position: absolute;
                right: $spacing-small;
                top: 50%;
                margin-top: -6px;
                transform: rotate(90deg);
            }
        }

        &.is-active {
            > a {
                 &:after {
                    transform: rotate(-90deg);
                     margin-top: -8px;
                }
            }
        }

    }

    .sidr-class-c-submenu__promo {
        display: none;
    }


    .sidr-class-c-submenu {
        display: none;

        &.is-active {
            display: block;
        }

        &:last-of-type {
            padding-bottom: $spacing-small;
        }

        .sidr-class-c-submenu__list {
             &:first-of-type {
                .sidr-class-c-submenu__subhead {
                    padding-top: 0;
                }
            }
        }


        a {
            @extend %font-headline;
            display: block;
            padding: $spacing-xxsmall $spacing-small;
            color: $c-font-light;
            font-size: $font-small;
            outline: 0;
        }

        .sidr-class-c-submenu__subhead {
            font-size: $font-small;
            padding-top:  $spacing-xsmall;

            a {
                @extend %font-headline;
                display: block;
                padding: $spacing-xxsmall $spacing-small;
                color: $c-font;
                font-size: $font-small;
            }
        }
    }


}