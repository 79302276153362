.c-contact-list {
	font-size: $font-size-10;
	font-weight: 300;

	svg {
		width: 40px;
		height: 40px;
		fill: #bcbdbd;
		float: left;
		margin-right: $spacing-small;
		position: relative;
		top: 5px;
	}

	dd {
		color: $c-brand;
		margin-bottom: $spacing-base;
		margin-left: 0;
		font-weight: $font-weight-semibold;
	}

	span {
		display: block;
	}

	&__sub {
		color: palette(grey, mid);
		font-size: $font-size-11;
	}
}