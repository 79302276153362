.c-features-list {
	font-weight: $font-weight-light; // @TODO: This might break other pages, standardise
	
	&--border {
		border-top: 1px solid $c-border;
		border-bottom: 1px solid $c-border;
	}

	li {
		border-bottom: 1px solid $c-border;
		display: flex !important; // To bully Neat grid from changing it for the &--halves selector
		padding: 12px 0 12px;
		align-items: center;

		&:last-child {
			border-bottom: none;
		}
	}

	svg {
		fill: palette(grey, xx-light);
		flex: 0 0 30px;
		height: 30px;
		margin-right: $spacing-base;
		width: 30px;
		
		&.c-features-list__icon--dark {
			fill: currentColor;
		}
		
	}

	&--halves {
		li {
			@include breakpoint($bp-medium) {
				@include span-columns(6);
			}

			&:nth-child(2n) {
				@include breakpoint($bp-medium) {
					@include omega();
				}
			}

			&:last-child {
				border-bottom: 1px solid $c-border;
			}
		}
	}
	
	&--large {
		font-size: $font-size-11;
	}
	
	&--no-border {
		li {
			border-bottom: none;
			padding-top: 0;
			// padding-bottom: 0;
		}
	}
}