.c-timeline-item {
    padding: 50px 0 50px 50px;
    position: relative;
    width: 100%;

    @include breakpoint($bp-medium) {
        display: flex;
        padding: 150px 0;
    }

    &:before {
        background-color: palette(grey, mid);
        content: "";
        bottom: 0;
        left: 20px;
        position: absolute;
        top: 0;
        transform: translate(-50%, 0);
        width: 2px;

        @include breakpoint($bp-medium) {
            left: 50%;
            width: 4px;
        }
    }

    &:after {
        background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2060%2067.2%22%3E%3Cpath%20d%3D%22M60%2C20.09v27a6.27%2C6.27%2C0%2C0%2C1-2.94%2C5.69L33.87%2C66.32a6.44%2C6.44%2C0%2C0%2C1-6.5%2C0L3.65%2C52.8A6.91%2C6.91%2C0%2C0%2C1%2C0%2C47.11v-27a7%2C7%2C0%2C0%2C1%2C3.66-5.69L27.3%2C0.88A6.72%2C6.72%2C0%2C0%2C1%2C34%2C.88l23.1%2C13.51A6.3%2C6.3%2C0%2C0%2C1%2C60%2C20.09Z%22%20fill%3D%22%2358595b%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: block;
        height: 24px;
        left: 20px;
        position: absolute;
        top: 50px;
        transform: translate(-50%, 0);
        width: 20px;
        z-index: 10;

        @include breakpoint($bp-medium) {
            top: 150px;
            left: 50%;
            height: 30px;
            width: 27px;
        }
    }

    &__content,
    &__image {
        @include breakpoint($bp-medium) {
            padding: 0 60px;
            width: 100%;
        }

        @include breakpoint($bp-large) {
            padding: 0 80px;
        }

        @include breakpoint($bp-xlarge) {
            padding: 0 100px;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__content {

        @include breakpoint($bp-medium) {
            text-align: right;
        }
    }

    &__content-inner {

        @include breakpoint($bp-medium) {
            max-width: 380px;
            margin-left: auto;
        }
    }

    &__image {
        img {
            margin: 0 auto;
            max-width: 350px;
            width: 100%;

            @include breakpoint($bp-medium) {
                margin-right: auto;
            }
        }
    }
}