.c-video-item {
	position: relative;
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	display: block;
	padding: $spacing-medium;
	position: relative;
	color: $c-white;

	&:before {
		top: 0;
		left: 0;
		content: "";
		position: absolute;
		background-color: rgba(0,0,0,.5);
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	@include breakpoint($bp-medium) {
		height: 500px;
		overflow: hidden;
	}

	&__play {
		display: block;
		height: toRems(80px);
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: toRems(72px);

		&:before,
		&:after {
			background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2064%2071.2%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%224%22%20stroke-miterlimit%3D%2210%22%20d%3D%22M62%2C22.1v27c0.1%2C2.3-1%2C4.5-2.9%2C5.7L35.9%2C68.3c-2%2C1.2-4.5%2C1.2-6.5%2C0L5.7%2C54.8c-2.1-1.1-3.5-3.3-3.7-5.7v-27c0.2-2.4%2C1.5-4.5%2C3.7-5.7L29.3%2C2.9c2.1-1.2%2C4.6-1.2%2C6.7%2C0l23.1%2C13.5C61%2C17.6%2C62.1%2C19.8%2C62%2C22.1z%22%20%2F%3E%3Cpath%20d%3D%22M41%2C33.7c2.1%2C1.1%2C2.1%2C2.9%2C0%2C4l-13.8%2C7.1c-2.1%2C1.1-3.8%2C0.1-3.8-2.3V28.8c0-2.4%2C1.7-3.4%2C3.8-2.3L41%2C33.7z%22%20fill%3D%22%23fff%22%20%2F%3E%3C%2Fsvg%3E');
			background-repeat: no-repeat;
			background-size: cover;
			content: "";
			height: toRems(67px);
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-52%, -50%);
			transition: opacity 0.3s $global-easing;
			width: toRems(60px);

			@include breakpoint($bp-medium) {
				height: toRems(80px);
				width: toRems(72px);
			}
		}

		&:before {
			background-image: url('data:image/svg+xml;charset=utf-8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2064%2071.2%22%3E%3Cpath%20fill%3D%22%2357ac25%22%20stroke%3D%22%2357ac25%22%20stroke-width%3D%224%22%20stroke-miterlimit%3D%2210%22%20d%3D%22M62%2C22.1v27c0.1%2C2.3-1%2C4.5-2.9%2C5.7L35.9%2C68.3c-2%2C1.2-4.5%2C1.2-6.5%2C0L5.7%2C54.8c-2.1-1.1-3.5-3.3-3.7-5.7v-27c0.2-2.4%2C1.5-4.5%2C3.7-5.7L29.3%2C2.9c2.1-1.2%2C4.6-1.2%2C6.7%2C0l23.1%2C13.5C61%2C17.6%2C62.1%2C19.8%2C62%2C22.1z%22%20%2F%3E%3Cpath%20d%3D%22M41%2C33.7c2.1%2C1.1%2C2.1%2C2.9%2C0%2C4l-13.8%2C7.1c-2.1%2C1.1-3.8%2C0.1-3.8-2.3V28.8c0-2.4%2C1.7-3.4%2C3.8-2.3L41%2C33.7z%22%20fill%3D%22%23fff%22%20%2F%3E%3C%2Fsvg%3E');
			opacity: 1;
			z-index: 1;

			@include breakpoint($bp-small) {
				opacity: 0;
			}
		}
	}

	&__title {
		@include heading-8;
		font-weight: 400;
		line-height: 1.2;
		font-size: $font-size-10;
		margin-bottom: $spacing-small;
		text-align: center;

		@include breakpoint($bp-medium) {
			text-align: left;
			font-size: $font-size-9;
			margin-bottom: $spacing-base;
		}

		@include breakpoint($bp-xlarge) {
			font-size: $font-size-8;
			margin-bottom: toRems(25px);
		}
	}

	&__content {
		position: absolute;
		bottom: 0;
		display: block;
		left: 0;
		right: 0;
		padding: $spacing-small;
		text-align: left;
		transition: all .3s $global-easing;

		@include breakpoint($bp-medium-max) {
			transform: translateY(0) !important; // We don't want the JS sizing to take effect
		}

		@include breakpoint($bp-medium) {
			opacity: 0;
			padding: $spacing-base;
		}

		@include breakpoint($bp-xlarge) {
			padding-left: toRems(40px);
			padding-right: toRems(40px);
		}
	}

	&__subcontent {
		display: none;
		transform: scale(.9);
		transition: all .3s $global-easing;
		padding-bottom: 10px;

		@include breakpoint($bp-medium) {
			display: block;
			position: relative;
			opacity: .5;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	&:hover {

		.c-video-item__play {
			&:before {
				opacity: 1;
			}
		}

		.c-video-item__content {
			@include breakpoint($bp-medium) {
				transform: translateY(0) !important;
			}
		}

		.c-video-item__subcontent {
			@include breakpoint($bp-medium) {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}