// Tables
@mixin hover {
	&:hover {
		@content;
	}
}

@mixin table-row-variant($state, $background) {
	// Exact selectors below required to override `.table-striped` and prevent
	// inheritance to nested tables.
	.table-#{$state} {
		&,
		> th,
		> td {
			background-color: $background;
		}
	}

	// Hover states for `.table-hover`
	// Note: this is not available for cells or rows within `thead` or `tfoot`.
	.table-hover {
		$hover-background: darken($background, 5%);

		.table-#{$state} {
			@include hover {
				background-color: $hover-background;

				> td,
				> th {
					background-color: $hover-background;
				}
			}
		}
	}
}

$spacer: $spacing-base;
$table-cell-padding: $spacing-base;
$table-border-width: 1px;
$table-border-color: $c-border;
$body-bg: $c-white;
$table-sm-cell-padding: $spacing-xsmall;
$table-bg-accent: palette(grey, super-light);
$table-bg-hover: palette(grey, xxx-light);
$table-head-color: $c-font;
$table-head-bg: palette(grey, super-light);
$table-inverse-color: $c-white;
$table-inverse-bg: palette(grey, dark);
$table-bg-active: palette(grey, super-light);
$state-success-bg: #dff0d8;
$state-info-bg: #d9edf7;
$state-warning-bg: #fcf8e3;
$state-danger-bg: #f2dede;
//
// Basic Bootstrap table
//

table,
.o-table {
	width: 100%;
	max-width: 100%;
	margin-bottom: $spacer;
	font-size: $font-size-11;

	&--product-selector-results {
		margin-bottom: 0;
		min-width: 800px;
		border-bottom: 2px solid $c-border;

		thead {
			th {
				border: 2px solid palette(grey, mid-dark);

				&:first-child {
					border-right: 4px solid rgba(255, 255, 255, .3);
				}
			}
		}

		tbody {
			th {
				&:first-child {
					border-left: 2px solid $c-border;
					min-width: 190px;
				}
				&:nth-child(3) {
					border-left: 4px solid $c-border;
					min-width: 202px;
				}
			}
			td {
				border-left: 2px solid $c-border;
				border-right: 2px solid $c-border;
			}
		}
	}

	tr:first-child {
		th,
		td {
			border-top: 0;
		}
	}

	th {
		text-align: left;
	}

	th,
	td {
		padding: $spacing-small;
		vertical-align: top;
		border-top: $table-border-width solid $table-border-color;
		// font-size: $font-size-7;

		@include breakpoint($bp-medium) {
			padding: $table-cell-padding;
		}
	}

	td {
		font-weight: $font-weight-light;
	}

	thead th {
		vertical-align: bottom;
		border-bottom: (2 * $table-border-width) solid #aeaeae;
	}

	tbody + tbody {
		border-top: (2 * $table-border-width) solid $table-border-color;
	}

	&--flush {
		tbody tr:first-child {
			th,
			td {
				padding-top: 0;
			}
		}

		tbody tr:last-child {
			th,
			td {
				padding-bottom: 0;
			}
		}

		tr {
			td:first-child,
			th:first-child {
				padding-left: 0;
			}
			td:last-child,
			th:last-child {
				padding-right: 0;
			}
		}
	}

	&--bg {
		background: $c-white;
		border: 15px solid white;
	}

	&--nomargin {
		margin-bottom: 0;
	}

	//
	// Condensed table w/ half padding
	//

	&--sm {
		font-size: $font-small;
		th,
		td {
			padding: $table-sm-cell-padding;
		}
	}

	// Bordered version
	//
	// Add borders all around the table and between all the columns.

	&--bordered {
		border: $table-border-width solid $table-border-color;

		th,
		td {
			border: $table-border-width solid $table-border-color;
		}

		thead {
			th,
			td {
				border-bottom-width: (2 * $table-border-width);
			}
		}
	}

	// Zebra-striping
	//
	// Default zebra-stripe styles (alternating gray and transparent backgrounds)

	&--striped {
		tbody tr:nth-of-type(odd) {
			background-color: $table-bg-accent;
		}
	}

	// Hover effect
	//
	// Placed here since it has to come after the potential zebra striping

	&--hover {
		tbody tr {
			@include hover {
				background-color: $table-bg-hover;
			}
		}
	}

	// Table backgrounds
	//
	// Exact selectors below required to override `.table-striped` and prevent
	// inheritance to nested tables.

	// Generate the contextual variants
	@include table-row-variant(active, $table-bg-active);
	@include table-row-variant(success, $state-success-bg);
	@include table-row-variant(info, $state-info-bg);
	@include table-row-variant(warning, $state-warning-bg);
	@include table-row-variant(danger, $state-danger-bg);

	// Inverse styles
	//
	// Same table markup, but inverted color scheme: dark background and light text.

	&--inverse {
		.thead-inverse {
			th {
				color: $table-inverse-color;
				background-color: $table-inverse-bg;
			}
		}

		.thead-default {
			th {
				color: $table-head-color;
				background-color: $table-head-bg;
			}
		}

		&.table-inverse {
			color: $table-inverse-color;
			background-color: $table-inverse-bg;

			th,
			td,
			thead th {
				border-color: $body-bg;
			}

			&.table-bordered {
				border: 0;
			}
		}
	}

	// Responsive tables
	//
	// Add `.table-responsive` to `.table`s and we'll make them mobile friendly by
	// enabling horizontal scrolling. Only applies <768px. Everything above that
	// will display normally.

	&--responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

		// Prevent double border on horizontal scroll due to use of `display: block;`
		&.table-bordered {
			border: 0;
		}
	}

	&--stacked\@small {
		@include breakpoint($bp-xsmall) {
			th,
			td {
				display: block;
				width: 100%;
			}
		}
	}

	&--small-block {
		@include breakpoint(max-width $bp-small) {
			th {
				display: none;
			}
			td {
				clear: both;
				display: block;
				border: 0;
				padding: 0;

				&:first-of-type {
					padding-top: $spacing-small;
				}

				&:last-of-type {
					border-bottom: 1px solid $c-border;
					padding-bottom: $spacing-small;
				}
			}
		}
	}

	&--underline {
		border-bottom: 1px solid $table-border-color;
	}

	th,
	td {
		&.no-bottom-border {
			border-bottom: none;
		}
	}
}
