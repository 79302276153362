.c-hero-simple {
	padding: $spacing-large 0;
	position: relative;

	&--more-padding {
		@include breakpoint($bp-large) {
			padding: $spacing-xlarge 0;
		}  
	}
	
	.o-grid {
		position: relative;
		z-index: 1;
		
		@include breakpoint($bp-large) {
			display: flex;
			align-items: center;
		}  
	}
	
	&__content {
		
		p {
			font-weight: $font-weight-light;
		}
		
		@include breakpoint($bp-large) {
			width: 50%;
			
			h2 {
				margin-bottom: .4em;
				
				&.u-heading-5 {
					margin-bottom: .7em;
				}
			}
		}  
	}
	
	&__image {
		margin-bottom: $spacing-medium;
		
		img {
			margin: 0 auto;
		}
		
		@include breakpoint($bp-large) {
			margin-bottom: 0;
			order: 1;
			width: 50%;
		}  
	}
	
	&--swap-order {
		@include breakpoint($bp-large) {
			.c-hero-simple__image {
				order: 0;
			}
		}  
	}
	
	&--inset-image {
		position: relative;
		z-index: 10;
		
		.c-hero-simple__image {
			@include breakpoint($bp-large) {
				margin-bottom: -100px;
			}  
		}
	}
	
	&--hexagon-page-bg {
		overflow: hidden;
		
		@include breakpoint($bp-large) {
			padding-top: 540px;
		}  
	}
	
	&__hexagon-mask {
		pointer-events: none;
		width: 3100px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-left: 250px; // Nudge along so that it sits under the text content
		right: 0;
		top: 0;
		z-index: 0;
		background-image: url(/images/elements/hexagon-giant-page-outline.svg);
		background-position: 50% 0;
		background-repeat: no-repeat;
		display: none;
		
		@include breakpoint($bp-large) {
			display: block;
		}  
	}
	
}