.o-video {
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: toRems(30px);
	margin-top: toRems(30px);
	
	&__responsive {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
	}
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.c-hero-simple__image & {
		@include breakpoint($bp-large) {
			padding-right: $spacing-large;
		}  
	}
}
