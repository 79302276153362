.u-overflow-hidden {
	&#{&} {
		overflow: hidden;
	}
}

.u-relative {
	position: relative;
	z-index: 1;
}

.u-block {
	&#{&} {
		display: block;
	}
}

.u-inline-block {
	&#{&} {
		display: inline-block;
	}
}

/**
* Displays @bp-small
*/

.u-block\@small {
	&#{&} {
		display: none;
		
		@include breakpoint($bp-small) {
			display: block;
		}  
	}
}

.u-inline-block\@small {
	&#{&} {
		display: none;
		
		@include breakpoint($bp-small) {
			display: inline-block;
		}  
	}
}


/**
* Displays @bp-medium
*/

.u-block\@medium {
	&#{&} {
		display: block;
	}
}

.u-inline-block\@medium {
	&#{&} {
		display: inline-block;
	}
}


/**
* Floats @bp-medium
*/

.u-float-left\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			float: left !important;
		}
	}
}

.u-float-right\@medium {
	&#{&} {
		@include breakpoint($bp-medium) {
			float: right !important;
		}
	}
}

.u-clearfix {
	&#{&} {
		@include clearfix;
	}
}