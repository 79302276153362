.c-footer-social {
	display: flex;
	
	li {
		margin-right: 10px;
	}
	
	svg {
		width: 36px;
		height: 36px;
		fill: $c-white;
		transition: all .3s $global-easing;
		
		&:hover {
			fill: $c-link;
		}
	}
}