.c-carousel {
	background: #e3e3e3;
	position: relative;

	&__slides {
		li {
			.is-loading & {
				display: none;
			}

			&:first-child {
				display: block;
			}
		}

		.slick-dots {
			li {
				display: inline-block;
			}
		}
	}

	&__slide {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f6f6+23,e3e3e3+100 */
		background: #f6f6f6; /* Old browsers */
		background: -webkit-linear-gradient(#f6f6f6 23%, #e3e3e3 100%);
		background: -o-linear-gradient(#f6f6f6 23%, #e3e3e3 100%);
		background: linear-gradient(#f6f6f6 23%, #e3e3e3 100%); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#e3e3e3',GradientType=0 ); /* IE6-9 */
		background-size: cover;
		background-position: center center;
		padding: $spacing-medium 0;

		@include breakpoint($bp-large) {
			padding: $spacing-large * 1.2 0;
		}

		&--light {
			color: $c-white;
		}

	}

	&__slide-inner {

		@include breakpoint($bp-large) {
			display: flex;
			align-items: center;
		}

		&--image-right,
		&--video,
		&--box-right {
			flex-direction: row-reverse;
		}
	}

	&__content {
		text-align: center;
		
		@include breakpoint($bp-large) {
			@include span-columns(6);
			padding: $spacing-large 0 $spacing-large $spacing-medium;
			text-align: left;

			&--image-right,
			&--video {
				padding: $spacing-large $spacing-medium $spacing-large 0;
			} 
		}

		&--bg-image {
			background: rgba(0,0,0,.7);
			padding: $spacing-medium;
			color: white; 
		}

		p {
			
			margin-bottom: $spacing-medium;

			@include breakpoint($bp-large) {
				font-size: $font-size-10;
			}
		}
	}

	&__image {
		margin-bottom: $spacing-large;
		text-align: center;

		@include breakpoint($bp-large) {
			@include span-columns(6);
			margin-bottom: 0;
			max-width: 	100%;
			text-align: center;
		}

		img {
			max-height: 300px;
			margin: 0 auto;

			@include breakpoint($bp-large) {
				max-height: 100%;
			}

		}
	}

	&__nav-holder {
		background: palette(grey, super-light);
		display: none;

		.is-loading & {
			display: none;
		}

		@include breakpoint($bp-large) {
			display: block;
		}
	}

	&__nav {
		background: palette(grey, super-light);
		position: relative;
		z-index: 5;


		@include breakpoint($bp-medium) {
			display: flex;
		}

		li {
		
			border-right: 1px solid palette(grey, xx-light);
			position: relative;
			flex-grow: 1;
    		flex-basis: 0;
		
			@include breakpoint($bp-medium) {
				width: auto !important;
				float: none;
				flex-grow: 1;
			}

			&:first-child {
				border-left: 1px solid palette(grey, xx-light);
			}

			button {
				transition: background .3s $global-easing;
				padding: $spacing-medium;
				outline: 0;
				width: 100%;
				height: 100%;
				text-align: left;

				&:hover {
					background: palette(grey, x-light);
				}

				&:before {
					transition: all .3s $global-easing;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 20px 20px 20px;
					border-color: transparent transparent palette(brand, mid) transparent;
					position: absolute;
					top: -20px;
					left: 50%;
					margin-left: -10px;
					visibility: hidden;
					opacity: 0;

				}

				&.is-active {
					background: palette(brand, mid);
					color: white;

					&:before {
						visibility: visible;
						opacity: 1;
					}
				}
			}

		}

		h2 {
			margin-bottom: 0;
			text-transform: uppercase;
			font-size: $font-size-10;
		}

		p {
			margin: 0;
			font-size: $font-small;
		}
	}


	&__video {
		display: inline-block;
		position: relative;
		max-width: 100%;

		&:before {
			top: 0;
			left: 0;
			content: "";
			position: absolute;
			background-color: rgba(0,0,0,.5);
			width: 100%;
			height: 100%;
			z-index: 0;
			// opacity: 0;
			// visibility: hidden;
			// transition: all .3s $global-easing;
		}

		&:hover {
			&:before {
				// opacity: 1;
				// visibility: visible;
			}

			.c-video-item__play {
				&:before {
					opacity: 1;
				}
			}
		}

		
	}

	&_video--inner {
		position: relative;
	}
}