.c-search-result {
	color: inherit;
	margin-bottom: $spacing-medium;
	display: block;
	
	@include breakpoint($bp-medium) {
		font-size: $font-size-10;
	}  
	
	&:hover {
		text-decoration: none;
			
		h3 span {
			border-bottom-color: transparent;
		}
	}
	
	h3 {
		color: $c-brand;
		font-size: $font-size-10;
		font-weight: $font-weight-normal;
		margin-bottom: .3em;
		text-decoration: none;
		display: inline-block;
		line-height: 1.1;
		
		@include breakpoint($bp-medium) {
			font-size: $font-size-8;
		}  
		
		span {
			border-bottom: 1px solid $c-brand;
			transition: all .2s $global-easing;
		}
	}
	
	p:last-of-type {
		margin-bottom: 0;
	}
	
	&--pdf {
		h3 {
			padding-left: 40px;
			background-image: url(/images/elements/pdf-icon-large@2x.png);
			background-repeat: no-repeat;
			background-size: 26px 30px;
		}
	}
	
	img,
	svg {
		width: 26px;
		height: 30px;
		display: inline-block;
		margin-right: $spacing-xsmall;
		position: relative;
		top: 5px;
	}
	
	&__link {
		font-weight: $font-weight-bold;
	}
}