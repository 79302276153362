.c-text-link {
	color: $c-brand;
	display: inline-block;
	font-weight: 700;

	&:after {
		position: relative;
		float: right;
		content: '';
		margin-left: .4rem;
		margin-top: .45rem;
		border-top: 4px solid transparent;
		border-right: 4px solid transparent;
		border-left: 6px solid $c-brand;
		border-bottom: 4px solid transparent;
	}

	&:hover {
		text-decoration: underline;
		&:after {
		}
	}
}