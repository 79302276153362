.c-media-stacked {
	text-align: center;
	
	img {
		margin: 0 auto $spacing-medium;
	}
	
	&__heading {
		@include heading-9;
		font-weight: $font-weight-semibold; // @TODO - we need medium of Proxima
	}
	
	.c-btn {
		margin-top: $spacing-small;
	}
	
	&--larger-image {
		img {
			@include breakpoint($bp-large-max) {
				max-width: 160px;
			} 
		}
	}
	
}