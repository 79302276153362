.c-livechat {
	background: palette(brand);
	color: $c-white;
	padding: $spacing-xxsmall $spacing-base $spacing-xsmall $spacing-base;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	position: fixed;
	bottom: 0px;
	right: $spacing-medium;
	border: 1px solid palette(grey,dark);
	border-bottom: 0;
	transition: background-color .2s $global-easing;

	&:hover {
		text-decoration: none;
		background-color: darken($c-brand, 10%);
	}

	&__icon {
		fill: $c-white;
		height: 25px;
		width: 35px;
		margin-right: $spacing-xsmall;
		position: relative;
		top: 7px;
	}
}