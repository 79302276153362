.c-installer-results-header {
	&__header {
		text-align: center;
		margin-bottom: $spacing-base;

		@include breakpoint($bp-large) {
			text-align: left;
			margin-top: $spacing-small;
		}
	}
}