%btn {
	align-items: center;
	background: palette(brand, mid);
	border-radius: 36px;
	border-width: 1px;
	color: $c-white;
	display: inline-flex;
	font-size: $base-font-size;
	font-weight: $font-weight-bold;
	justify-content: center;
	line-height: 1;
	padding: 10px 26px;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: background-color .2s $global-easing, color .2s $global-easing,
		border .2s $global-easing;
	cursor: pointer;

	@include breakpoint($bp-large) {
		// padding: 10px 26px;
		// padding: 15px $spacing-medium;
		// font-size: 20px;
		// min-height: 58px;
	}

	&:hover {
		background-color: darken(palette(brand, mid), 10%);
		text-decoration: none;
	}

	&:disabled {
		opacity: .4;
		cursor: default;

		&:hover {
			background: $c-brand;
		}
	}
}

%btn-full {
	@extend %btn;
	display: block;
	width: 100%;

	@include breakpoint($bp-small) {
		width: 100%;
	}
}

%btn-reset {
	background: none;
	border: 0;
	text-transform: none;
	width: 100%;
	color: $c-brand;
	padding: 0;
}

button {
	background: none;
	border: none;
	padding: 0;
}

.c-btn {
	@extend %btn;

	svg {
		fill: currentColor;
		height: 14px;
		margin-left: toRems(12px);
		width: 14px;
		position: relative;
	}

	&--full {
		@extend %btn-full;
	}

	&--outlined {
		@include breakpoint($bp-medium) {
			border: 1px solid $c-font;
			background-color: transparent;
			color: $c-font;

			&:hover {
				background-color: rgba($c-font, .2);
			}

			&.is-active,
			&.is-expanded {
				background-color: $c-brand;
				border-color: $c-brand;
				color: #fff;
			}
		}

		&.c-btn--white {
			border-color: $c-white;
			color: #fff;
		}
	}

	&.is-loading {
		color: transparent;
		opacity: 1;

		&:before {
			@extend .c-loader;
			@extend .c-loader--white;
			content: "";
			height: toRems(30px) !important;
			left: 50%;
			margin-left: -15px;
			margin-top: -15px;
			position: absolute;
			top: 50%;
			width: toRems(30px) !important;
		}
	}

	&--grey {
		background-color: #e3e3e3;
		color: $c-font;

		&:hover {
			background-color: shade(#e3e3e3, 10%);
		}
	}

	&--no-left-corners {
		border-radius: 0 $global-radius $global-radius 0 !important;
	}

	&--no-right-corners {
		border-radius: $global-radius 0 0 $global-radius !important;
	}

	&--no-left-corners\@small {
		@include breakpoint($bp-small) {
			border-radius: 0 $global-radius $global-radius 0 !important;
		}
	}

	&--no-right-corners\@small {
		@include breakpoint($bp-small) {
			border-radius: $global-radius 0 0 $global-radius !important;
		}
	}

	&--centered {
		margin: 0 auto;
		display: table; // This will probably break something - change if needed
	}

	&--icon-left {
		svg {
			margin-right: toRems(12px);
			margin-left: 0;
		}
	}

	&--small {
		padding: 8px 12px;

		svg {
			margin-left: 6px;
		}

		&.c-btn--icon-left {
			svg {
				margin-right: 6px;
				margin-left: 0;
			}
		}
	}

	&--medium {
		padding: 18px 30px;

		svg {
			top: 0;
		}

		@include breakpoint($bp-large) {
			border-width: 2px;
			// min-height: 60px;
			font-size: $font-size-10;

			&.c-btn--icon {
				svg {
					width: 12px;
				}
			}
		}
	}

	&--medium-alt {
		@extend .c-btn--medium;
		font-size: 16px;

		svg {
			top: 2px;
		}
	}

	&--large {
		padding: 22px 50px;

		svg {
			top: 0;
		}

		@include breakpoint($bp-large) {
			border-width: 2px;
			// min-height: 60px;
			font-size: $font-size-10;

			&.c-btn--icon {
				svg {
					width: 12px;
				}
			}
		}
	}

	&--wide {
		min-width: 260px;
	}

	&--normal-text {
		font-weight: normal;
	}

	&--inline-with-input {
		@extend .c-btn--no-left-corners;
		height: 52px;

		@include breakpoint($bp-large) {
			// padding: 18px 24px;
			height: 58px;
		}
	}
}
