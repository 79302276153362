.c-band-divider {
	height: 10px;
	background-color: $c-brand;	
	width: 180px;
	display: block;
	margin: $spacing-large auto;
	z-index: 5;
	position: relative;
	
	&--no-bottom-margin {
		margin-bottom: 0;
	}

	&--no-margin {
		margin-top: 0;
		margin-bottom: 0;
	}
}