.c-concave-border {
    align-items: flex-end;
    bottom: -1px;
    display: block;
    display: flex;
    height: 10%;
    left: -1%;
    min-height: 35px;
    position: absolute;
    right: -1%;
    z-index: 1;
    pointer-events: none;
    
    &__inner {
        position: relative;
        display: block;
        width: 100%;
    }
    
    canvas {
        display: block;
        width: 100%;
        visibility: hidden;
    }
    
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        
        path {
            fill: $c-white;
        }
    }
    
    &--top {
        // align-items: flex-start;
        bottom: 100%;
        // top: 0;
        
        svg {
            // transform: rotateX(180deg);
            top: auto;
            bottom: 0;
        }
    }
    
    &--brand {
        svg path {
            fill: $c-brand;
        }
    }
    
    &--grey-super-light {
        svg path {
            fill: palette(grey, super-light);
        }
    }
    
    &.u-show\@large.u-show\@large {
        @include breakpoint($bp-large) {
            display: flex;
        }
    }
    
    &.u-hide\@large.u-hide\@large {
        display: flex;
        
        @include breakpoint($bp-large) {
            display: none;
        }
    }
}