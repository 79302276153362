.o-halves {
	@include span-columns(12);
	
	> div,
	> li,
	> figure {
		margin-bottom: $spacing-medium;
		
		@include breakpoint($bp-medium) {
			@include span-columns(6);	
			margin-bottom: 0;

			&:nth-child(2n) {
				@include omega();
			}
		}  
	
	}
	
	@include breakpoint($bp-medium) {
		
		&--flex {
			display: flex;
			align-items: center;
		}
		
		&--flex-end {
			display: flex;
			align-items: flex-end;
		}
	}  
	
	&--chunky {
		// display: flex;
		
		@include breakpoint($bp-medium) {
			> div,
			 > li,
			 > figure {
				&:nth-child(odd) {
					padding-right: $spacing-medium;
				}
				
				&:nth-child(even) {
					padding-left: $spacing-medium;
				}
			}
		}  
	}
	
	&--border-middle {
		
		> li:nth-child(2n-1) {
			position: relative;
			
			&:after {
				border-right: 2px dotted palette(grey, mid-light);
				content: '';
				display: none;
				height: 100%;
				left: calc(100% + 10px);
				pointer-events: none;
				position: absolute;
				top: 0;
				
				@include breakpoint($bp-large) {
					display: block;
				}  
			}
		} 

	}
}