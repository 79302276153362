.u-align-left {
	&#{&} {
		text-align: left;
	}
}

.u-align-center {
	&#{&} {
		text-align: center;

		img {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@include breakpoint($bp-large) {
	.u-align-left\@large {
		text-align: left;
	}
	.u-align-right\@large {
		text-align: right;
	}
}

.u-align-right {
	&#{&} {
		text-align: right;
	}
}

.u-uppercase {
	&#{&} {
		text-transform: uppercase;
	}
}

.u-lowercase {
	&#{&} {
		text-transform: lowercase;
	}
}

.u-no-case {
	&#{&} {
		text-transform: none;
	}
}

.u-heading {
	color: $c-brand;
	text-align: center;
	font-size: toRems(20px);
	line-height: toRems(26px);
	margin: 0 0 toRems(10px) 0;

	@include breakpoint($bp-small) {
		font-size: toRems(24px);
		line-height: toRems(30px);
	}

	@include breakpoint($bp-medium) {
		font-size: toRems(26px);
		line-height: toRems(32px);
	}

	a {
		color: $c-brand;
	}

	&:after {
		content: "• • •";
		color: palette(grey);
		display: block;
	}

	&:before,
	&:after {
		@include breakpoint($bp-xsmall) {
			content: "• • •";
			display: inline;
			font-size: 20px;
			color: palette(grey);
			padding: 0 $spacing-base;
			letter-spacing: 0;
		}
	}

	&--white {
		color: $c-white;

		a {
			color: $c-white;
		}

		&:before,
		&:after {
			color: $c-white;
			opacity: .5;
		}
	}

	&--left {
		@include breakpoint($bp-xsmall) {
			float: left;
		}

		&:before,
		&:after {
			@include breakpoint($bp-xsmall) {
				display: none;
			}
		}
	}

}
.u-text-columns {
	@include breakpoint($bp-large) {
		column-count: 2;
		column-gap: 3em;
	}
}