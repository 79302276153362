.c-top-story {
	background-size: cover;
	position: absolute;
	display: table;
	
	&:before {
		background-color: rgba(0,0,0,.5);
		content: "";
		display: block;
		left: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
	}
	
	@include breakpoint($bp-large) {
		width: 100%;
		height: 600px;
	}
	
	&__content {
		padding: $spacing-large;
		position: relative;
		color: $c-white;
		
		@include breakpoint($bp-large) {
			width: 100%;
			height: 600px;
			display: table-cell;
			vertical-align: middle;
		}
	}
	
	&__title {
		font-size: $font-size-7;
		font-weight: $font-weight-bold;
		margin-bottom: .4em;
		
		@include breakpoint($bp-large) {
			max-width: 500px;
		}  
		
		+ p {
			font-size: $font-size-10;
			margin-bottom: 2em;
			
			@include breakpoint($bp-large) {
				max-width: 500px;
			}  
		}
	}
}