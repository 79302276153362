.c-installer {

	&__name {
		@include heading-7;
		text-transform: uppercase;
		font-weight: $font-weight-light;
		padding-top: $spacing-xsmall;
	}

	&__buttons {

		@include breakpoint($bp-medium) {
			display: flex;
		}

		a {
			display: block;
			margin-bottom: $spacing-small;

			@include breakpoint($bp-medium) {
				margin-right: $spacing-small;
    			width: 50%;
    		}
    	}
	}


}