@font-face {
	font-family: "Proxima Nova";
	src: url("/fonts/proxima-nova/proximanova-regular-webfont.eot");
	src: url("/fonts/proxima-nova/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("/fonts/proxima-nova/proximanova-regular-webfont.woff") format("woff"),
		url("/fonts/proxima-nova/proximanova-regular-webfont.ttf") format("truetype"),
		url("/fonts/proxima-nova/proximanova-regular-webfont.svg#proxima-nova") format("svg");
	font-style:  normal;
    font-weight:  normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("/fonts/proxima-nova/proximanova-light-webfont.eot");
	src: url("/fonts/proxima-nova/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"),
		url("/fonts/proxima-nova/proximanova-light-webfont.woff") format("woff"),
		url("/fonts/proxima-nova/proximanova-light-webfont.ttf") format("truetype"),
		url("/fonts/proxima-nova/proximanova-light-webfont.svg#proxima-nova-light") format("svg");
	font-style:  normal;
    font-weight:  300;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("/fonts/proxima-nova/proximanova-semibold-webfont.eot");
	src: url("/fonts/proxima-nova/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"),
		url("/fonts/proxima-nova/proximanova-semibold-webfont.woff") format("woff"),
		url("/fonts/proxima-nova/proximanova-semibold-webfont.ttf") format("truetype"),
		url("/fonts/proxima-nova/proximanova-semibold-webfont.svg#proxima-nova-semibold") format("svg");
	font-style:  normal;
    font-weight:  600;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("/fonts/proxima-nova/proximanova-bold-webfont.eot");
	src: url("/fonts/proxima-nova/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"),
		url("/fonts/proxima-nova/proximanova-bold-webfont.woff") format("woff"),
		url("/fonts/proxima-nova/proximanova-bold-webfont.ttf") format("truetype"),
		url("/fonts/proxima-nova/proximanova-bold-webfont.svg#proxima-nova-bold") format("svg");
	font-style:  normal;
    font-weight:  700;
}

