.c-form-row {
	@include breakpoint($bp-medium) {
		display: flex;
	}

	.c-form-input {
		margin-bottom: $spacing-base;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include breakpoint($bp-medium) {		
			flex-grow: 1;
			margin-bottom: 0;
			margin-right: 15px;
		}
	}

	legend {
		margin: $spacing-base 0 $spacing-xsmall 0;  

		@include breakpoint($bp-medium) {
			margin: $spacing-medium 0 $spacing-xsmall 0; 
		}
	}
}