.c-image-cta {
	padding: $spacing-medium 0;
	position: relative;
	min-height: 350px;
	
	@include breakpoint($bp-large) {
		padding: 80px 0;
	}  
	
	&:before {
		background-color: rgba($c-brand, .5);
		content: "";
		display: block;
		left: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: 1;
		
		@include breakpoint($bp-large) {
			display: none;
		}  
	}
	
	.o-grid {
		position: relative;
		z-index: 1;
	}
	
	&__bg-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 50%;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
	
	&__content {
		margin-top: $spacing-medium;
		
		@include breakpoint($bp-large) {
			@include shift(3);
			@include span-columns(8);
			margin-top: 0;
			text-shadow: 0 0 40px $c-brand, 0 0 10px $c-brand, 0 0 100px $c-brand;

			.c-btn {
				text-shadow: none;
			}

			p {
				font-size: $font-size-10;
				margin-bottom: toRems(40px);
			}
		}  

	}
	
	&__badge {
		@include breakpoint($bp-large) {
			@include span-columns(2);
			@include shift(2);
		}  
	}

	&__list {
		margin-bottom: $spacing-small;

		@include breakpoint($bp-medium) {
			display: flex;
		}

		li {
			position: relative;
			padding-left: 35px;
			padding-right: $spacing-small;
			margin-bottom: $spacing-small;
		}

		svg {
			width: 25px;
			height: 25px;
			fill: white;
			position: absolute;
			top: 0px;
			left: 0;
			text-shadow: 0 0 40px $c-brand, 0 0 10px $c-brand, 0 0 100px $c-brand;

			@include breakpoint($bp-medium) {
				top: 3px;
			}
		}
	}
}