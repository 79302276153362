.c-badge-with-title {
	font-weight: $font-weight-semibold;
	line-height: $font-leading-xxsmall;
	text-align: center;
	text-transform: uppercase;

	img {
		margin: 0 auto $spacing-small;
		max-width: 85px;
	}
}
