.o-inline-input-label {
    display: flex;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    
    @include breakpoint($bp-medium) {
        display: block;
        width: 100%;
    }
    
    @include breakpoint($bp-large) {
        display: flex;
        align-items: flex-start;
    }
    
    .c-form-label {
        flex: 1;
        font-family: $font-proxima;
        font-size: 14px;
        font-size: 16px;
        padding-right: 20px;
        text-align: left;
        
        @include breakpoint($bp-small) {
            font-size: 20px;
        }
        
        @include breakpoint($bp-medium) {
            font-size: 16px;
        }
    }
    
    .c-dropdown,
    .c-form-text {
        width: 160px;
        
        @include breakpoint($bp-medium) {
            width: 100%;
        }
        
        @include breakpoint($bp-large) {
            width: 160px;
        }
    }
}