.c-divider {
	@include span-columns(12);
	display: block;
	float: none;
	padding: $spacing-large 0;
	margin-left: auto !important;
	margin-right: auto !important;
	max-width: $max-site-width;
	clear: both;
	position: relative;
	
	&:before {
		height: 1px;
		content: "";
		display: block;
		width: 100%;
		background-color: $c-border;
		position: absolute;
		top: 50%;
		margin-top: -1px;
	}
	
	&--no-margin {
		padding-top: 0;
		padding-bottom: 0;
	}
}