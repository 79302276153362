.c-accordion-list {
	@include span-columns(12);
	margin-bottom: $spacing-large;
	
	header {
		// text-align: center;
		
		@include breakpoint($bp-medium) {
			text-align: left;
			display: flex;
		}  
		
		h4 {
			font-size: $font-size-11;
			margin: 0 0 .3em;
			
			@include breakpoint($bp-medium) {
				margin: 0 0 28px 0;
			}  
		}
		
		button {
			color: $c-brand;
			outline: none;
			padding-right: $spacing-base;
			position: relative;
			display: block;
			margin-bottom: $spacing-base;
			text-align: right;
			display: block;
			width: 100%;
			
			@include breakpoint($bp-medium) {
				width: auto;
				font-size: $font-size-11;
				margin-bottom: 0;
				margin-left: auto;
				padding-right: $spacing-medium;
				top: -.8em;
			}  
			
			svg {
				fill: $c-brand;
				height: 14px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%) rotate(0);
				width: 14px;
				transition: transform .3s $global-easing;
				
				@include breakpoint($bp-large) {
					height: 18px;
					width: 18px;
				}  
			}
		}
		
		&.is-active {
			svg {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
	
	> li {
		@include span-columns(12);
		border-bottom: 1px solid palette(grey, xxx-light);
		padding: $spacing-base 0 0;
		
		@include breakpoint($bp-large) {
			padding: $spacing-medium $spacing-base 0
		}  
	}
	
	&__content {
		@extend .s-cms-content;
		padding-bottom: $spacing-medium;
		
		.js & {
			display: none;
		}
		
		h5 {
			font-size: $font-size-9;
			font-weight: $font-weight-bold !important;
			
			@include breakpoint($bp-large) {
				font-size: $font-size-8;
			}  
		}
		
		h6 {
			font-size: $font-size-11;
			font-weight: $font-weight-bold !important;
			
			@include breakpoint($bp-large) {
				font-size: $font-size-10;
			} 
		}
	}
	
	&__inner {
		@include breakpoint($bp-large) {
			display: flex;
		}  
		
	}
	
	&__cols {
		@include breakpoint($bp-large) {
			@include span-columns(8);
			padding-right: $spacing-medium;
			column-count: 2;
			column-gap: $spacing-medium;
			
			ol li {
				column-break-inside: avoid;
			}
		}  
	}
	
	&__col {
		@include breakpoint($bp-large) {
			@include span-columns(4);
			padding-left: $spacing-medium;
			padding-right: $spacing-medium;
		}  
		
		&:first-child {
			padding-left: 0;
		}
		
		&:last-child {
			padding-right: 0;
			
			@include breakpoint($bp-large) {
				border-left: 1px solid palette(grey, xxx-light);
			}  
		}
		
		&.u-heading-9 {
			p {
				line-height: 1.2em;
			}
		}
	}
}