.c-card-long {
	align-items: center;
	background-color: #fff;
	justify-content: space-between;
	position: relative;
	width: 100%;
	
	header {
		padding: $spacing-medium;
		
		@include breakpoint($bp-large) {
			padding: 40px;
		}  
	}
	
	figure {
		@include breakpoint($bp-small) {
			min-width: 160px;
			flex: 0 0 160px;
	
		}  
		
		img {
			margin: 0 auto $spacing-medium;
			max-width: 120px;
			
			@include breakpoint($bp-medium) {
				margin: 0 $spacing-base 0 0;
			}  
		}
	}
	
	&__inner {
		@include breakpoint($bp-medium) {
			display: flex;		 
			align-items: center;
			align-content: flex-end;
		}  
		
	}
	
	&__header-content {
		@include breakpoint($bp-medium) {
			padding: 0 $spacing-large 0 0;
		}  
	}
	
	&__header-actions {
		@include breakpoint($bp-medium) {
			min-width: 160px;
			text-align: center;
			flex-grow: 1;
		}  
	}

	&__header-actions-inner {
		@include breakpoint($bp-medium) {
			float: right;
			width: 160px;
		}
	}
	
	&__toggle {
		margin-top: $spacing-base;
		margin-bottom: 0;
		
		@include breakpoint($bp-medium) {
			margin-top: 0;
			padding-left: 160px;
			
			.c-card-long--no-image & {
				padding-left: 0;
			}
		}  
	}
	
	footer {
		@include span-columns(12);
		background-color: $c-white;
		position: relative;
		padding: $spacing-medium;
		display: none;
		
		@include breakpoint($bp-large) {
			padding: 40px;
		}  
		
		> div:first-child {
			@include breakpoint($bp-medium) {
				padding-right: $spacing-medium;
			}  
		}
		
		h3,
		h4 {
			font-size: $font-size-10;
		}
		
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			content: '';
			height: 7px;
			background-image: linear-gradient(to top, #fcfcfc, #e3e3e3);
		}
	}
	
	&__footer-cta {
		@include span-columns(12);
		border-top: 1px solid $c-border;
		padding-top: 40px;
		margin-top: 40px;
	}
	
	&__footer-cta-item {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		
		span {
			display: block;
			font-weight: $font-weight-bold;
			font-size: $font-size-10;
			margin-right: $spacing-base;
		}
	}
}