.o-tab-buttons {
	
	@include breakpoint($bp-medium) {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		position: relative;
		transform: translateY(50%);
	}  
}