.c-back-link {
	position: relative;
	top: -15px;

	svg {
		width: 18px;
		height: 10px;
		transition: all .3s $global-easing;
		transform: rotate(0);
		fill: $c-brand;
	}
}