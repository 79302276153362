.c-flash-badge {
	background-image: linear-gradient(208deg, $c-brand, rgb(87, 172, 37));
	box-shadow: 3.1px 3.9px 13px 0 rgba(15, 14, 11, 0.25);
	border: 5px solid $c-white;
	padding: $spacing-base;
	border-radius: 50%;
	text-align: center;
	width: 154px;
	height: 154px;
	transform: rotate(10deg);
	
	&__top {
		font-size: 13px;
		font-weight: 600;
		line-height: 0.9;
		letter-spacing: -0.3px;
		text-align: center;
		color: #ffffff;
		padding-bottom: $spacing-xsmall;
		display: block;
	}
	
	&__middle {
		font-size: 54px;
		font-weight: bold;
		line-height: 0.8;
		letter-spacing: -1.1px;
		display: block;
		padding-bottom: 4px;
	}
	
	&__bottom {
		font-size: $font-small;
		font-weight: $font-weight-semibold;
		line-height: .97;
		text-transform: uppercase;
		display: block;
	}
}