.c-faqs {
	@include span-columns(12);
	border-top: 2px solid palette(grey, mid-light);

	dt {
		@include span-columns(12);
		border-bottom: 1px solid $c-border;
		cursor: pointer;
		font-size: 18px;
		padding: 24px $spacing-base;
		transition: color 300ms $global-easing;

		&.is-active {
			color: $c-brand;
		}
	}

	dd {
		width: 100%;
		background-color: #efefef;
		margin: 0;
		padding: $spacing-medium;
		position: relative;
		float: left;
		clear: both;

		.js & {
			display: none;
		}

		p:last-child {
			margin-bottom: 0;
		}

	}

	&__answer {
		display: inline-block;
		float: right;
		width: calc(100% - 35px);
	}

	&__answer-marker {
		font-weight: 600;
		margin-right: $spacing-base;
		display: block;
		float: left;
	}
	
	&__inset-header {
		margin-left: $spacing-base;
	}
}