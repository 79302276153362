.o-media-alt {
	text-align: center;
	
	img {
		margin: 0 auto;
	}
	
	@include breakpoint($bp-large) {
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		flex-direction: row;
	}  	
	
	&__heading {
		width: 100%;
		flex: none;
		margin-bottom: $spacing-medium;
	}
	
	figure {
		margin-bottom: $spacing-medium;
		
		@include breakpoint($bp-large) {
			margin-right: toRems(30px);
			margin-bottom: 0;
			flex: 180px 0 0;
		}  	
		
		img {
			max-width: 100%;
		}
	}
	
	&__content {
		@include breakpoint($bp-large) {
			flex: 1;

			p,
			a {
				align-self: flex-start
			}
			
		}  	
	}

	&--no-img {
		@include breakpoint($bp-large) {
			flex-direction: column;
		}

		.o-media-alt__content {
			flex: none;
		}
	}
}