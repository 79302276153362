.o-thirds-list {
	@include span-columns(12);
	margin-bottom: -2.04082%; // Compensate for the bottom margins on the list items
	display: flex;
	flex-wrap: wrap;
	
	@include breakpoint($bp-large) {
		align-items: flex-start;
	}  
	
	&--centered {
		@include breakpoint($bp-large) {
			justify-content: center;
		}  
	}
	
	> li {
		@include span-columns(12);
		margin-bottom: 2rem;
		
		&:last-child {
			margin-bottom: 2.04082%;
		}
		
		@include breakpoint($bp-medium) {
			// Maybe put this back in as a chunky option
			// padding: 0 $spacing-base $spacing-base $spacing-base; 
		}  
		
		@include breakpoint($bp-medium $bp-large) {
			@include span-columns(4 of 8);		
			
			&:nth-child(2n) {
				@include omega();
			}
			
			&:nth-child(2n+1) {
				clear: left;
			}
		}  	
		
		@include breakpoint($bp-large) {
			@include span-columns(4);
			
			&:nth-child(3n) {
				@include omega();
			}
			
			&:nth-child(3n+1) {
				clear: left;
			}
		}  	
	}	
}