.o-results-header {
	
	@include breakpoint($bp-medium $bp-xlarge) {
		display: flex;
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
	}  
	
	@include breakpoint($bp-xlarge) {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		padding-bottom: $spacing-xlarge;
		
	}  
	
	&__collection-item {
		
		@include breakpoint($bp-medium $bp-xlarge) {
			// display: flex;
			justify-content: center;
			flex: 1 0 50%;
			align-items: center;
			flex-direction: row;
			
			&:first-child {
				padding-right: $spacing-base;
			}
			
			&:last-child {
				padding-left: $spacing-base;
			}
		}  
		
		@include breakpoint($bp-xlarge) {
			// width: 50%;
			
			display: flex;
			align-items: center;
			padding: $spacing-base;
			text-align: right;
			
		}
		
		label:not(.c-form-select) {
			font-size: $font-size-9;
			text-align: center;
			
			@include breakpoint($bp-medium) {
				font-size: $font-size-8;
			}  
			
			@include breakpoint($bp-xlarge) {
				margin-right: 1em;
			}  
		}
	}
	
	&__item {
		padding: 0 0 $spacing-base 0;
		
		@include breakpoint($bp-xlarge) {
			padding: 0;
		}  
		
		&--full\@medium {
			text-align: right;
			
			@include breakpoint($bp-medium  $bp-xlarge) {
				flex: 0 1 100%;
			}  
		}
		
		.c-btn {
			// margin-top: $spacing-medium;
		}
	}  
}