.c-expandable-cards {
	
	&.is-loading {
		opacity: 0;
		transition: opacity .3s $global-easing;
	}
	
	> li {
		text-align: left;
		
		@include breakpoint($bp-small) {
			display: inline;
		}
		
		.c-product-card {
			vertical-align: top;
			// margin-top: 30px;
			margin-left: $spacing-base;
			margin-right: $spacing-base;
			width: calc(100% - 40px);
			margin-bottom: 24px;
			
			@include breakpoint($bp-small) {
				@include span-columns(3.75 of 8);
				display: inline-block;
				float: none;
				margin-left: -4px;
			}
			
			@include breakpoint($bp-medium) {
				@include span-columns(3.7 of 8);
				display: inline-block;
				float: none;
				margin-left: -4px;
			}
			
			@include breakpoint($bp-large) {
				@include span-columns(2.84);
				display: inline-block;
				float: none;
			}  
			
			@include breakpoint($bp-xlarge) {
				// @include span-columns(3);
				display: inline-block;
				float: none;
				// margin-top: 50px;
				// margin-left: -4px;
				width: 276px;
				margin: 0 12px 24px 12px;
			}
			
			&:last-child {
				// outline: 1px solid green;
			}
		}
		
		.c-btn {
			@include breakpoint($bp-xsmall) {
				width: calc(100% - 40px);
				margin-left: $spacing-base;
			}  
		}
		
		&:first-child {
			
			.c-btn {
				margin-left: $spacing-base;
				
				@include breakpoint($bp-medium $bp-xlarge) {
					margin-left: $spacing-medium;
				}  
				
				// @include breakpoint($bp-large) {
				// 	margin-left: $spacing-medium;
				// }  
			}
			
		}
		
		// Every 2 starting at the first
		&:nth-child(2n+1) {
			> .c-product-card {
				@include breakpoint($bp-small $bp-medium) {
					margin-left: $spacing-base;
				}  
				
				@include breakpoint($bp-medium $bp-large) {
					margin-left: $spacing-medium;
				}  
			}
		}
		
		&:nth-child(2n) {
			> .c-product-card {
				
				@include breakpoint($bp-small $bp-large) {
					@include omega();
				}
				// 
				// @include breakpoint($bp-medium) {
				// 	@include remove-omega();
				// }
				// 
				// @include breakpoint($bp-large) {
				// 	@include remove-omega();
				// }
			}
		}
		// 
		// &:nth-child(-n+2) {
		// 	> .c-product-card {
		// 		@include breakpoint($bp-small) {
		// 			margin-top: 0;
		// 		}
		// 	}
		// }
		// 
		// &:nth-child(3n) {
		// 
		// 	> .c-product-card {
		// 		@include breakpoint($bp-medium) {
		// 			@include omega();
		// 		}
		// 
		// 		@include breakpoint($bp-large) {
		// 			@include remove-omega();
		// 		}
		// 	}
		// }
		// 
		// &:nth-child(-n+3) {
		// 	> .c-product-card {
		// 		@include breakpoint($bp-medium) {
		// 			margin-top: 0;
		// 		}
		// 	}
		// }
		// 
		&:nth-child(4n) {
			> .c-product-card {
				@include breakpoint($bp-large) {
					@include omega();
				}
				// @include breakpoint(max-width $bp-xlarge) {
				// 	margin-right: $spacing-medium !important;
				// }  
			}
		}
		&:nth-child(4n+1) {
			> .c-product-card {
				@include breakpoint($bp-large) {
					margin-left: $spacing-medium;
				}
			}
		}
		
		// 
		// &:nth-child(-n+4) {
		// 	> .c-product-card {
		// 		@include breakpoint($bp-large) {
		// 			margin-top: 0;
		// 		}
		// 	}
		// }
	}
	
	&__close-btn {
		position: absolute;
		right: 15px;
		top: -46px;
		
		bottom: 100%;
		line-height: 1;
		padding-bottom: 5px;
		
		@include breakpoint($bp-large) {
			top: auto;
			right: 20px;
		}  
		
		svg {
			display: block;
			fill: palette(grey, mid-light);
			height: 24px;
			width: 24px;
		}
		
		.u-bg-grey-mid & {
			right: -5px;
			
			@include breakpoint($bp-large) {
				right: -10px;
			}  
		}
	}
	
	&__content {
		display: none;
		margin: 25px 0 $spacing-large 0;
		position: relative;
		width: 100%;
		float: left;
		
		// Third level
		.c-expandable-cards__content.u-bg-grey-mid {
			@include breakpoint($bp-large) {
				// margin-bottom: -50px;
			}  
		}
		
		&.u-bg-grey-xxx-light,
		&.u-bg-grey-mid {
			padding: 50px 0;
			z-index: 1;
			
			.c-product-card__title {
				font-weight: 400;
			}
		}
		
		&.u-bg-grey-mid {
			color:#fff;
			padding-bottom: 50px;
			
			.c-expandable-cards__indicator {
				border-color: transparent transparent palette(grey, mid) transparent;
			}
			
			ul {
				a {
					color: $c-white;
				}
			}
		}
		
		// Nested of the same 
		
		&.u-bg-grey-xxx-light {
			.u-bg-grey-xxx-light {
				background-color: palette(grey, xx-light);
			}
		}
		
		ul {
			li {
				margin-bottom: .5em;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		
		// Documents columns 
		
		.u-grid-3\@large {
			@include breakpoint($bp-large) {
    			&:nth-child(4n) {
					margin-right: 0;
				}
			}  
		}
	}

	.c-expandable-cards {
		.c-product-card__title {
			@include breakpoint($bp-large) {
				font-size: 22px;
			}
		}
	}
}