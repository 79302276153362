.o-halves-swap-order {
	
	@include breakpoint($bp-medium) {
		@include direction-context(right-to-left) {
			
			> div {
				@include span-columns(6);	
			}
		}
	}
	
}