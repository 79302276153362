.c-modal {
	padding: $spacing-medium;

	&--centered {
		text-align: center;
	}

	&--slim {
		max-width: 600px;
	}
}
