.c-cta-button {
    @extend %btn;
    background-color: transparent;
    border: 2px solid palette(grey, mid);
    color: palette(grey, mid);
    font-weight: 700;
    justify-content: flex-start;
    align-items: center;
    min-width: toRems(210px);
    padding-bottom: toRems(10px);
    padding-right: toRems(60px);
    padding-top: toRems(10px);
    position: relative;
    
    span {
        align-self: center;
    }
    
    @include breakpoint($bp-large) {
        min-width: 250px;
    }
    
    &:not(:disabled):hover {
        background-color: palette(brand);
        border-color: palette(brand);
        color: $c-white;
        content: "";
        
        svg {
            fill: $c-white;
        }
    }
    
    &:after {
        @extend %i-chevron-right;
        font-size: 14px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    svg {
        fill: palette(grey, mid);
        height: toRems(30px);
        margin-right: toRems(18px);
        width: toRems(30px);
        transition: opacity 0.3s $global-easing;
    }
    
    &:disabled {
        border-color: palette(grey, mid);
        color: palette(grey, mid);
        cursor: not-allowed;
        opacity: 0.25;
        
        &:hover {
            background-color: transparent;
            border: 2px solid palette(grey, mid);
            color: palette(grey, mid);
        }
        
        svg {
            fill: palette(grey, mid);
        }
    }
    
    &.is-loading {
        color: transparent;
        opacity: 1;
        
        &:hover {
            background-color: transparent;
            border: 2px solid palette(grey, mid);
            color: transparent;
        }
        
        svg {
            opacity: 0;
        }
        
        &:before {
            @extend .c-loader;
            content: "";
            height: toRems(30px) !important;
            left: 50%;
            margin-left: -15px;
            margin-top: -15px;
            position: absolute;
            top: 50%;
            width: toRems(30px) !important;
        }
    }
}