.c-input-btn {
	margin: 0 auto;
	display: block;
	text-align: center;
	
	&__button {
		@extend %btn;
		position: relative;
		
		&:after {
			@extend %btn;
			content: 'Selected';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			background-color: #32a534;
		}
	}
	
	input {
		@include visually-hidden;
		
		&:checked {
			
			+ span {
				
				&:hover {
					background-color: #32a534;
					
					&:after {
						background-color: #32a534;
					}
				}
				
				&:after {
					opacity: 1;
					
				}
				
			}
		}
	}	
	
}