.c-results-header {
	background-color: palette(grey, dark);
	color: $c-white;
	display: flex;
	padding: 24px $spacing-base;
	
	h2 {
		flex: 0 0 50%;
		font-size: $font-size-6;
		margin: 0;
	}
	
	p {
		margin: 0;
	}
}