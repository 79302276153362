.o-inline-icon-list {
	
	@include breakpoint($bp-medium) {
		display: flex;
		justify-content: center;
	}  
	
	@include breakpoint($bp-large) {
		justify-content: flex-start;
	}  
	
	li {
		margin-bottom: $spacing-base;
		
		@include breakpoint($bp-medium) {
			margin-right: $spacing-medium;
		} 
		
		&:last-child {
			margin: 0;
		} 
	}
}