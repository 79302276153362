.c-form-file {
	input[type="file"] {
		border: 0;
		cursor: pointer;
		width: auto;
		padding: 0 0 $spacing-base 0;
		line-height: 10px;
		background: none;
		border-radius: 0;
	}
}