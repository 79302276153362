.c-hexagon-image {
	height: 1080px;
	left: 50%;
	margin: 0 auto 0 60px;
	pointer-events: none;
	position: absolute;
	top: -220px;
	width: 970px;
	display: none;
	
	@include breakpoint($bp-large) {
		display: block;
	}  
	
	image {
		clip-path: url(#hexagonal-mask);
	}
}